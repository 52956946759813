import { Text, Button } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FormEvent } from "react";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";

export const BasicInfoOverview = () => {
  const { navigate } = useRouter();

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    navigate(Routes.BasicInfoEntry);
  };

  const iconSize = "w-6 w-6 text-primary dark:text-primary-light p-1";

  return (
    <AnimationContainer>
      <form
        id="OnboardingBasicInfoOverview"
        name="OnboardingBasicInfoOverview"
        onSubmit={onSubmit}
        className="onboarding-inner-content"
      >
        <Heading
          title="Identity Verification"
          subtitle="Just a few more things remaining to help protect your account from
          fraud and verify your identity."
        />

        <div className="flex w-full flex-col gap-2 rounded-xl border border-solid border-neutral-800/5 p-4 shadow-md dark:border-gray-600">
          <div className="flex gap-1.5">
            <FontAwesomeIcon
              icon={icon({ name: "id-card", style: "regular" })}
              className={iconSize}
            />
            <div className="flex flex-col">
              <Text className="font-bold">Social Security</Text>
              <Text className="opacity-70">Just the last 4-digits</Text>
            </div>
          </div>
          <div className="flex gap-1.5">
            <FontAwesomeIcon
              icon={icon({ name: "cake", style: "regular" })}
              className={iconSize}
            />
            <div className="flex flex-col">
              <Text className="font-bold">Birthday</Text>
              <Text className="opacity-70">Date of birth</Text>
            </div>
          </div>
          <div className="flex gap-1.5">
            <FontAwesomeIcon
              icon={icon({ name: "signature", style: "regular" })}
              className={iconSize}
            />
            <div className="flex flex-col">
              <Text className="font-bold">Name</Text>
              <Text className="opacity-70">Your legal first and last name</Text>
            </div>
          </div>
          <div className="flex gap-1.5">
            <FontAwesomeIcon
              icon={icon({ name: "house-circle-check", style: "regular" })}
              className={iconSize}
            />
            <div className="flex flex-col">
              <Text className="font-bold">Residential Address</Text>
              <Text className="opacity-70">Your legal address</Text>
            </div>
          </div>
        </div>

        <div className="onboarding-footer">
          <Button key="OnboardingBasicInfoOverview:button" type="submit">
            Continue
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
