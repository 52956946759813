import { LOGO_SIZES } from "../utils/constants";
import { twMerge } from "tailwind-merge";
import { useState } from "react";

const sizeClasses = {
  xs: "h-4 w-4",
  sm: "h-6 w-6",
  md: "h-9 w-9",
  lg: "h-16 w-16",
  xl: "h-24 w-24",
};

export const PartnerLogo = ({
  size = "sm",
  displayName,
  logoUri,
}: {
  size?: keyof typeof LOGO_SIZES;
  displayName?: string;
  logoUri?: string;
}) => {
  const [imageError, setImageError] = useState(false);
  const initials = displayName
    ? displayName.split(" ").map((word) => word[0].toUpperCase())
    : [""];
  const fallback =
    size === "sm" || size === "xs" || initials.length > 2
      ? initials[0] || ""
      : initials.join("");

  return !imageError ? (
    <img
      data-testid="partnerLogoImage"
      key="partner-logo-img"
      className={twMerge("rounded-full", sizeClasses[size])}
      src={logoUri}
      alt={displayName ? `Logo for ${displayName}` : "Partner"}
      onError={() => setImageError(true)}
    />
  ) : (
    fallback && (
      <span
        key="partner-logo-fallback"
        className={twMerge(
          "flex items-center justify-center rounded-full bg-gradient-to-b from-neutral-100 to-neutral-200 text-center font-semibold text-neutral-800 dark:from-neutral-700 dark:to-neutral-800 dark:text-white",
          `text-${size}`,
          sizeClasses[size],
        )}
      >
        {fallback}
      </span>
    )
  );
};
