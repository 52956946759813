import { twMerge } from "tailwind-merge";
import "./styles.css";
import { Input, InputProps } from "./Input";
import { ReactNode, LabelHTMLAttributes, Ref, forwardRef } from "react";

export type LabelledInputProps = InputProps & {
  inputComponent?: ReactNode;

  labelProps: {
    text: string;
    attributes?: LabelHTMLAttributes<HTMLLabelElement>;
    accessory?: ReactNode;
  };
};

/**
 * A Meso component that provides an `<input>` and corresponding wrapper `<label>` element.
 */
export const LabelledInput = forwardRef(function LabelledInputWithRef(
  {
    inputComponent,
    disabled,
    isValid,
    labelProps,
    ...props
  }: LabelledInputProps,
  ref: Ref<HTMLInputElement>,
) {
  const labelClasses = twMerge("relative block", !disabled && "cursor-pointer");
  const labelTextClasses = twMerge(
    "label",
    !isValid && "label-invalid",
    disabled && "label-disabled",
  );

  return (
    <label
      className={labelClasses}
      htmlFor={props.name}
      {...labelProps.attributes}
    >
      {labelProps.accessory ? (
        <div
          className={twMerge(
            "flex items-center justify-between",
            labelTextClasses,
          )}
        >
          <p>{labelProps.text}</p>
          {labelProps.accessory}
        </div>
      ) : (
        <p className={labelTextClasses}>{labelProps.text}</p>
      )}

      {inputComponent || (
        <Input {...props} disabled={disabled} isValid={isValid} ref={ref} />
      )}
    </label>
  );
});
