// Based on: https://github.com/mailcheck/mailcheck/blob/cbc33a6f1af79c70f8d4a0dfc0b0140b7cd5f9cf/src/mailcheck.js

import { err, ok, Result } from "neverthrow";

type EmailSuggestion = {
  /**
   * The leading part of the email address before `@`.
   *
   * This value remains unchanged from the given input.
   */
  address: string;
  /**
   * The suggested domain for the email address.
   */
  domain: string;
  /**
   * The resulting fully qualified email address.
   */
  full: string;
};

type ParsedEmailAddress = Record<
  "topLevelDomain" | "secondLevelDomain" | "domain" | "address",
  string
>;

export const defaultDomains = [
  "msn.com",
  "bellsouth.net",
  "telus.net",
  "comcast.net",
  "optusnet.com.au",
  "earthlink.net",
  "qq.com",
  "sky.com",
  "icloud.com",
  "mac.com",
  "sympatico.ca",
  "googlemail.com",
  "att.net",
  "xtra.co.nz",
  "web.de",
  "cox.net",
  "gmail.com",
  "ymail.com",
  "aim.com",
  "rogers.com",
  "verizon.net",
  "rocketmail.com",
  "google.com",
  "optonline.net",
  "sbcglobal.net",
  "aol.com",
  "me.com",
  "btinternet.com",
  "charter.net",
  "shaw.ca",
  "protonmail.com",
  "protonmail.ch",
  "proton.me",
  "icloud.com",
];

export const secondLevelDomains = [
  "yahoo",
  "hotmail",
  "mail",
  "live",
  "outlook",
  "gmx",
];

export const topLevelDomains = [
  "com",
  "com.au",
  "com.tw",
  "ai",
  "ca",
  "co.nz",
  "co.uk",
  "me",
  "de",
  "fr",
  "it",
  "ru",
  "network",
  "org",
  "edu",
  "gov",
  "jp",
  "nl",
  "kr",
  "se",
  "eu",
  "ie",
  "co.il",
  "us",
  "at",
  "be",
  "dk",
  "hk",
  "es",
  "gr",
  "ch",
  "no",
  "cz",
  "in",
  "net",
  "net.au",
  "info",
  "biz",
  "mil",
  "co.jp",
  "sg",
  "hu",
  "uk",
  "pizza",
  "plumbing",
];

export const parseEmailAddress = (
  /** A _valid_ email address to be parsed into address and domain. */
  email: string,
): Result<ParsedEmailAddress, string> => {
  const parts = email.trim().split("@").filter(Boolean);

  if (parts.length !== 2) {
    return err("Invalid email address");
  }

  const [address, domain] = parts;
  const domainParts = domain.split(".");
  let sld = "";
  let tld = "";

  if (domainParts.length === 0) {
    // The address does not have a top-level domain
    return err(
      "Invalid email address. The provided address does not have a top-level domain.",
    );
  } else if (domainParts.length == 1) {
    // The address has only a top-level domain (valid under RFC)
    tld = domainParts[0];
  } else {
    // The address has a domain and a top-level domain
    sld = domainParts[0];
    for (var j = 1; j < domainParts.length; j++) {
      tld += domainParts[j] + ".";
    }
    tld = tld.substring(0, tld.length - 1);
  }

  return ok({
    topLevelDomain: tld,
    secondLevelDomain: sld,
    domain,
    address,
  });
};

// Distance function used by the original library
// Other algorithms were tried, such as levenshtein (via https://www.npmjs.com/package/fastest-levenshtein, https://www.npmjs.com/package/leven) and did not yield as good results.
const sift4Distance = (s1: string, s2: string, maxOffset = 5) => {
  // sift4: https://siderite.blogspot.com/2014/11/super-fast-and-accurate-string-distance.html

  if (!s1 || !s1.length) {
    if (!s2) {
      return 0;
    }
    return s2.length;
  }

  if (!s2 || !s2.length) {
    return s1.length;
  }

  var l1 = s1.length;
  var l2 = s2.length;

  var c1 = 0; //cursor for string 1
  var c2 = 0; //cursor for string 2
  var lcss = 0; //largest common subsequence
  var local_cs = 0; //local common substring
  var trans = 0; //number of transpositions ('ab' vs 'ba')

  var offset_arr: Array<{ c1: number; c2: number; trans: boolean }> = []; //offset pair array, for computing the transpositions

  while (c1 < l1 && c2 < l2) {
    if (s1.charAt(c1) == s2.charAt(c2)) {
      local_cs++;
      var isTrans = false;
      //see if current match is a transposition
      var i = 0;
      while (i < offset_arr.length) {
        var ofs = offset_arr[i];
        if (c1 <= ofs.c1 || c2 <= ofs.c2) {
          // when two matches cross, the one considered a transposition is the one with the largest difference in offsets
          isTrans = Math.abs(c2 - c1) >= Math.abs(ofs.c2 - ofs.c1);
          if (isTrans) {
            trans++;
          } else {
            if (!ofs.trans) {
              ofs.trans = true;
              trans++;
            }
          }
          break;
        } else {
          if (c1 > ofs.c2 && c2 > ofs.c1) {
            offset_arr.splice(i, 1);
          } else {
            i++;
          }
        }
      }
      offset_arr.push({ c1, c2, trans: isTrans });
    } else {
      lcss += local_cs;
      local_cs = 0;
      if (c1 != c2) {
        c1 = c2 = Math.min(c1, c2); //using min allows the computation of transpositions
      }
      //if matching characters are found, remove 1 from both cursors (they get incremented at the end of the loop)
      //so that we can have only one code block handling matches
      for (var j = 0; j < maxOffset && (c1 + j < l1 || c2 + j < l2); j++) {
        if (c1 + j < l1 && s1.charAt(c1 + j) == s2.charAt(c2)) {
          c1 += j - 1;
          c2--;
          break;
        }
        if (c2 + j < l2 && s1.charAt(c1) == s2.charAt(c2 + j)) {
          c1--;
          c2 += j - 1;
          break;
        }
      }
    }
    c1++;
    c2++;
    // this covers the case where the last match is on the last token in list, so that it can compute transpositions correctly
    if (c1 >= l1 || c2 >= l2) {
      lcss += local_cs;
      local_cs = 0;
      c1 = c2 = Math.min(c1, c2);
    }
  }
  lcss += local_cs;

  return Math.round(Math.max(l1, l2) - lcss + trans); //add the cost of transpositions to the final result
};

export const findClosestDomain = (
  domain: string,
  domains: string[],
): Result<string | null, string> => {
  const DISTANCE_THRESHOLD = 2;
  let dist;
  let minDist = Infinity;
  let closestDomain = null;

  for (var i = 0; i < domains.length; i++) {
    if (domain === domains[i]) {
      return ok(domain);
    }

    dist = sift4Distance(domain, domains[i]);

    if (dist < minDist) {
      minDist = dist;
      closestDomain = domains[i];
    }
  }

  if (minDist <= DISTANCE_THRESHOLD && closestDomain !== null) {
    return ok(closestDomain);
  } else {
    return ok(null);
  }
};

export const mailcheck = (
  inputAddress: Readonly<string>,
): Result<EmailSuggestion | null, string> => {
  const email = inputAddress.toLowerCase();
  const parsedEmailResult = parseEmailAddress(email);

  if (parsedEmailResult.isErr()) {
    return err(
      "Unable to check email. The provided address was invalid and could not be parsed.",
    );
  }

  const closestDomainResult = findClosestDomain(
    parsedEmailResult.value.domain,
    defaultDomains,
  );

  if (closestDomainResult.isErr()) {
    return err("Something went wrong. Unable to find suggestion.");
  }

  if (closestDomainResult.value === parsedEmailResult.value.domain) {
    // The email address exactly matches one of the supplied domains; do not return a suggestion.
    return ok(null);
  }

  // If closestDomainResult.value is null, the domain wasn't found in the top-level list
  if (closestDomainResult.value !== null) {
    // The email address closely matches one of the supplied domains; return a suggestion
    return ok({
      address: parsedEmailResult.value.address,
      domain: closestDomainResult.value,
      full: parsedEmailResult.value.address + "@" + closestDomainResult.value,
    });
  }

  // The email address does not closely match one of the supplied domains
  const closestSecondLevelDomainResult = findClosestDomain(
    parsedEmailResult.value.secondLevelDomain,
    secondLevelDomains,
  );

  if (closestSecondLevelDomainResult.isErr()) {
    return err("Something went wrong. Unable to find suggestion.");
  }

  const closestTopLevelDomainResult = findClosestDomain(
    parsedEmailResult.value.topLevelDomain,
    topLevelDomains,
  );

  if (closestTopLevelDomainResult.isErr()) {
    return err("Something went wrong. Unable to find suggestion.");
  }

  let closestDomain = parsedEmailResult.value.domain;
  let rtrn = false;

  if (
    closestSecondLevelDomainResult.value !== null &&
    closestSecondLevelDomainResult.value !==
      parsedEmailResult.value.secondLevelDomain
  ) {
    // The email address may have a mispelled second-level domain; return a suggestion
    closestDomain = closestDomain.replace(
      parsedEmailResult.value.secondLevelDomain,
      closestSecondLevelDomainResult.value,
    );
    rtrn = true;
  }

  if (
    closestTopLevelDomainResult.value !== null &&
    closestTopLevelDomainResult.value !==
      parsedEmailResult.value.topLevelDomain &&
    parsedEmailResult.value.secondLevelDomain !== ""
  ) {
    // The email address may have a mispelled top-level domain; return a suggestion
    closestDomain = closestDomain.replace(
      new RegExp(parsedEmailResult.value.topLevelDomain + "$"),
      closestTopLevelDomainResult.value,
    );
    rtrn = true;
  }

  if (rtrn) {
    return ok({
      address: parsedEmailResult.value.address,
      domain: closestDomain,
      full: `${parsedEmailResult.value.address}@${closestDomain}`,
    });
  }

  return err("Unable to check email address.");
};
