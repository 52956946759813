import { AppContext } from "@src/contexts/AppContext";
import { useContext } from "react";
import { OnboardingApp } from "@tigris/onboarding";
import { useLocation, useNavigate } from "react-router-dom";
import { TransferKind } from "@src/generated/sdk";
import { Routes } from "@src/utils/constants";
import { Amplitude, Sentry } from "@src/utils/telemetry";

export const OnboardingRenderContainer = () => {
  const { session, configuration, partner } = useContext(AppContext);
  const navigate = useNavigate();
  const { search, state } = useLocation();

  return (
    <OnboardingApp
      session={session!}
      network={configuration.network}
      walletAddress={configuration.walletAddress}
      partner={partner}
      telemetry={{ Sentry, Amplitude }}
      initialRoute={
        state
          ? {
              pathname: state?.initialPathname,
              state: {
                post2faOnboardingRoute: state?.post2faOnboardingRoute,
              },
            }
          : undefined
      }
      onReturnToTransfer={(reason) => {
        switch (reason) {
          case "returnToLogin": {
            navigate({ pathname: Routes.LandingSheetLoginEntry, search });
            break;
          }

          case "onboardingComplete": {
            if (configuration.transferKind === TransferKind.CASH_IN) {
              navigate({ pathname: Routes.TransferSheet, search });
            } else {
              navigate({
                pathname: Routes.TransferSheetDepositAddress,
                search,
              });
            }
            break;
          }

          case "onboardingTerminated": {
            navigate({ pathname: Routes.TransferUnavailable, search });
            break;
          }
        }
      }}
    />
  );
};
