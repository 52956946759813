import {
  AuthenticationStrategy,
  Asset,
  Network,
  Position,
  CAIPAsset,
  CAIPNetwork,
  IntegrationMode,
  Configuration,
  SerializedTransferIframeParams,
} from "@src/types";
import * as Factory from "factory.ts";
import { TransferKind } from "@src/generated/sdk";

export const configurationFactory = Factory.Sync.makeFactory<Configuration>({
  partnerId: "meso-dev",
  network: Network.ETHEREUM_MAINNET,
  walletAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  sourceAmount: "100",
  sourceCAIPAsset: CAIPAsset.FIAT_USD,
  destinationCAIPAsset: CAIPAsset.ETHEREUM_MAINNET_ETH,
  destinationAsset: Asset.ETH,
  layout: {
    position: Position.TOP_RIGHT,
    offset: { horizontal: 0, vertical: 0 },
  },
  version: "1.0.0",
  authenticationStrategy: AuthenticationStrategy.HEADLESS_WALLET_VERIFICATION,
  transferKind: TransferKind.CASH_IN,
});

export const configurationQueryParamFactory = Factory.Sync.makeFactory<{
  partnerId: string;
  network: string;
  walletAddress: string;
  sourceAmount?: string;
  destinationAmount?: string;
  sourceAsset?: string;
  destinationAsset: string;
  layoutPosition: string;
  layoutOffset: string;
  version: string;
  authenticationStrategy: string;
}>({
  partnerId: "meso-dev",
  network: Network.ETHEREUM_MAINNET,
  walletAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  sourceAmount: "100",
  destinationAsset: Asset.ETH,
  layoutPosition: Position.TOP_RIGHT,
  layoutOffset: JSON.stringify({ horizontal: 0, vertical: 0 }),
  version: "1.0.0",
  authenticationStrategy: AuthenticationStrategy.HEADLESS_WALLET_VERIFICATION,
});

export const transferLinkConfigurationFactory =
  Factory.Sync.makeFactory<Configuration>({
    partnerId: "meso-dev",
    network: Network.ETHEREUM_MAINNET,
    walletAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    sourceAmount: "100",
    sourceCAIPAsset: CAIPAsset.FIAT_USD,
    destinationCAIPAsset: CAIPAsset.ETHEREUM_MAINNET_ETH,
    destinationAsset: Asset.ETH,
    version: "1.0.0",
    authenticationStrategy: AuthenticationStrategy.BYPASS_WALLET_VERIFICATION,
    transferKind: TransferKind.CASH_IN,
  });

export const transferLinkQueryParamFactory = Factory.Sync.makeFactory<{
  partnerId: string;
  destinationAsset: string;
  sourceAsset: string;
  network: string;
  walletAddress: string;
  sourceAmount?: string;
  destinationAmount?: string;
  version: string;
  redirectUrl?: string;
}>({
  partnerId: "meso-dev",
  destinationAsset: CAIPAsset.ETHEREUM_MAINNET_ETH,
  sourceAsset: CAIPAsset.FIAT_USD,
  network: CAIPNetwork.ETHEREUM_MAINNET,
  walletAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  sourceAmount: "100",
  version: "1.0.0",
});

export const transferAppWindowParamsFactory =
  Factory.Sync.makeFactory<SerializedTransferIframeParams>({
    partnerId: "foo",
    network: Network.ETHEREUM_MAINNET,
    walletAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    sourceAmount: "100",
    sourceAsset: Asset.USD,
    destinationAsset: Asset.ETH,
    layoutPosition: Position.TOP_RIGHT,
    layoutOffset: JSON.stringify({ horizontal: "0", vertical: "0" }),
    authenticationStrategy: AuthenticationStrategy.WALLET_VERIFICATION,
    mode: IntegrationMode.EMBEDDED,
    version: "x.x.x",
  });

export const transferAppQueryStringFactory = Factory.Sync.makeFactory<string>(
  new URLSearchParams(transferAppWindowParamsFactory.build()).toString(),
);
