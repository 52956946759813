export const LANDING_AND_TRANSFER_SHEET_HEIGHT = 390;
export const LANDING_AND_TRANSFER_SHEET_HEIGHT_COLLAPSED = 180;
export const LANDING_AND_TRANSFER_SHEET_STANDALONE_HEIGHT = "23rem";
export const INNER_CARD_HORIZONTAL_ANIMATION_DISTANCE = 48;

export enum Routes {
  LandingSheet = "/",
  LandingSheet2Fa = "/landing/2fa",
  LandingSheetLoginLanding = "/landing/login",
  LandingSheetLoginEntry = "/landing/login/entry",
  TransferSheet = "/transfer",
  TransferSheet2Fa = "/transfer/2fa",
  TransferSheetDepositAddress = "/transfer/deposit-address",
  TransferSheetStatus = "/transfer/status",
  Onboarding = "/onboarding",
  Unknown = "",
  TransferUnavailable = "/transfer-unavailable",
  ActivateUser = "/activate-user",
}

/**
 * _Sometimes, you need to slow down and enjoy the scenery._
 *
 * This helper allows buffering of time to step out animations or transitions as needed.
 */
export const delay = (n = 500) => new Promise((r) => setTimeout(r, n));

/** The Meso maximum transfer amount */
export const MESO_MAX_AMOUNT = 5_000;
/** The Meso minimum transfer amount */
export const MESO_MIN_AMOUNT = 25;
