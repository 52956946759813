import { GenericError, Network } from "@src/types";
import { PublicKey } from "@solana/web3.js";
import { isAddress } from "viem";
import { isEVMBlockchain, isSolanaBlockchain } from "@tigris/common";
import { validate as isValidBitcoinAddress } from "bitcoin-address-validation";

const isValidEVMAddress = (address: string): boolean => {
  return isAddress(address);
};

const isValidSolanaAddress = (address: string): boolean => {
  try {
    return PublicKey.isOnCurve(address);
  } catch (err) {
    return false;
  }
};

export const parseWalletAddress = (
  walletAddress: string,
  network: Network,
): GenericError | undefined => {
  if (isEVMBlockchain(network)) {
    if (!isValidEVMAddress(walletAddress)) {
      return { message: "Invalid ETH wallet address provided." };
    }
  }

  if (isSolanaBlockchain(network)) {
    if (!isValidSolanaAddress(walletAddress)) {
      return { message: "Invalid SOL wallet address provided." };
    }
  }

  if (network === Network.BITCOIN_MAINNET) {
    if (!isValidBitcoinAddress(walletAddress)) {
      return { message: "Invalid BTC wallet address provided." };
    }
  }
};
