import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { twMerge } from "tailwind-merge";

export const FiatInstrument = ({
  display,
  className = "",
}: {
  display?: string;
  className?: string;
}) => {
  className = twMerge(
    "flex flex-auto gap-1 items-center",
    className,
    display ? "opacity-100" : "opacity-50",
  );

  return (
    <div data-testid="FiatInstrument" className={className}>
      <FontAwesomeIcon
        data-testid="FiatInstrumentIcon"
        icon={icon({ name: "credit-card", style: "regular" })}
      />
      {display ? `Ending in ${display}` : "Connect Card"}
    </div>
  );
};
