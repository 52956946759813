import { IntegrationMode } from "@src/types";
import { Result, err, ok } from "neverthrow";
import { ErrorMessages } from "@tigris/mesokit";

export const getTargetWindowOrigin = (mode: IntegrationMode) => {
  if (import.meta.env.VITE_IS_TEST === "true") {
    return "http://some-partner.app";
  } else if (mode === IntegrationMode.WEBVIEW) {
    return window.location.origin;
  } else {
    let origin: Location["origin"];

    if ("ancestorOrigins" in location) {
      origin = location.ancestorOrigins[0];
    } else if (document.referrer) {
      origin = new URL(document.referrer).origin;
    } else {
      origin = window.location.origin;
    }

    return origin;
  }
};

/**
 * Attempt to safely get a handle to a webView window object for post messaging.
 */
export const getWebViewWindowHandle = (): Result<Window, string> => {
  // The react-native-webView library injects a JS bundle with this namespace for post messaging
  // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#onmessage
  if ("ReactNativeWebView" in window) {
    const targetWindow = window.ReactNativeWebView as unknown as Window;

    if ("postMessage" in targetWindow) {
      // We cannot return a reference to `window.ReactNativeWebView` directly as the minified object in the build will
      // lose scope at runtime. Instead, we create a wrapper that calls `window.ReactNativeWebView.postMessage` directly.
      // This solves an issue with the Android implementation of WebViews in React Native.
      return ok({
        postMessage: (data: string) => {
          // @ts-expect-error: We are doing a runtime check, so TS can ignore this
          window.ReactNativeWebView.postMessage(data);
        },
      } as Window);
    }
  }

  // If we are dealing with a custom WebView, in iOS, we expect injected JS to create
  // a namespace of `window.webkit.messageHandlers.meso`
  if ("webkit" in window) {
    const targetWindow = window.webkit as unknown as Window & {
      messageHandlers: { meso?: Window };
    };

    if (
      "messageHandlers" in targetWindow &&
      "meso" in targetWindow.messageHandlers
    ) {
      return ok(targetWindow.messageHandlers.meso as Window);
    }

    return err(
      ErrorMessages.initialization.UNABLE_TO_GET_WEBVIEW_HANDLE_ERROR_IOS,
    );
  }

  return err(ErrorMessages.initialization.UNABLE_TO_GET_WEBVIEW_HANDLE_ERROR);
  // Handle Android (soon)
};
