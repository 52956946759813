import {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  FormEventHandler,
  useCallback,
  useMemo,
} from "react";
import { Title } from "../../Title";
import { Button } from "../../Button";
import { LabelledInput } from "../../Input/LabelledInput";
import { MesoKitContext } from "../../../MesoKitContext";
import {
  Frames,
  FramesBillingAddress,
  PaymentMethod,
  /* cspell:disable-next-line: This is a type from CKO's library. */
  FrameElementIdentifer,
  FramesInitProps,
} from "frames-react";
import {
  AddressInput,
  type AddressInputProps,
  type Suggestion,
} from "../../Address";
import { AnimatePresence, motion } from "framer-motion";
import { z } from "zod";
import {
  billingAddressSchema,
  cardholderNameSchema,
  defaultBillingAddress,
  defaultFormField,
  isPOBoxError,
  isProhibitedRegionError,
  rawAddressContainsPOBox,
} from "@tigris/common";
import { PaymentCardInputFrame } from "./PaymentCardInputFrame";
import { ErrorMessages } from "../../../utils/errorMessages";
import { AddPaymentCardFormProps, FormState } from "./types";
import { usePrevious } from "@uidotdev/usehooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLazyScript } from "../../../hooks/useLazyScript";

enum ToastIds {
  TOKENIZED_CARD_ERROR = "cko_tokenized_card_error",
  CHECKOUT_TOKENIZATION_FAILED = "cko_tokenization",
  ADD_PAYMENT_CARD_API_ERROR = "add_payment_card_api_error",
  PROHIBITED_REGION = "prohibited_region",
  PO_BOX_NOT_ALLOWED = "po_box_not_allowed",
}

const CHECKOUT_SCRIPT_SRC = "https://cdn.checkout.com/js/framesv2.min.js";

const defaultFormState: FormState = {
  isValid: false,
  fields: {
    cardDetails: defaultFormField(""),
    cardholderName: defaultFormField(""),
    billingAddress: defaultFormField(defaultBillingAddress),
  },
};

/**
 * Sanitize card holder name to remove disallowed characters. For now, this only includes digits.
 */
const formatCardholderName = ({
  newValue,
}: {
  previousValue: string;
  newValue: string;
}): string => newValue.replace(/\d+/g, "");

/** The web property the payment card form is render in. */
const property = location.host.includes("account.")
  ? "account app"
  : "transfer app";

/**
 * A component for tokenizing card details with CKO.
 *
 * This has `toast` built-in and will inherit the `sonner` instance from the embedding application.
 */
export const AddPaymentCardForm = ({
  formEnabled,
  formId,
  onTokenizationSuccess,
  disabled = false,
  initialValues,
  sessionId,
}: AddPaymentCardFormProps) => {
  const { sentry, amplitude, toast } = useContext(MesoKitContext);
  const [checkoutScriptLoadStartTime] = useState(() => performance.now());
  const [requestIsInFlight, setRequestIsInFlight] = useState(false);
  /** Determine if the iframes are loaded and ready for use. */
  const [formIsReady, setFormIsReady] = useState(false);
  const { status: checkoutScriptLoadStatus, loadScript } = useLazyScript();
  const [formState, setFormState] = useState<FormState>(() => {
    const initialFormState = defaultFormState;

    if (initialValues?.billingAddress) {
      initialFormState.fields.billingAddress.value =
        initialValues.billingAddress;
      initialFormState.fields.billingAddress.isDirty = true;
      initialFormState.fields.billingAddress.isValid = true;
      initialFormState.fields.billingAddress.isTouched = true;
    }

    if (initialValues?.cardholderName) {
      initialFormState.fields.cardholderName.value =
        initialValues.cardholderName;

      initialFormState.fields.cardholderName.isDirty = true;
      initialFormState.fields.cardholderName.isValid = true;
      initialFormState.fields.cardholderName.isTouched = true;
    }

    return initialFormState;
  });
  const [initialAddressValue] = useState<AddressInputProps["initialValue"]>(
    () => {
      if (initialValues?.billingAddress) {
        return {
          streetLine: initialValues.billingAddress.addressLine1,
          secondary: initialValues.billingAddress.addressLine2,
          city: initialValues.billingAddress.city,
          state: initialValues.billingAddress.state,
          zipcode: initialValues.billingAddress.zip,
          entries: 1,
        };
      }

      return undefined;
    },
  );
  const [cardBrand, setCardBrand] = useState<PaymentMethod>();
  /* cspell:disable-next-line: This is a type from CKO's library. */
  const [frameFocused, setFrameFocused] = useState<FrameElementIdentifer>();
  const isDarkMode = useMemo(
    () => document.documentElement.classList.contains("dark-mode"),
    [],
  );
  const previousFormEnabled = usePrevious(formEnabled);
  const [tokenizationAttempts, setTokenizationAttempts] = useState(0);

  const handleFormSubmit = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      setRequestIsInFlight(true);
      const tokenizationStartTime = performance.now();

      try {
        // Collect metrics/insights
        sentry?.metrics.increment("cko_tokenization_started", 1, {
          tags: {
            sessionId,
            attempts: tokenizationAttempts + 1,
          },
        });
        setTokenizationAttempts((count) => count + 1);

        const result = await Frames.submitCard();

        const onTokenizationError = (
          message: string,
          reason:
            | "invalid_card_type"
            | "invalid_card_scheme"
            | "invalid_card_category",
        ) => {
          const duration = performance.now() - tokenizationStartTime;
          sentry?.metrics.increment("cko_tokenization_complete", 1, {
            tags: {
              status: "error",
              duration,
              reason,
              sessionId,
              property,
              attempts: tokenizationAttempts,
            },
          });
          sentry?.metrics.distribution("cko_tokenization_duration", duration, {
            tags: { type: "3rd party", status: "error", sessionId, property },
            unit: "millisecond",
          });

          toast?.error(message, { id: ToastIds.TOKENIZED_CARD_ERROR });
          Frames.enableSubmitForm();
          setRequestIsInFlight(false);
        };

        // There is a discrepancy in the CKO docs and types. Cases are mixed across the two. Calling `.toLowerCase()` allows us to normalize the response.
        if (import.meta.env.VITE_TIGRIS_ENV === "dev") {
          // We cannot test debit cards in dev. This early return bypasses that constraint on the client-side.
          sentry?.metrics.increment("cko_tokenization_complete", 1, {
            tags: {
              status: "success",
              duration: performance.now() - tokenizationStartTime,
              sessionId,
              property,
              attempts: tokenizationAttempts,
            },
          });
          onTokenizationSuccess(result.token);
        } else if (result.card_type?.toLowerCase() !== "debit") {
          onTokenizationError(
            ErrorMessages.addPaymentCard.DEBIT_CARDS_ONLY_ERROR,
            "invalid_card_type",
          );
        } else if (
          !result.scheme ||
          !["visa", "mastercard"].includes(result.scheme.toLowerCase())
        ) {
          onTokenizationError(
            ErrorMessages.addPaymentCard.UNSUPPORTED_CARD_SCHEME_ERROR,
            "invalid_card_scheme",
          );
        } else if (result.card_category?.toLowerCase() !== "consumer") {
          onTokenizationError(
            ErrorMessages.addPaymentCard.CONSUMER_CARDS_ONLY_ERROR,
            "invalid_card_category",
          );
        } else {
          const duration = performance.now() - tokenizationStartTime;

          sentry?.metrics.increment("cko_tokenization_complete", 1, {
            tags: {
              status: "success",
              duration,
              sessionId,
              property,
              attempts: tokenizationAttempts,
            },
          });
          sentry?.metrics.distribution("cko_tokenization_duration", duration, {
            tags: { type: "3rd party", status: "success", sessionId, property },
            unit: "millisecond",
          });

          onTokenizationSuccess(result.token);
        }
      } catch (err: unknown) {
        setRequestIsInFlight(false);
        toast?.error(
          ErrorMessages.addPaymentCard.CHECKOUT_TOKENIZATION_FAILED,
          { id: ToastIds.CHECKOUT_TOKENIZATION_FAILED },
        );
        sentry?.captureException(err, {
          tags: { integration: "checkout frames" },
        });
      }
    },
    [onTokenizationSuccess, sentry, sessionId, toast, tokenizationAttempts],
  );

  useEffect(() => {
    if (checkoutScriptLoadStatus === "error") {
      sentry?.metrics.increment("cko_iframes_load_error", 1, {
        tags: { type: "3rd party" },
      });
      toast?.error(ErrorMessages.addPaymentCard.GENERIC_ERROR);
    }
  }, [checkoutScriptLoadStatus, sentry?.metrics, toast]);

  // Load CKO script when component mounts
  useEffect(() => {
    if (formEnabled) {
      loadScript(CHECKOUT_SCRIPT_SRC);
    }
  }, [formEnabled, loadScript]);

  useEffect(() => {
    const endTime = performance.now();

    if (!previousFormEnabled && formEnabled && formIsReady) {
      sentry?.metrics.distribution(
        "cko_iframes_ready",
        endTime - checkoutScriptLoadStartTime,
        {
          tags: { type: "3rd party" },
          unit: "millisecond",
        },
      );
      Frames.enableSubmitForm();
      setRequestIsInFlight(false);
    }
  }, [
    amplitude,
    checkoutScriptLoadStartTime,
    formEnabled,
    formIsReady,
    previousFormEnabled,
    sentry?.metrics,
  ]);

  const onAddressResolved = useCallback<AddressInputProps["onAddressResolved"]>(
    (suggestion: Suggestion | null, rawInputValue: string) => {
      if (!suggestion) {
        // If we can't create a full `Suggestion`, we still have the raw input which we can validate for the existence of a PO Box
        if (rawAddressContainsPOBox(rawInputValue)) {
          toast?.error(ErrorMessages.address.PO_BOX_NOT_ALLOWED_ERROR, {
            id: ToastIds.PO_BOX_NOT_ALLOWED,
          });
        } else {
          toast?.dismiss(ToastIds.PO_BOX_NOT_ALLOWED);
        }

        setFormState((previousState) => {
          return {
            ...previousState,
            isValid: false,
            fields: {
              ...previousState.fields,
              billingAddress: {
                value: { ...defaultBillingAddress },
                isValid: false,
                isDirty:
                  previousState.fields.billingAddress.isDirty ||
                  suggestion !== null ||
                  rawInputValue.length > 0,
                isTouched: true,
              },
            },
          };
        });
        return;
      }

      const mappedSuggestion: FramesBillingAddress = {
        addressLine1: suggestion.streetLine,
        addressLine2: suggestion.secondary,
        city: suggestion.city,
        state: suggestion.state,
        zip: suggestion.zipcode,
        country: "US",
      };

      const addressValidationResult =
        billingAddressSchema.safeParse(mappedSuggestion);
      const isValid = addressValidationResult.success;

      if (!isValid) {
        amplitude?.track("Form Input Invalid", {
          formId,
          inputId: "billingAddress",
        });

        if (isPOBoxError(addressValidationResult.error)) {
          toast?.error(ErrorMessages.address.PO_BOX_NOT_ALLOWED_ERROR, {
            id: ToastIds.PO_BOX_NOT_ALLOWED,
          });
        }

        if (isProhibitedRegionError(addressValidationResult.error)) {
          amplitude?.track("Prohibited Region", {
            formId,
            inputId: "billingAddress",
            region: mappedSuggestion.state,
          });
          toast?.error(
            ErrorMessages.addPaymentCard.PROHIBITED_US_AND_TERRITORY_CODE,
            { id: ToastIds.PROHIBITED_REGION },
          );
        }
      } else {
        toast?.dismiss(ToastIds.PROHIBITED_REGION);
        toast?.dismiss(ToastIds.PO_BOX_NOT_ALLOWED);
      }

      setFormState((previousState) => ({
        ...previousState,
        isValid:
          isValid &&
          Object.entries(previousState.fields)
            .filter(([key, _]) => key !== "billingAddress")
            .every(([_, { isValid }]) => isValid),
        fields: {
          ...previousState.fields,
          billingAddress: {
            ...previousState.fields.billingAddress,
            isValid,
            isDirty: true,
            isTouched: true,
            value: mappedSuggestion,
          },
        },
      }));
    },
    [amplitude, formId, toast],
  );

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];

      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  const onBlur = useCallback(
    (fieldName: keyof FormState["fields"]) => () => {
      setFormState((previousState) => ({
        ...previousState,
        isTouched: true,
        isValid: Object.values(previousState.fields).every(
          (field) => field.isValid,
        ),
        isDirty: Object.values(previousState.fields).every(
          (field) => field.isDirty,
        ),
        fields: {
          ...previousState.fields,
          [fieldName]: {
            ...previousState.fields[fieldName],
            isTouched: true,
          },
        },
      }));
    },
    [],
  );

  const onChange = useCallback(
    function onChange<T = string | FramesBillingAddress>({
      fieldName,
      format,
      validationSchema,
    }: {
      fieldName: keyof FormState["fields"];

      format?: (props: { previousValue: T; newValue: T }) => T;
      /**
       * A [Zod](https://zod.dev) schema to perform validation via `safeParse`.
       */
      validationSchema: z.ZodSchema;
    }) {
      return (event: ChangeEvent<HTMLInputElement>) => {
        amplitude?.track("Form Input Changed", {
          formId,
          inputId: fieldName,
        });

        setFormState((previousState) => {
          const newValue =
            typeof format === "function"
              ? format({
                  previousValue: previousState.fields[fieldName].value as T,
                  newValue: event.target.value as T,
                })
              : event.target.value;

          const isDirty =
            previousState.fields[fieldName].isTouched ||
            newValue !== previousState.fields[fieldName].value;

          let isValid = false;

          const result = validationSchema.safeParse(newValue);

          isValid = result.success;
          if (!isValid) {
            amplitude?.track("Form Input Invalid", {
              formId,
              inputId: fieldName,
            });
          }

          return {
            ...previousState,
            isValid:
              isValid &&
              Object.entries(previousState.fields)
                .filter(([key, _]) => key !== fieldName)
                .every(([_, { isValid }]) => isValid),
            fields: {
              ...previousState.fields,
              [fieldName]: {
                ...previousState.fields[fieldName],
                value: newValue,
                isValid,
                isDirty,
              },
            },
          };
        });
      };
    },
    [amplitude, formId],
  );

  const framesConfig = useMemo<FramesInitProps>(
    () => ({
      debug: import.meta.env.VITE_TIGRIS_ENV === "dev",
      publicKey: import.meta.env.VITE_CHECKOUT_PUBLIC_KEY,
      localization: {
        cardNumberPlaceholder: "Card number",
        expiryMonthPlaceholder: "MM",
        expiryYearPlaceholder: "YY",
        cvvPlaceholder: "CVV",
      },
      style: {
        base: {
          color: isDarkMode ? "white" : "rgb(50, 59, 60)",
          // autofill from pw manager may cause unreadable text in dark mode
          backgroundColor: isDarkMode ? "rgb(64 64 64)" : undefined,
          fontFamily: "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
          letterSpacing: "normal",
          fontWeight: "normal",
        },
        invalid: {
          color: "red",
          fontWeight: "normal",
        },
        placeholder: {
          base: {
            opacity: "40%",
            fontFamily:
              "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
            fontWeight: "normal",
            letterSpacing: "normal",
          },
        },
      },
      cardholder: {
        billingAddress: formState.fields.billingAddress.value,
        name: formState.fields.cardholderName.value,
      },
    }),
    [
      formState.fields.billingAddress.value,
      formState.fields.cardholderName.value,
      isDarkMode,
    ],
  );

  const disableInputs = requestIsInFlight || !formIsReady || disabled;
  const disableFormSubmit =
    requestIsInFlight || !formIsReady || !formState.isValid || disabled;

  return (
    <form
      id={formId}
      name={formId}
      onSubmit={handleFormSubmit}
      className="relative flex h-full flex-grow flex-col justify-between gap-2"
      data-testid={formId}
    >
      <div className="flex flex-col gap-1.5">
        <Title.Medium bold>Add Debit Card</Title.Medium>

        <AnimatePresence>
          {checkoutScriptLoadStatus === "ready" && (
            <motion.section
              className="flex flex-col gap-4"
              key="AddPaymentCardInputs"
              data-testid="AddPaymentCardInputs"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <Frames
                config={framesConfig}
                ready={() => {
                  setFormIsReady(true);
                }}
                frameFocus={(e) => {
                  setFrameFocused(e.element);
                  toast?.dismiss(ToastIds.TOKENIZED_CARD_ERROR);
                }}
                frameBlur={(e) => {
                  setFrameFocused((currentFrame) => {
                    if (currentFrame !== e.element) return currentFrame;
                  });
                  setFormState((previousState) => ({
                    ...previousState,
                    fields: {
                      ...previousState.fields,
                      cardDetails: {
                        ...previousState.fields.cardDetails,
                        isTouched: true,
                      },
                    },
                  }));
                }}
                paymentMethodChanged={({ paymentMethod }) => {
                  setCardBrand(paymentMethod);
                }}
                cardValidationChanged={({ isValid }) => {
                  setFormState((previousState) => ({
                    isValid:
                      isValid &&
                      Object.entries(previousState.fields)
                        .filter(([key, _]) => key !== "cardDetails")
                        .every(([_, { isValid }]) => isValid),
                    fields: {
                      ...previousState.fields,
                      cardDetails: {
                        ...previousState.fields.cardDetails,
                        isValid,
                      },
                    },
                  }));
                }}
                cardTokenizationFailed={(error) => {
                  toast?.error(
                    ErrorMessages.addPaymentCard.CHECKOUT_TOKENIZATION_FAILED,
                    { id: ToastIds.CHECKOUT_TOKENIZATION_FAILED },
                  );

                  sentry?.captureException(
                    ErrorMessages.addPaymentCard.CHECKOUT_TOKENIZATION_FAILED,
                    {
                      tags: { integration: "checkout frames" },
                      extra: { error },
                    },
                  );
                }}
              >
                <LabelledInput
                  inputComponent={
                    <PaymentCardInputFrame
                      isFocused={!!frameFocused}
                      disabled={disableInputs}
                      cardBrand={cardBrand}
                      formIsReady={formIsReady}
                    />
                  }
                  isValid={
                    !formState.fields.cardDetails.isTouched ||
                    formState.fields.cardDetails.isValid
                  }
                  name="cardDetails"
                  disabled={disableInputs}
                  labelProps={{ text: "Card Information" }}
                />
              </Frames>

              <div>
                <LabelledInput
                  labelProps={{ text: "Name on Card" }}
                  name="cardholderName"
                  placeholder="Your full name"
                  value={formState.fields.cardholderName.value}
                  isValid={renderFieldAsValid("cardholderName")}
                  disabled={disableInputs}
                  onChange={onChange<string>({
                    fieldName: "cardholderName",
                    format: formatCardholderName,
                    validationSchema: cardholderNameSchema,
                  })}
                  onBlur={onBlur("cardholderName")}
                  maxLength={255}
                  autoComplete="cc-name"
                />

                <div className="mt-1 flex items-center text-xs font-medium tracking-tight opacity-60 dark:text-white">
                  <FontAwesomeIcon
                    icon={icon({ name: "info-circle", style: "solid" })}
                    className="mr-1"
                  />
                  <div>Make sure your name matches what&apos;s on the card</div>
                </div>
              </div>

              <div>
                <AddressInput
                  labelText="Billing Address"
                  onAddressResolved={onAddressResolved}
                  disabled={disableInputs}
                  isValid={renderFieldAsValid("billingAddress")}
                  inputName="billingAddress"
                  initialValue={initialAddressValue}
                  placeholder="Your billing address"
                />
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>

      <Button
        key="AddPaymentCard:button"
        disabled={disableFormSubmit}
        type="submit"
        isLoading={requestIsInFlight}
      >
        Continue
      </Button>
    </form>
  );
};
