const environment = (() => {
  if (/\.sandbox\./.test(location.host)) {
    return "Sandbox";
  }

  if (/\.dev\./.test(location.host)) {
    return "Dev";
  }

  if (/\.preview\./.test(location.host)) {
    return "Preview";
  }

  if (location.host.includes("localhost")) {
    return "Local";
  }

  // If we are in production, return `null`
  return null;
})();

export const EnvironmentBanner = () => {
  // In production we do not want to render this banner
  if (!environment) {
    return null;
  }

  return (
    <div className="flex items-center gap-1 rounded-2xl bg-purple-800 px-2 py-1">
      <div className="h-[10px] w-[10px] rounded-full bg-purple-500"></div>
      <div className="font-mono text-xs font-bold text-white">
        {environment}
      </div>
    </div>
  );
};
