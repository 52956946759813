import { motion } from "framer-motion";
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  PropsWithChildren,
} from "react";
import { twMerge } from "tailwind-merge";
import { spring } from "../utils/animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

type CommonButtonProps = {
  /**
   * Whether the button renders with the `primary` styling.
   *
   * Defaults to `true`.
   */
  primary?: boolean;
  /**
   * Whether to render the loading state or not.
   *
   * Defaults to `false`.
   */
  isLoading?: boolean;
  /**
   * Theme override className
   */
  themeOverride?: string;
  /**
   * Styles to apply to the button's Framer Motion container
   */
  containerClassName?: string;
};

type AsButtonProps = CommonButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: "button";
  };

type AsAnchorTagProps = CommonButtonProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "onClick" | "type"> & {
    as: "a";
    disabled?: boolean;
    href: Required<AnchorHTMLAttributes<HTMLAnchorElement>>["href"];
  };

const buttonAnimation = {
  initial: { opacity: 0, y: 24 },
  exit: { opacity: 0, y: 24, transition: spring },
  animate: { opacity: 1, y: 0, transition: { ...spring, delay: 0.25 } },
};

/** A Meso UI button. */
export const Button = (
  props: PropsWithChildren<AsButtonProps | AsAnchorTagProps>,
) => {
  const {
    children,
    containerClassName = "",
    primary = true,
    isLoading = false,
    themeOverride,
  } = props;

  const buttonClasses = twMerge([
    "h-12 rounded-[16px] px-4 font-bold text-white transition duration-100 flex items-center justify-center hover:opacity-90",
    primary
      ? "bg-primary dark:bg-primary-light hover:opacity-90"
      : "text-neutral-800 bg-neutral-100 border border-neutral-200 hover:opacity-90 dark:text-neutral-200 dark:border-neutral-700 dark:bg-neutral-800",
    props.disabled
      ? "cursor-not-allowed bg-neutral-400 hover:bg-neutral-400 dark:bg-neutral-600 dark:text-neutral-400 dark:hover:bg-neutral-600"
      : "scale-100 active:scale-95",
    props.className ?? "",
  ]);

  return (
    <motion.div
      key="AnimatedButton"
      className={twMerge(["flex flex-col", containerClassName])}
      variants={buttonAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {props.as !== "a" && (
        <button
          disabled={props.disabled}
          className={themeOverride ? themeOverride : buttonClasses}
          onClick={props.onClick}
          data-testid={
            "data-testid" in props ? props["data-testid"] : "continue-button"
          }
          type={props.type ?? "button"}
        >
          {isLoading ? (
            <div className="flex items-center justify-center gap-1">
              <FontAwesomeIcon
                data-testid="LandingSheet:loaderIcon"
                icon={icon({ name: "loader" })}
                size="lg"
                className="fa-spin"
              />
              {children}
            </div>
          ) : (
            children
          )}
        </button>
      )}

      {props.as === "a" && (
        <a
          className={themeOverride ? themeOverride : buttonClasses}
          href={props.href}
          rel={props.rel}
          target={props.target}
          data-testid={
            "data-testid" in props ? props["data-testid"] : "continue-button"
          }
        >
          {isLoading ? (
            <div className="flex items-center justify-center gap-1">
              <FontAwesomeIcon
                data-testid="LandingSheet:loaderIcon"
                icon={icon({ name: "loader" })}
                size="lg"
                className="fa-spin"
              />
              {children}
            </div>
          ) : (
            children
          )}
        </a>
      )}
    </motion.div>
  );
};
