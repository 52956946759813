import { useOnboarding } from "@src/hooks/useOnboarding";
import { Button, LoadingInterstitial, Text } from "@tigris/mesokit";

export const HoldingScreen = () => {
  const { cancelOnboarding } = useOnboarding();

  return (
    <div className="absolute left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-between gap-4 bg-neutral-100 p-4 dark:bg-neutral-800 dark:text-white">
      <div className="flex h-full flex-col items-center justify-center gap-2 text-lg">
        <LoadingInterstitial mode={"waiting"} position="static" />
        <Text className="font-semibold tracking-tight opacity-40">
          Signup in progress...
        </Text>
      </div>
      <Button
        containerClassName="text-sm w-full"
        primary={false}
        onClick={cancelOnboarding}
      >
        Cancel signup
      </Button>
    </div>
  );
};
