// Based on https://github.com/uidotdev/usehooks/blob/630d43dc787ca475c9ae18c3b988131de6b7de81/index.js#L1097
import { useEffect, useState } from "react";

type ScriptSrc = `https://${string}`;

export type UseLazyScript = {
  loadScript(src: ScriptSrc): void;
  status: "awaitingInitialization" | "loading" | "ready" | "error";
};

/**
 * Defer loading of a <script> tag until the application needs it.
 *
 * This is based on the [useScript](https://usehooks.com/usescript) hook, but allows us to wrap that
 * to load on-demand instead of relying on the react lifecycle.
 */
export const useLazyScript = (): UseLazyScript => {
  const [status, setStatus] = useState<UseLazyScript["status"]>(
    "awaitingInitialization",
  );
  const [scriptSrc, setScriptSrc] = useState<ScriptSrc>();

  useEffect(() => {
    if (scriptSrc) {
      setStatus("loading");
      let script: HTMLScriptElement | null = document.querySelector(
        `script[src="${scriptSrc}"]`,
      );

      if (script === null) {
        script = document.createElement("script");
        script.src = scriptSrc;
        script.async = true;
        document.body.appendChild(script);
      }

      const handleScriptLoad = () => setStatus("ready");
      const handleScriptError = () => setStatus("error");

      script.addEventListener("load", handleScriptLoad);
      script.addEventListener("error", handleScriptError);

      return () => {
        script?.removeEventListener("load", handleScriptLoad);
        script?.removeEventListener("error", handleScriptError);

        script?.remove();
      };
    }
  }, [scriptSrc]);

  return {
    loadScript(src: ScriptSrc) {
      setScriptSrc(src);
    },
    status,
  };
};
