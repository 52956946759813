import {
  InputHTMLAttributes,
  Ref,
  forwardRef,
  useCallback,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  "data-testid"?: string;
  isValid?: boolean;
};

export const Input = forwardRef(function InputWithRef(
  {
    autoFocus,
    className,
    disabled,
    id,
    isValid,
    name,
    type = "text",
    ...props
  }: InputProps,
  ref: Ref<HTMLInputElement>,
) {
  const originalInputType: InputProps["type"] = type;
  const [masked, setMasked] = useState(originalInputType === "password");
  const inputClasses = twMerge([
    "input",
    className,
    !isValid && "input-invalid",
  ]);

  const handleMaskToggle = useCallback(() => {
    setMasked(!masked);
  }, [masked]);

  return (
    <div className="relative">
      <input
        className={inputClasses}
        id={id || name}
        aria-label={id || name}
        type={
          originalInputType === "password"
            ? masked
              ? "password"
              : "text"
            : originalInputType
        }
        autoFocus={autoFocus}
        disabled={disabled}
        ref={ref}
        name={name}
        {...props}
      />
      {originalInputType === "password" && (
        <div className="absolute right-0 top-0 flex h-full w-8 items-center pr-2">
          <FontAwesomeIcon
            className="text-neutral-600 opacity-50 dark:text-white"
            icon={masked ? icon({ name: "eye" }) : icon({ name: "eye-slash" })}
            onClick={handleMaskToggle}
          />
        </div>
      )}
    </div>
  );
});
