export const formatTaxpayerIdLast4 = ({
  newValue: taxpayerIdLast4,
}: {
  newValue: string;
}): string => taxpayerIdLast4.replace(/\D/g, "").substring(0, 4);

export const formatDateString = ({
  previousValue,
  newValue,
}: {
  previousValue: string;
  newValue: string;
}): string => {
  // only allow digit or '/' chars with max length of 10 (i.e. MM/DD/YYYY)
  const filteredDate = newValue
    .replace(/\s/g, "")
    .replace(/-/g, "/")
    .replace(/[^0-9/]/g, "")
    .substring(0, 10);

  // return filtered date if deleting, typing invalid char, greater than 10 chars
  const isAdding = previousValue.length < newValue.length;
  if (previousValue == filteredDate || !isAdding) return filteredDate;

  if (
    (/^\d+$/.test(filteredDate) && filteredDate.length === 8) ||
    filteredDate.length === 6
  ) {
    return `${filteredDate.substring(0, 2)}/${filteredDate.substring(
      2,
      4,
    )}/${filteredDate.substring(4)}`;
  }

  const length = filteredDate.length;
  const lastCharIsSlash = filteredDate.endsWith("/");
  if (length == 1 || length == 4) {
    // only allow digit for first char in month and date portions
    return lastCharIsSlash ? filteredDate.slice(0, -1) : filteredDate;
  } else if (length == 2 || length == 5) {
    // pad with 0 if month or date portion is single digit, auto add '/' otherwise
    return lastCharIsSlash
      ? `${filteredDate.slice(0, -2)}0${filteredDate.slice(-2, -1)}/`
      : `${filteredDate}/`;
  } else if (length == 3 || length == 6) {
    // edge case for positions where '/' should be, but last char is not '/' due to deleting '/' and entering digit
    return lastCharIsSlash
      ? filteredDate
      : `${filteredDate.slice(0, -1)}/${filteredDate.slice(-1)}`;
  } else {
    // length is > 6, so only allow digit for year portion
    return lastCharIsSlash ? filteredDate.slice(0, -1) : filteredDate;
  }
};
