import useTransfer, { TRANSFER_SUCCESS_STATUSES } from "@src/hooks/useTransfer";
import { Amplitude, Sentry } from "@src/utils/telemetry";
import { AppContext } from "@src/contexts/AppContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "@src/utils/constants";
import { Button, Text } from "@tigris/mesokit";
import {
  IntegrationMode,
  TransferStatus,
  UserStatus,
  MessageKind,
} from "@src/types";
import { TransferStatusIndicator } from "./TransferStatusIndicator";
import { Variants, motion } from "framer-motion";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { spring } from "@src/utils/animation";
import { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PasskeyRegistrationCard } from "./PasskeyRegistrationCard";

const animationVariants: Variants = {
  initial: { opacity: 0, x: 56 },
  animate: {
    opacity: 1,
    x: 0,
    transition: { ...spring },
  },
  exit: { x: -56, opacity: 0 },
};

export const TransferSheetStatus = () => {
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const {
    bus,
    configuration: { redirectUrl, returnButtonContent },
    transfer,
    api: { resolveUser },
    session,
    hasPasskey,
    browserSupportsWebAuthn,
    mode,
    partner,
  } = useContext(AppContext);
  const updatedTransfer = useTransfer(transfer?.id);
  /* If the user successfully adds a passkey, we still want to show the
   * PasskeyRegistrationCard as it shows the success UI. Utilize useRef to only
   * read/initialize the hasPasskey value once from context. */
  const alreadyHasPasskey = useRef(hasPasskey);
  const showPasskeyRegistration =
    session &&
    session.passkeysEnabled &&
    !alreadyHasPasskey.current &&
    browserSupportsWebAuthn;

  useEffect(() => {
    const processUpdatedTransfer = async () => {
      if (!updatedTransfer) return;

      switch (updatedTransfer.status) {
        case TransferStatus.APPROVED:
          if (state && state.executeTransferStartTime) {
            Sentry.metrics.distribution(
              "execute_transfer_approved",
              performance.now() - state.executeTransferStartTime,
              { unit: "millisecond" },
            );
          }

          setTimeout(() => {
            Amplitude.track("Transfer Approved");
            bus?.emit({
              kind: MessageKind.TRANSFER_UPDATE,
              payload: {
                id: updatedTransfer.id,
                status: updatedTransfer.status,
                updatedAt: updatedTransfer.updatedAt,
              },
            });
          }, 3e3);
          break;
        case TransferStatus.COMPLETE:
          setTimeout(() => {
            Amplitude.track("Transfer Complete");
            bus?.emit({
              kind: MessageKind.TRANSFER_UPDATE,
              payload: {
                id: updatedTransfer.id,
                status: updatedTransfer.status,
                updatedAt: updatedTransfer.updatedAt,
                networkTransactionId: updatedTransfer.networkTransactionId,
              },
            });
          }, 3e3);
          break;
        case TransferStatus.DECLINED:
          Amplitude.track("Transfer Declined");

          bus?.emit({
            kind: MessageKind.TRANSFER_UPDATE,
            payload: {
              id: updatedTransfer.id,
              status: updatedTransfer.status,
              updatedAt: updatedTransfer.updatedAt,
            },
          });

          const userResult = await resolveUser();

          if (userResult.isOk()) {
            const user = userResult.value;

            if (
              user?.__typename === "User" &&
              user.status === UserStatus.FROZEN
            ) {
              navigate({ pathname: Routes.TransferUnavailable, search });
              return;
            }
          }

          navigate(
            {
              pathname: Routes.TransferSheet,
              search,
            },
            {
              state: {
                declineReason: updatedTransfer.frontendDeclineReason,
                error: true,
              },
            },
          );

        default:
          break;
      }
    };
    processUpdatedTransfer();
  }, [bus, navigate, resolveUser, search, state, updatedTransfer]);

  if (mode === IntegrationMode.STANDALONE) {
    const success =
      updatedTransfer?.status &&
      TRANSFER_SUCCESS_STATUSES.includes(updatedTransfer.status);
    return (
      success && (
        <motion.div
          data-testid="TransferSheetStatus"
          key="TransferSheet:content"
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          {showPasskeyRegistration ? (
            <PasskeyRegistrationCard />
          ) : (
            <div
              key="TransferSheetStatusCard"
              data-testid="TransferSheetStatusCard"
              className="flex items-center gap-2 rounded-3xl bg-white p-4 shadow-lg dark:bg-neutral-800"
            >
              <FontAwesomeIcon
                icon={icon({ name: "check-circle" })}
                className="text-primary dark:text-primary-light mr-1 h-4 w-4"
              />
              <div className="flex flex-col">
                <Text className="text-base font-bold">
                  {`Transfer ${
                    updatedTransfer.status === TransferStatus.APPROVED
                      ? "Approved"
                      : "Complete"
                  }`}
                </Text>
                <Text className="text-sm">
                  {`${
                    updatedTransfer.status === TransferStatus.APPROVED
                      ? "Once finalized, a receipt will be sent to your email."
                      : "We've sent a receipt to your email."
                  } You can close this window.`}
                </Text>
              </div>
            </div>
          )}
          {redirectUrl && (
            <Button
              data-testid="TransferSheetStatusRedirectButton"
              primary={false}
              className="mt-2 bg-white dark:bg-transparent"
              onClick={() => window.location.assign(redirectUrl)}
            >
              {returnButtonContent ? (
                returnButtonContent
              ) : (
                <>Back to {partner?.displayName ?? "Partner"}</>
              )}
            </Button>
          )}
        </motion.div>
      )
    );
  } else if (mode === IntegrationMode.INLINE) {
    return (
      <div className="absolute top-3 w-full text-center text-sm">
        <TransferStatusIndicator
          status={updatedTransfer?.status}
          bloom={false}
        />
      </div>
    );
  } else {
    return (
      <motion.div
        initial={{ height: 340 }}
        animate={{ height: 180, transition: spring }}
        exit={{ scale: 0.8, opacity: 0, y: -56 }}
        data-testid="TransferSheetStatus"
        key="TransferSheetStatus"
        className="absolute left-0 top-0 w-full"
      >
        <div className="absolute bottom-3 w-full text-center text-sm">
          <TransferStatusIndicator status={updatedTransfer?.status} />
        </div>
      </motion.div>
    );
  }
};
