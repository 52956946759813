import { AuthCode, AuthCodeRef, ErrorMessages } from "@tigris/mesokit";
import { useCallback, useMemo, useRef, useState } from "react";
import { Amplitude, Sentry } from "../utils/telemetry";
import { TwoFactorMethod } from "../generated/sdk";
import { toast } from "sonner";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { getNextOnboardingStep } from "../utils/getNextOnboardingStep";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { AutoFocusRef } from "../utils/autoFocusRef";

const TOAST_ID = "Phone2Fa";

// Depending on the context, a post2fa route can come from:
// - URL params (when onboarding is running from an inline/embedded transfer)
// - Navigation state (when onboarding is running from a standalone transfer)
const getPost2faOnboardingRoute = (state?: object) => {
  let post2faPathname: string | null = null;

  if (state && "post2faOnboardingRoute" in state) {
    post2faPathname = state.post2faOnboardingRoute as string;
  } else {
    const params = new URLSearchParams(location.search);
    post2faPathname = params.get("post2faOnboardingRoute");
  }

  const route = Object.entries(Routes).find(([_, pathname]) => {
    return pathname === post2faPathname;
  });

  if (route) {
    return route[1];
  }

  return null;
};

export const Phone2Fa = () => {
  const {
    navigate,
    currentRoute: { state },
  } = useRouter();
  const {
    api: { resolveSend2FACode, resolveVerifyPhoneNumber, resolveVerify2FA },
  } = useApi();
  const post2faOnboardingRoute = useRef(getPost2faOnboardingRoute(state));
  const { user } = useOnboarding();
  const authCodeRef = useRef<AuthCodeRef>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleError = useCallback(() => {
    setIsLoading(false);
    authCodeRef.current?.clear();
    toast.error(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR, {
      id: TOAST_ID,
    });

    setTimeout(() => {
      authCodeRef.current?.focus();
    });
  }, [setIsLoading, authCodeRef]);

  const verifyPhoneChallenge = useCallback(
    async (verificationCode: string) => {
      setIsLoading(true);

      if (state && "verify" in state && state.verify === true) {
        const verifyPhoneNumberResult = await resolveVerifyPhoneNumber({
          input: { verificationCode },
        });

        if (verifyPhoneNumberResult.isErr()) {
          handleError();

          return;
        }

        const nextStep = getNextOnboardingStep(
          Routes.Phone2Fa,
          verifyPhoneNumberResult.value,
          user.status,
        );

        if (nextStep.isErr()) {
          toast.error(nextStep.error, { id: TOAST_ID });
          return;
        }
        navigate(nextStep.value.pathname, nextStep.value.state);
      } else {
        const verify2FAResult = await resolveVerify2FA({
          input: { code: verificationCode },
        });

        if (verify2FAResult.isErr()) {
          handleError();
          return;
        }

        if (!post2faOnboardingRoute.current) {
          setIsLoading(false);
          toast.error(ErrorMessages.twoFactorAuth.AUTH_FLOW_ERROR, {
            id: TOAST_ID,
          });

          Sentry.captureMessage(ErrorMessages.twoFactorAuth.AUTH_FLOW_ERROR, {
            level: "warning",
          });
          return;
        }

        navigate(post2faOnboardingRoute.current);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAuthCodeChange = useMemo(
    () => async (result: string) => {
      Amplitude.track("Form Input Changed", {
        formId: "Phone2Fa",
        inputId: "authCode",
      });
      if (result.length === 6) {
        Amplitude.track("[Amplitude] Form Submitted", {
          "[Amplitude] Form Destination": window.location.href,
          "[Amplitude] Form ID": "Phone2Fa",
          "[Amplitude] Form Name": "Phone2Fa",
        });
        await verifyPhoneChallenge(result);
      }
    },
    [verifyPhoneChallenge],
  );

  const sendCode = useCallback(async () => {
    const send2FACodeResult = await resolveSend2FACode({
      input: { method: TwoFactorMethod.PHONE },
    });

    if (send2FACodeResult.isErr()) {
      setIsLoading(false);
      authCodeRef.current?.clear();
      toast.error(send2FACodeResult.error, { id: TOAST_ID });
    } else {
      setIsLoading(false);
      setTimeout(() => authCodeRef.current?.focus());
    }
  }, [resolveSend2FACode]);

  return (
    <AnimationContainer
      onAnimationComplete={AutoFocusRef<AuthCodeRef>(authCodeRef)}
    >
      <form id="Phone2Fa" name="Phone2Fa" className="onboarding-inner-content">
        <Heading
          title="Verify Phone Number"
          subtitle={`Enter the code sent to ${user.phoneNumber}`}
        />
        <AuthCode
          disabled={isLoading}
          onAuthCodeChange={onAuthCodeChange}
          ref={authCodeRef}
          onResend={sendCode}
        />
      </form>
    </AnimationContainer>
  );
};
