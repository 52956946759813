import { AnimatePresence, motion } from "framer-motion";
import { LogoMark } from "./LogoMark";
import { spring } from "../utils/animation";
import "./Logo.css";
import { twMerge } from "tailwind-merge";
import { LOGO_SIZES } from "../utils/constants";

export const Logo = ({
  showText = true,
  size = "sm",
  delay = 0,
  className,
}: {
  showText?: boolean;
  size?: keyof typeof LOGO_SIZES;
  delay?: number;
  className?: string;
}) => {
  const containerClasses = twMerge(
    "lockup-container",
    LOGO_SIZES[size].wrapper,
    className,
  );
  const textClasses = twMerge(
    "meso-text",
    "text-neutral-800 dark:text-white",
    LOGO_SIZES[size].text,
  );

  return (
    <div className={containerClasses} data-testid="Logo">
      <AnimatePresence>
        <LogoMark size={size} delay={delay} />
        {showText && (
          <motion.div
            key="logo-text"
            initial={{ x: -16, opacity: 0.01, skewX: 20 }}
            animate={{
              x: 0,
              opacity: 1,
              skewX: 0,
              transition: { ...spring, delay: delay + 0.3 },
            }}
            className={textClasses}
          >
            Meso
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
