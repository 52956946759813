import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "@src/contexts/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import { spring } from "@tigris/mesokit";
import {
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AssetAmount } from "@src/types";
import { amountBoundariesSchema } from "@src/utils/validation";
import { twMerge } from "tailwind-merge";
import { Amplitude } from "@src/utils/telemetry";

const LIMITS_SUPPORT_URL =
  "https://support.meso.network/hc/en-us/articles/17053929093531-Fees-Limits";

type AmountEditorProps = {
  /** A callback that will fire when the user taps the cancel button. The parent component should use this as a trigger to unmount the `AmountEditor` component. */
  onCancel: () => void;
  /** A callback that will fire when the user chooses an updated amount. */
  onComplete: () => void;
  minimumAmount: number;
  maximumAmount: number;
  errorMessage?: string;
};

// Replaces all non-digit and non-period characters with an empty string.
const sanitizeNumericInput = (input: string | null) => {
  if (!input) return "";

  // First, replace any non-digit and non-period characters.
  const clean = input.replace(/[^\d.]/g, "");

  // Match valid numbers with up to two decimal places. When selecting crypto amounts this will need to be changed.
  const match = clean.match(/^\d*(\.\d{0,2})?/);

  // Return the match if found, otherwise return an empty string.
  return match ? match[0] : "";
};

export const AmountEditor = ({
  onComplete,
  onCancel,
  minimumAmount,
  maximumAmount,
  errorMessage: providedErrorMessage,
}: AmountEditorProps) => {
  const amountValidator = useMemo(() => {
    return amountBoundariesSchema(`${minimumAmount}`, `${maximumAmount}`);
  }, [maximumAmount, minimumAmount]);
  const {
    configuration: { sourceAmount, destinationAsset },
    updateConfiguration,
  } = useContext(AppContext);
  const [newAmount, setNewAmount] = useState<AssetAmount>(
    () => sourceAmount || ("" as AssetAmount),
  );
  // Store the initial amount passed in when this component is mounted
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialAmount = useMemo(() => sourceAmount, []);
  const atUserLimit = useMemo(
    () => minimumAmount >= maximumAmount,
    [maximumAmount, minimumAmount],
  );
  const [formIsValid, setFormIsValid] = useState(
    () =>
      !atUserLimit &&
      Number(sourceAmount) <= maximumAmount &&
      Number(sourceAmount) >= minimumAmount,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    providedErrorMessage,
  );
  const amountOptions = useMemo<
    Array<{
      amount: AssetAmount;
      display: string;
    }>
  >(() => {
    return [
      { amount: `${25}`, display: "$25" },
      { amount: `${50}`, display: "$50" },
      { amount: `${100}`, display: "$100" },
      { amount: `${500}`, display: "$500" },
    ];
  }, []);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();

      updateConfiguration({
        sourceAmount: newAmount as AssetAmount,
      });
      onComplete();
    },
    [newAmount, onComplete, updateConfiguration],
  );

  const handleNewAmountChange = useCallback<(newValue: AssetAmount) => void>(
    (newValue) => {
      const updatedAmount = sanitizeNumericInput(newValue);

      if (updatedAmount.length === 0) {
        setFormIsValid(false);
        setErrorMessage(undefined);
        setNewAmount(updatedAmount as AssetAmount);
        return;
      }

      const validationResult = amountValidator.safeParse(updatedAmount);

      if (!validationResult.success) {
        setErrorMessage(validationResult.error.issues[0].message);
        setFormIsValid(false);
      } else {
        setFormIsValid(true);
        setErrorMessage(undefined);
      }

      setNewAmount(updatedAmount as AssetAmount);
    },
    [amountValidator],
  );

  const handleCancel = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      onCancel();
    },
    [onCancel],
  );

  // Send tracking events to Amplitude.
  useEffect(() => {
    Amplitude.track("Amount editor initialized", {
      initialAmount,
    });

    return () => {
      Amplitude.track("Amount editor unmounted", { initialAmount, newAmount });
    };
  }, [initialAmount, newAmount, sourceAmount]);

  // After first render, perform validation on the provided amount
  // This is useful for situations when a user is automatically taken to the amount editor because their requested amount exceeds their limit.
  useEffect(() => {
    handleNewAmountChange(newAmount);

    // Ensure this only runs once, after first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      initial={{ x: -48, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 48, opacity: 0 }}
      transition={spring}
      className="h-full text-neutral-800 dark:text-white"
    >
      <form
        noValidate
        onSubmit={handleSubmit}
        className="flex h-full flex-col p-6"
      >
        <div className="flex items-center justify-between">
          {!atUserLimit && (
            <button
              type="button"
              onClick={handleCancel}
              className="h-10 w-12 cursor-pointer items-center gap-1 rounded-full border border-neutral-800/10 text-sm opacity-60 transition-opacity hover:bg-neutral-800/5 hover:opacity-100 active:scale-95 dark:border-white/10 dark:hover:bg-white/5"
            >
              <FontAwesomeIcon
                icon={icon({ name: "xmark", style: "solid" })}
                size="xl"
              />
            </button>
          )}
          <div className="absolute left-1/2 translate-x-[-50%] font-semibold">
            Buy {destinationAsset}
          </div>
          {!atUserLimit && formIsValid && (
            <button
              type="submit"
              className="dark:bg-primary-light bg-primary hover:bg-primary/90 dark:hover:bg-primary-light/90 h-10 w-12 rounded-full px-4 py-2 text-sm text-white transition-opacity active:scale-95"
              disabled={!formIsValid}
              data-testid="SubmitButton"
            >
              <FontAwesomeIcon icon={icon({ name: "check", style: "solid" })} />
            </button>
          )}
        </div>
        <label
          htmlFor="AmountInput"
          className="relative flex h-full items-center justify-center font-semibold"
        >
          <div className="text-[3rem] opacity-50">$</div>
          <input
            id="AmountInput"
            name="AmountInput"
            className="max-w-full bg-transparent text-[3rem] tabular-nums outline-none"
            style={{
              width: `${newAmount.length === 0 ? 2 : newAmount.length}ch`,
            }}
            value={newAmount}
            onChange={(event) =>
              handleNewAmountChange(event.target.value as AssetAmount)
            }
            disabled={atUserLimit}
            placeholder={amountOptions[0].amount}
            inputMode="decimal"
            autoComplete="off"
            data-testid="AmountInput"
          />
          <AnimatePresence>
            {errorMessage && (
              <motion.div
                initial={{ y: 16, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 16, opacity: 0 }}
                className="absolute bottom-0 py-2 text-center text-xs text-red-600 dark:text-orange-400"
                data-testid="ErrorMessage"
              >
                {atUserLimit ? (
                  <span className="">
                    {
                      "You are at your current rolling limit maximum and cannot transfer at this time. For details on our limits see this "
                    }
                    <a
                      href={LIMITS_SUPPORT_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary dark:text-primary-light font-semibold no-underline hover:opacity-75"
                      onClick={() =>
                        Amplitude.track("Link Clicked", {
                          url: LIMITS_SUPPORT_URL,
                        })
                      }
                    >
                      support article
                    </a>
                    .
                  </span>
                ) : (
                  errorMessage
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </label>

        <div className="flex gap-2">
          {amountOptions.map(({ amount, display }) => {
            const disabled = Number(amount) > Number(maximumAmount);
            return (
              <button
                disabled={disabled}
                type="button"
                onClick={() => {
                  updateConfiguration({
                    sourceAmount: amount,
                  });
                  onComplete();
                }}
                key={display}
                data-testid={`PrefillPill-${amount}`}
                className={twMerge(
                  "h-10 w-full rounded-full border border-neutral-200 p-1 text-center font-semibold transition-all disabled:cursor-not-allowed disabled:opacity-55 dark:border-neutral-700",
                  !disabled && "hover:-translate-y-1 hover:bg-white/10",
                )}
              >
                {display}
              </button>
            );
          })}
        </div>
      </form>
    </motion.div>
  );
};
