import { AppContext } from "@src/contexts/AppContextProvider";
import { useContext } from "react";
import { MesoToaster } from "@tigris/mesokit";
import { AnimatePresence, motion } from "framer-motion";

/**
 * @returns Sonner Toaster wrapped in div with transform property to allow
 * absolute positioning (vs. fixed by default)
 */
export function Toast() {
  // Key Sonner Toaster component by id, allowing to clear all toasts on id change
  const { toasterId } = useContext(AppContext);
  return (
    <AnimatePresence>
      <motion.div
        key={toasterId}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute z-50 w-full transform"
      >
        <MesoToaster
          key={toasterId}
          style={{
            left: "50%",
            top: ".5rem",
            transform: "translateX(-50%)",
            width: "calc(100% - 16px)",
          }}
          duration={300000}
        />
      </motion.div>
    </AnimatePresence>
  );
}
