import {
  FrontendDeclineReason,
  TransferFragment,
  TransferStatus,
} from "@src/generated/sdk";
import * as Factory from "factory.ts";
import { nanoid } from "nanoid";

const NETWORK_TRANSACTION_ID =
  "0xeef10fc5170f669b86c4cd0444882a96087221325f8bf2f55d6188633aa7be7c";

const transferFactory = Factory.Sync.makeFactory<TransferFragment>({
  __typename: "Transfer",
  id: `transfer:${nanoid(8)}`,
  status: TransferStatus.APPROVED,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  networkTransactionId: NETWORK_TRANSACTION_ID,
});

export const transferExecutingFactory = transferFactory.extend({
  status: TransferStatus.EXECUTING,
  networkTransactionId: "",
});

export const transferCompleteFactory = transferFactory.extend({
  status: TransferStatus.COMPLETE,
});

export const transferDeclinedFactory = transferFactory.extend({
  status: TransferStatus.DECLINED,
  frontendDeclineReason: FrontendDeclineReason.BANK_DECLINE,
  networkTransactionId: "",
});

export const transferApprovedFactory = transferFactory.extend({
  status: TransferStatus.APPROVED,
});
