import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApp } from "@src/hooks/useApp";
import { spring, Text } from "@tigris/mesokit";
import { motion } from "framer-motion";

export const AnnouncementBanner = ({ className }: { className?: string }) => {
  const { announcementBanner } = useApp();

  if (!announcementBanner) return null;

  return (
    <motion.div
      className={`sm:w-ts-card bg-warning rounded-ts-card mt-auto max-h-full w-full p-4 shadow-xl sm:mt-0 ${className ?? ""}`}
      initial={{ y: 24, opacity: 0, scale: 0.95 }}
      animate={{
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ...spring, delay: 0.25 },
      }}
    >
      <div className="mb-0.5 flex items-center gap-1.5">
        <motion.div
          initial={{ rotate: 0, scale: 1 }}
          animate={{ rotate: [-12, -24, 0, -24, -12], scale: [1, 1.2, 1] }}
          transition={{
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 1,
          }}
        >
          <FontAwesomeIcon
            icon={icon({ name: "bullhorn", style: "regular" })}
            className="text-yellow-800"
          />
        </motion.div>
        <Text className="font-bold tracking-tight dark:text-yellow-900">
          {announcementBanner.title}
        </Text>
      </div>
      <Text className="line-clamp-2 leading-tight tracking-tight dark:text-yellow-800">
        {announcementBanner.body}
      </Text>
    </motion.div>
  );
};
