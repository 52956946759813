import { AppContext } from "@src/contexts/AppContext";
import { IntegrationMode, MessageKind } from "@src/types";
import { Title, Text, Logo, Button } from "@tigris/mesokit";
import { useContext } from "react";

export const TransferUnavailable = () => {
  const { bus, mode } = useContext(AppContext);

  return (
    <div className="rounded-ts-card flex h-full w-full flex-col items-center justify-between gap-6 p-6 text-center">
      <Logo size="md" />

      <div>
        <Title.Medium bold>Account Under Review</Title.Medium>
        <Text>
          Your account is under review. We will contact you shortly with the
          next steps. If you have any questions please visit our{" "}
          <a
            href="https://support.meso.network/"
            target="_blank"
            className="underline transition-opacity hover:opacity-90"
            rel="noreferrer"
          >
            support center
          </a>
          .
        </Text>

        {mode !== IntegrationMode.INLINE && (
          <Text className="mt-6">You may now close this window.</Text>
        )}
      </div>

      {(mode === IntegrationMode.EMBEDDED ||
        mode === IntegrationMode.WEBVIEW) && (
        <Button
          onClick={() => {
            bus!.emit({ kind: MessageKind.CLOSE });
          }}
          containerClassName="w-full"
        >
          Close
        </Button>
      )}
      {mode === IntegrationMode.STANDALONE && <div />}

      {mode === IntegrationMode.INLINE && <div />}
    </div>
  );
};
