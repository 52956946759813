import { Result, err, ok } from "neverthrow";
import { ErrorMessages } from "@tigris/mesokit";
import { CurrentRoute, Routes, UserStatus } from "../types";
import { LoginFragment } from "../generated/sdk";

type ProfileStatus = LoginFragment["profileStatus"];

const onboardingSteps: Array<
  [keyof Omit<ProfileStatus, "__typename">, Routes]
> = [
  ["acceptedTerms", Routes.Root],
  ["account", Routes.CreateAccount],
  ["phone", Routes.PhoneEntry],
  ["phoneVerified", Routes.PhoneEntry],
  ["basicInfo", Routes.BasicInfoOverview],
  ["residentialAddress", Routes.BasicInfoOverview],
  ["taxpayerId", Routes.BasicInfoOverview],
  ["financialInstrument", Routes.AddPaymentCard],
];

/**
 * Determine next navigation step based on current user & app state.
 */
export const getNextOnboardingStep = (
  route: string,
  profileStatus: ProfileStatus,
  userStatus?: UserStatus | string,
): Result<CurrentRoute, string> => {
  switch (route) {
    case Routes.Root: {
      const next = onboardingSteps.find(
        ([stepName]) => !profileStatus[stepName],
      );

      if (next) {
        return ok({ pathname: next[1] });
      }
      break;
    }

    case Routes.AddPaymentCard: {
      if (userStatus === UserStatus.NEW) {
        return ok({ pathname: Routes.Summary });
      }

      break;
    }

    case Routes.CreateAccount: {
      const next = onboardingSteps.find(
        ([stepName]) => !profileStatus[stepName],
      );

      if (next) {
        return ok({ pathname: next[1] });
      }

      if (userStatus === UserStatus.NEW) {
        return ok({ pathname: Routes.Summary });
      }

      break;
    }

    case Routes.PhoneEntry:
      return ok({ pathname: Routes.Phone2Fa, state: { verify: true } });

    case Routes.Phone2Fa:
      return ok({ pathname: Routes.BasicInfoOverview });

    case Routes.BasicInfoEntry: {
      if (!profileStatus.taxpayerId) {
        return ok({ pathname: Routes.TaxpayerIdEntry });
      }

      return ok({ pathname: Routes.AddPaymentCard });
    }

    case Routes.TaxpayerIdEntry:
      return ok({ pathname: Routes.AddPaymentCard });
  }

  return err(ErrorMessages.onboardingSteps.UNABLE_TO_DETERMINE_NEXT_STEP);
};
