import * as Factory from "factory.ts";
import { genericErrorFactory } from "./error";
import {
  profileStatusFactory,
  returningUserProfileStatusFactory,
} from "./profileStatus";
import {
  LoginWithEmailAndPasswordMutation,
  LoginWithWalletMutation,
  LoginFragment,
} from "@src/generated/mocks";
import { UserStatus } from "@src/types";

type LoginErrors =
  | Extract<
      LoginWithEmailAndPasswordMutation["loginWithEmailAndPassword"],
      { __typename: "Errors" }
    >
  | Extract<
      LoginWithWalletMutation["loginWithWallet"],
      { __typename: "Errors" }
    >;

export const newUserLoginFactory = Factory.Sync.makeFactory<LoginFragment>({
  __typename: "Login",
  profileStatus: profileStatusFactory.build(),
  needsTwoFactor: true,
  userStatus: UserStatus.NEW,
});

export const provisionalUserLoginFactory =
  Factory.Sync.makeFactory<LoginFragment>({
    __typename: "Login",
    profileStatus: returningUserProfileStatusFactory.build(),
    needsTwoFactor: true,
    userStatus: UserStatus.PROVISIONAL,
  });

export const returningUserLoginFactory =
  Factory.Sync.makeFactory<LoginFragment>({
    __typename: "Login",
    profileStatus: returningUserProfileStatusFactory.build(),
    needsTwoFactor: true,
    userStatus: UserStatus.ACTIVE,
  });

export const loginErrorsFactory = Factory.Sync.makeFactory<LoginErrors>({
  __typename: "Errors",
  errors: [
    genericErrorFactory.build({
      code: "a code",
      enMessage: "We just render the error message from the API",
    }),
  ],
});
