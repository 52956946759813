import { z } from "zod";

const MINIMUM_AGE_IN_YEARS = 18;

export const minimumAgeDate = new Date();
minimumAgeDate.setFullYear(minimumAgeDate.getFullYear() - MINIMUM_AGE_IN_YEARS);

export const maximumAgeDate = new Date();
maximumAgeDate.setFullYear(maximumAgeDate.getFullYear() - 110);

export const dateOfBirthSchema = z
  .string()
  .regex(/\d{2}\/\d{2}\/\d{4}/)
  .pipe(
    z.coerce
      .date()
      .max(minimumAgeDate, "You must be ${MINIMUM_AGE_IN_YEARS} or older.")
      .min(maximumAgeDate, "Age exceeds limits. Please contact support."),
  );

export const taxpayerIdLast4Schema = z
  .string()
  .regex(/^\d{4}$/, "Please enter 4 digits.");

export const firstAndLastNameSchema = z
  .string()
  .min(1, "A valid name is required.");

// via: chatgpt
const ssnRegex = /\b(?!000|666|9\d\d)\d{3}-?(?!00)\d{2}-?(?!0000)\d{4}\b/g;
export const ssnSchema = z
  .string()
  .regex(ssnRegex, "A valid US Social Security Number is required.");
