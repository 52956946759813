import { createContext } from "react";
import { RouterContextValue, Routes } from "../types";

export const RouterContext = createContext<RouterContextValue>({
  currentRoute: { pathname: Routes.Root },
  navigate: () => {},
  outlet: <></>,
  goBack: () => {},
  canGoBack: false,

  enableNavigation: () => {},
  disableNavigation: () => {},
  navigationDisabled: false,
});
