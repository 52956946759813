import {
  AddWalletMutation,
  AddWalletMutationVariables,
  AnonQuoteQuery,
  AnonQuoteQueryVariables,
  CheckTransferQuery,
  CheckTransferQueryVariables,
  ExecuteTransferMutation,
  ExecuteTransferMutationVariables,
  LoginMessageQuery,
  LoginMessageQueryVariables,
  LoginWithEmailAndPasswordMutationVariables,
  LookupUserMutation,
  MutationPerformWalletLoginArgs,
  NewSessionMutation,
  NewSessionMutationVariables,
  PartnerQuery,
  QuoteQuery,
  QuoteQueryVariables,
  RiskSessionQuery,
  Sdk,
  Send2FaCodeMutationVariables,
  Verify2FaMutationVariables,
  CreateCashOutQuoteMutationVariables,
  CreateAnonCashOutQuoteMutationVariables,
  MutationCompletePasskeyRegistrationArgs,
  MutationCompletePasskeyLoginArgs,
  LoginFragment,
  PasskeyCeremony,
  PerformUserActivationMutationVariables,
  ResolveUserActivationStatusQueryVariables,
  PerformUserActivationMutation,
  ResolveUserActivationStatusQuery,
  ThreeDomainSecureSession,
  CreateThreeDomainSecureSessionMutationVariables,
  SetThreeDomainSecureSessionMethodCompletionMutationVariables,
} from "./generated/sdk";
import { AnonCashOutQuote, AuthorizedCashOutQuote } from "./types";
import { Result, err, ok } from "neverthrow";
import { Sentry } from "./utils/telemetry";
import { ClientError } from "graphql-request";
import { ErrorMessages } from "@tigris/mesokit";

export type ResolveUserResult = Result<
  Extract<LookupUserMutation["user"], { __typename: "User" }>,
  string
>;

/* Wallet specific login response, can either be LoginFragment if successful, or
 * prompting for email/password in case of login_failed server response,
 * signaling address was unparsable */
export type LoginWithWalletSuccess =
  | LoginFragment
  | { loginWithEmailAndPassword: true };

export type ResolveActivateUserResult = Result<
  Extract<
    PerformUserActivationMutation["activateUser"],
    { __typename: "UserActivation" }
  >,
  string
>;

export type ResolveVerify2FAResult = Result<true, string>;

export type ResolveUserActivationStatusResult = Result<
  Extract<
    ResolveUserActivationStatusQuery["userActivation"],
    { __typename: "UserActivation" }
  >,
  string
>;

export type ResolveCheckTransferResult = Result<
  Extract<CheckTransferQuery["transfer"], { __typename: "Transfer" }>,
  string
>;

export type QuoteError = {
  message: string;
  isLimitError: boolean;
  code?: QuoteLimitErrorCode;
};

export type ResolveQuoteResult = Result<
  Extract<QuoteQuery["quote"], { __typename: "Quote" }>,
  QuoteError
>;

export type ResolveLoginMessageResult = Result<
  Extract<LoginMessageQuery["loginMessage"], { __typename: "LoginMessage" }>,
  string
>;

export type ResolveRiskSessionResult = Result<
  Extract<RiskSessionQuery["riskSession"], { __typename: "RiskSession" }>,
  string
>;

export type ResolveSend2FACodeResult = Result<true, string>;

/**
 * A fallback to capture exceptions when calling our API. This is not intended for handling known errors such as validation or errors-as-data results.
 */
const reportApiError = (error: unknown, operation: string) => {
  Sentry.captureException(
    error instanceof ClientError ? error.message : error,
    { tags: { operation } },
  );
};

export const QUOTE_ERROR_LIMIT_CODES = [
  "below_min_xfer",
  "above_max_xfer",
  "above_monthly_xfer",
] as const;

export type QuoteLimitErrorCode = (typeof QUOTE_ERROR_LIMIT_CODES)[number];

export const api = (sdk: Sdk) => {
  return {
    async resolveLoginMessage({
      input,
    }: LoginMessageQueryVariables): Promise<ResolveLoginMessageResult> {
      const OPERATION_NAME = "LoginMessage";

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginMessage },
        } = result;

        if (!result || !loginMessage || loginMessage.__typename === "Errors") {
          Sentry.captureMessage(
            ErrorMessages.landingSheet.GENERATE_SIGNING_MESSAGE_ERROR,
            {
              level: "warning",
              tags: { operation: OPERATION_NAME },
              extra: {
                result: JSON.stringify(result),
                requestId: result.headers.get("x-meso-request") ?? "unknown",
              },
            },
          );

          return err(ErrorMessages.landingSheet.GENERATE_SIGNING_MESSAGE_ERROR);
        }

        return ok(loginMessage);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ErrorMessages.landingSheet.GENERATE_SIGNING_MESSAGE_ERROR);
    },

    async resolveSend2FACode({
      input,
    }: Send2FaCodeMutationVariables): Promise<ResolveSend2FACodeResult> {
      const OPERATION_NAME = "Send2FACode";
      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { send2FACode },
        } = result;

        if (
          !result ||
          send2FACode?.__typename === "Errors" ||
          !send2FACode?.success
        ) {
          return err(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
        } else {
          return ok(true);
        }
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
    },

    async resolveUser(): Promise<ResolveUserResult> {
      const OPERATION_NAME = "LookupUser";
      const ERROR_MESSAGE = ErrorMessages.common.LOOKUP_USER_API_ERROR;
      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { user },
        } = result;

        if (!user || user.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(user);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }
      return err(ERROR_MESSAGE);
    },

    async resolveBeginPasskeyRegistration(): Promise<
      Result<PasskeyCeremony, string>
    > {
      const OPERATION_NAME = "BeginPasskeyRegistration";
      const ERROR_MESSAGE =
        ErrorMessages.passkey.UNABLE_TO_BEGIN_PASSKEY_REGISTRATION;

      try {
        const result = await sdk.BeginPasskeyRegistration();
        const {
          data: { beginPasskeyRegistration },
        } = result;

        if (
          !beginPasskeyRegistration ||
          beginPasskeyRegistration.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(beginPasskeyRegistration);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveCompletePasskeyRegistration({
      input,
    }: MutationCompletePasskeyRegistrationArgs): Promise<Result<true, string>> {
      const OPERATION_NAME = "CompletePasskeyRegistration";
      const ERROR_MESSAGE = ErrorMessages.passkey.PASSKEY_REGISTRATION_FAILED;

      try {
        const result = await sdk.CompletePasskeyRegistration({ input });
        const {
          data: { completePasskeyRegistration },
        } = result;

        if (
          !completePasskeyRegistration ||
          completePasskeyRegistration.__typename === "Errors" ||
          !completePasskeyRegistration.success
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(true);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveBeginPasskeyLogin(): Promise<Result<PasskeyCeremony, string>> {
      const OPERATION_NAME = "BeginPasskeyLogin";
      const ERROR_MESSAGE = ErrorMessages.passkey.UNABLE_TO_BEGIN_PASSKEY_LOGIN;

      try {
        const result = await sdk.BeginPasskeyLogin();
        const {
          data: { beginPasskeyLogin },
        } = result;

        if (!beginPasskeyLogin || beginPasskeyLogin.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(beginPasskeyLogin);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveCompletePasskeyLogin({
      input,
    }: MutationCompletePasskeyLoginArgs): Promise<
      Result<LoginFragment, string>
    > {
      const OPERATION_NAME = "CompletePasskeyLogin";
      const ERROR_MESSAGE = ErrorMessages.passkey.PASSKEY_LOGIN_FAILED;

      try {
        const result = await sdk.CompletePasskeyLogin({ input });
        const {
          data: { completePasskeyLogin },
        } = result;

        if (
          !completePasskeyLogin ||
          completePasskeyLogin.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(completePasskeyLogin);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveLoginWithWallet({
      input,
    }: MutationPerformWalletLoginArgs): Promise<
      Result<LoginWithWalletSuccess, string>
    > {
      const OPERATION_NAME = "LoginWithWallet";
      const ERROR_MESSAGE = ErrorMessages.landingSheet.LOGIN_FAILED;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginWithWallet },
        } = result;

        if (!loginWithWallet || loginWithWallet.__typename === "Errors") {
          const errorCode = loginWithWallet?.errors[0].code;

          if (errorCode === "login_failed") {
            return ok({ loginWithEmailAndPassword: true });
          } else if (errorCode === "invalid_signature") {
            return err(ErrorMessages.landingSheet.WALLET_SIGNING_GENERIC_ERROR);
          }

          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(loginWithWallet);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveLoginWithEmailAndPassword({
      input,
    }: LoginWithEmailAndPasswordMutationVariables): Promise<
      Result<LoginFragment, string>
    > {
      const OPERATION_NAME = "LoginWithEmailAndPassword";
      const ERROR_MESSAGE =
        ErrorMessages.landingSheet.EMAIL_PASSWORD_LOGIN_FAILED;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginWithEmailAndPassword },
        } = result;

        if (
          !loginWithEmailAndPassword ||
          loginWithEmailAndPassword.__typename === "Errors"
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(loginWithEmailAndPassword);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAddWallet({
      input,
    }: AddWalletMutationVariables): Promise<
      Result<
        { user: Extract<AddWalletMutation["user"], { __typename: "User" }> },
        string
      >
    > {
      const OPERATION_NAME = "AddWallet";
      const ERROR_MESSAGE = ErrorMessages.addWallet.UNABLE_TO_ADD_WALLET;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addWallet, user },
        } = result;

        if (
          !addWallet ||
          addWallet.__typename === "Errors" ||
          !user ||
          user.__typename === "Errors"
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok({ user });
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolvePartnerDetails(): Promise<
      Result<PartnerQuery["partner"], string>
    > {
      const OPERATION_NAME = "Partner";
      const ERROR_MESSAGE =
        ErrorMessages.partner.UNABLE_TO_RESOLVE_PARTNER_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { partner },
        } = result;

        if (!partner) {
          return err(ERROR_MESSAGE);
        }

        return ok(partner);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveActivateUser({
      input,
    }: PerformUserActivationMutationVariables): Promise<ResolveActivateUserResult> {
      const OPERATION_NAME = "PerformUserActivation";
      const ERROR_MESSAGE = "Something went wrong. Please try again.";

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { activateUser },
        } = result;

        if (!activateUser || activateUser.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(activateUser);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveVerify2FA({
      input,
    }: Verify2FaMutationVariables): Promise<ResolveVerify2FAResult> {
      const OPERATION_NAME = "Verify2FA";
      const ERROR_MESSAGE =
        ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { verify2FA },
        } = result;

        if (
          !verify2FA ||
          verify2FA.__typename === "Errors" ||
          !verify2FA.success
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(true);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveRiskSession(): Promise<ResolveRiskSessionResult> {
      const OPERATION_NAME = "RiskSession";
      const ERROR_MESSAGE = "Unable to initialize the Meso transfer flow.";

      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { riskSession },
        } = result;

        if (!result || !riskSession || riskSession.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(riskSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveExecuteTransfer({
      input,
    }: ExecuteTransferMutationVariables): Promise<
      Result<
        Extract<
          ExecuteTransferMutation["executeTransfer"],
          { __typename: "Transfer" }
        >,
        string
      >
    > {
      const OPERATION_NAME = "ExecuteTransfer";
      const ERROR_MESSAGE =
        ErrorMessages.transferSheet.EXECUTE_TRANSFER_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { executeTransfer },
        } = result;

        if (!result || !executeTransfer) {
          return err(ERROR_MESSAGE);
        }

        if (executeTransfer.__typename === "Errors") {
          const executedQuoteError = (executeTransfer?.errors || []).find(
            (e) => e.code === "quote_already_executed",
          );

          if (executedQuoteError) {
            return err(
              ErrorMessages.transferSheet.QUOTE_ALREADY_EXECUTED_API_ERROR,
            );
          } else {
            return err(ERROR_MESSAGE);
          }
        }

        return ok(executeTransfer);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveNewSession({
      input,
    }: NewSessionMutationVariables): Promise<
      Result<
        Extract<NewSessionMutation["newSession"], { __typename: "Session" }>,
        string
      >
    > {
      const OPERATION_NAME = "NewSession";
      const ERROR_MESSAGE = ErrorMessages.newSession.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { newSession },
        } = result;

        if (!result || !newSession || newSession.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(newSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveUserActivationStatus({
      id,
    }: ResolveUserActivationStatusQueryVariables): Promise<ResolveUserActivationStatusResult> {
      const OPERATION_NAME = "ResolveUserActivationStatus";
      const ERROR_MESSAGE = ErrorMessages.userActivation.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ id });
        const {
          data: { userActivation },
        } = result;

        if (
          !result ||
          !userActivation ||
          userActivation?.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(userActivation);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveQuote({
      input,
    }: QuoteQueryVariables): Promise<ResolveQuoteResult> {
      const OPERATION_NAME = "Quote";
      const ERROR_MESSAGE = ErrorMessages.quote.GENERIC_API_ERROR;
      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { quote },
        } = result;

        if (!result || !quote) {
          return err({
            message: ERROR_MESSAGE,
            isLimitError: false,
          });
        }

        if (quote?.__typename === "Errors") {
          const limitErr = quote.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );

          return err({
            code: limitErr?.code as QuoteLimitErrorCode | undefined,
            message: limitErr?.enMessage || ERROR_MESSAGE,
            isLimitError: limitErr !== undefined,
          });
        }

        return ok(quote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({
        message: ERROR_MESSAGE,
        isLimitError: false,
      });
    },

    async resolveAnonQuote({
      input,
    }: AnonQuoteQueryVariables): Promise<
      Result<
        Extract<AnonQuoteQuery["anonQuote"], { __typename: "AnonQuote" }>,
        QuoteError
      >
    > {
      const OPERATION_NAME = "AnonQuote";
      const ERROR_MESSAGE = ErrorMessages.quote.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { anonQuote },
        } = result;

        if (!result || !anonQuote) {
          return err({
            message: ERROR_MESSAGE,
            isLimitError: false,
          });
        }

        if (anonQuote?.__typename === "Errors") {
          const limitErr = anonQuote.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );

          return err({
            code: limitErr?.code as QuoteLimitErrorCode | undefined,
            message: limitErr?.enMessage || ERROR_MESSAGE,
            isLimitError: limitErr !== undefined,
          });
        }

        return ok(anonQuote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({
        message: ERROR_MESSAGE,
        isLimitError: false,
      });
    },

    async resolveCreateCashOutQuote({
      input,
    }: CreateCashOutQuoteMutationVariables): Promise<
      Result<AuthorizedCashOutQuote, QuoteError>
    > {
      const OPERATION_NAME = "CreateCashOutQuote";
      const ERROR_MESSAGE = ErrorMessages.cashOutQuote.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createCashOutQuote },
        } = result;

        if (!createCashOutQuote || createCashOutQuote.__typename === "Errors") {
          const limitErr = createCashOutQuote?.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );
          return err({
            isLimitError: !!limitErr,
            message: limitErr?.enMessage || ERROR_MESSAGE,
          });
        }

        return ok(createCashOutQuote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({ isLimitError: false, message: ERROR_MESSAGE });
    },

    async resolveCreateAnonCashOutQuote({
      input,
    }: CreateAnonCashOutQuoteMutationVariables): Promise<
      Result<AnonCashOutQuote, QuoteError>
    > {
      const OPERATION_NAME = "CreateAnonCashOutQuote";
      const ERROR_MESSAGE = ErrorMessages.cashOutQuote.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createAnonCashOutQuote },
        } = result;

        if (
          !createAnonCashOutQuote ||
          createAnonCashOutQuote.__typename === "Errors"
        ) {
          const limitErr = createAnonCashOutQuote?.errors.find((e) =>
            QUOTE_ERROR_LIMIT_CODES.includes(e.code as QuoteLimitErrorCode),
          );
          return err({
            isLimitError: !!limitErr,
            message: limitErr?.enMessage || ERROR_MESSAGE,
          });
        }

        return ok(createAnonCashOutQuote);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err({ isLimitError: false, message: ERROR_MESSAGE });
    },

    async resolveCheckTransfer({
      id,
    }: CheckTransferQueryVariables): Promise<ResolveCheckTransferResult> {
      const OPERATION_NAME = "CheckTransfer";
      const ERROR_MESSAGE =
        ErrorMessages.transferStatus.TRANSFER_STATUS_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ id });
        const {
          data: { transfer },
        } = result;

        if (!result || !transfer || transfer?.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(transfer);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(ERROR_MESSAGE);
      }
    },

    async resolveCreateThreeDomainSecureSession({
      input,
    }: CreateThreeDomainSecureSessionMutationVariables): Promise<
      Result<ThreeDomainSecureSession, string>
    > {
      const OPERATION_NAME = "CreateThreeDomainSecureSession";

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createThreeDomainSecureSession },
        } = result;

        if (
          !result ||
          !createThreeDomainSecureSession ||
          createThreeDomainSecureSession?.__typename === "Errors"
        ) {
          const ERROR_MESSAGE =
            ErrorMessages.threeDomainSecure.GENERIC_CLIENT_ERROR;

          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              input: JSON.stringify(input),
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(createThreeDomainSecureSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(
          ErrorMessages.threeDomainSecure.CREATE_3DS_SECURE_SESSION_API_ERROR,
        );
      }
    },

    async resolveSetThreeDomainSecureSessionMethodCompletion({
      input,
    }: SetThreeDomainSecureSessionMethodCompletionMutationVariables): Promise<
      Result<ThreeDomainSecureSession, string>
    > {
      const OPERATION_NAME = "SetThreeDomainSecureSessionMethodCompletion";
      const REPORTABLE_ERROR_MESSAGE =
        ErrorMessages.threeDomainSecure.SET_3DS_SESSION_METHOD_API_ERROR;
      const CLIENT_ERROR_MESSAGE =
        ErrorMessages.threeDomainSecure.SET_3DS_SESSION_METHOD_CLIENT_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { setThreeDomainSecureSessionMethodCompletion },
        } = result;

        if (
          !result ||
          !setThreeDomainSecureSessionMethodCompletion ||
          setThreeDomainSecureSessionMethodCompletion?.__typename === "Errors"
        ) {
          Sentry.captureMessage(REPORTABLE_ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
              userErrorSeen:
                ErrorMessages.threeDomainSecure
                  .SET_3DS_SESSION_METHOD_CLIENT_ERROR,
            },
          });

          return err(CLIENT_ERROR_MESSAGE);
        }

        return ok(setThreeDomainSecureSessionMethodCompletion);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(CLIENT_ERROR_MESSAGE);
      }
    },
  };
};
