import { createContext } from "react";
import * as SentryImport from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { toast } from "sonner";

type Amplitude = {
  track: typeof amplitude.track;
};

type Sentry = {
  captureMessage: typeof SentryImport.captureMessage;
  captureException: typeof SentryImport.captureException;
  metrics: typeof SentryImport.metrics;
};

export type MesoKitContextValue = {
  /** Reference to an instantiated `Sentry` instance in the embedding application. */
  sentry?: Sentry;
  /** Reference to an instantiated `Amplitude` instance in the embedding application. */
  amplitude?: Amplitude;
  /** Reference to the `sonner.toast` namespace in the embedding application. */
  toast?: typeof toast;
};

export const MesoKitContext = createContext<MesoKitContextValue>({
  sentry: undefined,
  amplitude: undefined,
  toast: undefined,
});
