import { FormField, Routes } from "../types";
import { Button, LabelledInput, Text } from "@tigris/mesokit";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import { Amplitude } from "../utils/telemetry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AnimatePresence, motion } from "framer-motion";
import { spring } from "../utils/animation";
import { toast } from "sonner";
import { ssnSchema } from "../utils/validation";
import { defaultFormField } from "@tigris/common";
import { useRouter } from "../hooks/useRouter";
import { getNextOnboardingStep } from "../utils/getNextOnboardingStep";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import Heading from "./Heading";
type FormState = {
  isValid: boolean;
  fields: { taxpayerId: FormField };
};

const FORM_ID = "TaxpayerIdEntry";
// We add _search to the end to prevent 1password from rendering it's UI
// see: https://1password.community/discussion/comment/606453/#Comment_606453
const INPUT_ID = "taxpayerId_search";
const TOAST_ID = FORM_ID;

const defaultFormState: FormState = {
  isValid: false,
  fields: { taxpayerId: defaultFormField("") },
};

export const TaxpayerIdEntry = () => {
  const { navigate } = useRouter();
  const {
    api: { resolveAddTaxpayerId },
  } = useApi();
  const { user } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<FormState>(defaultFormState);

  const handleFormSubmit = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      if (!formState.isValid) return;

      setIsLoading(true);

      const addTaxpayerIdResult = await resolveAddTaxpayerId({
        input: {
          taxpayerId: formState.fields.taxpayerId.value.replace(/-/g, ""),
        },
      });

      if (addTaxpayerIdResult.isErr()) {
        setIsLoading(false);
        toast.error(addTaxpayerIdResult.error, { id: TOAST_ID });

        return;
      }

      const nextStep = getNextOnboardingStep(
        Routes.TaxpayerIdEntry,
        addTaxpayerIdResult.value,
        user.status,
      );

      if (nextStep.isErr()) {
        toast.error(nextStep.error, { id: TOAST_ID });
        return;
      }

      navigate(nextStep.value.pathname);
    },
    [
      formState.fields.taxpayerId.value,
      formState.isValid,
      navigate,
      resolveAddTaxpayerId,
      user.status,
    ],
  );

  const renderFieldAsValid = useCallback((): boolean => {
    const field = formState.fields.taxpayerId;

    if (!field.isTouched || field.isValid) {
      return true;
    }

    if (field.isTouched && field.isDirty) {
      return field.isValid;
    }

    return true;
  }, [formState]);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      Amplitude.track("Form Input Changed", {
        formId: FORM_ID,
        inputId: INPUT_ID,
      });

      setFormState((previousState) => {
        const previousValue = previousState.fields.taxpayerId.value;
        let newValue = event.target.value.replace(/[^0-9-]+/g, "");

        // https://codesandbox.io/s/ssn-input-g13ot?file=/src/hooks.js:180-217
        // handle backspace at dash positions
        if (
          previousValue.substring(previousValue.length - 1) === "-" &&
          newValue === previousValue.substring(0, previousValue.length - 1)
        ) {
          newValue = previousValue.substring(0, previousValue.length - 2);
        } else {
          // strip out non-numeric characters
          newValue = newValue.replace(/[^0-9]/g, "");

          // insert dashes & limit to 9 characters
          if (newValue.length > 2 && newValue.length < 5) {
            newValue = `${newValue.substring(0, 3)}-${newValue.substring(3)}`;
          } else if (newValue.length >= 5) {
            newValue = `${newValue.substring(0, 3)}-${newValue.substring(
              3,
              5,
            )}-${newValue.substring(5, 9)}`;
          }
        }

        const isDirty =
          previousState.fields.taxpayerId.isTouched ||
          newValue !== previousState.fields.taxpayerId.value;

        let isValid = false;

        const result = ssnSchema.safeParse(newValue);
        isValid = result.success;
        if (!isValid) {
          Amplitude.track("Form Input Invalid", {
            formId: FORM_ID,
            inputId: INPUT_ID,
          });
        }

        return {
          ...previousState,
          isValid,
          fields: {
            taxpayerId: {
              ...previousState.fields.taxpayerId,
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    },
    [setFormState],
  );

  const handleInputFocus = useCallback(() => {
    Amplitude.track("Form Input Focused", {
      formId: FORM_ID,
      inputId: INPUT_ID,
    });
  }, []);

  const handleInputBlur = useCallback(() => {
    setFormState((previousState) => ({
      ...previousState,
      isTouched: true,
      isValid: previousState.fields.taxpayerId.isValid,
      isDirty: previousState.fields.taxpayerId.isDirty,
      fields: {
        taxpayerId: {
          ...previousState.fields.taxpayerId,
          isTouched: true,
        },
      },
    }));
  }, []);

  return (
    <form
      id={FORM_ID}
      name={FORM_ID}
      onSubmit={handleFormSubmit}
      className="onboarding-inner-content"
    >
      <Heading
        title="Social Security Number"
        subtitle="Please enter your full Social Security Number to proceed."
      />

      <LabelledInput
        labelProps={{ text: "Social Security Number" }}
        placeholder="Your SSN"
        value={formState.fields.taxpayerId.value}
        isValid={renderFieldAsValid()}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        maxLength={11}
        disabled={isLoading}
        name={INPUT_ID}
        // autoComplete="off" helps prevent 1password from rendering inline suggestions
        // https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field
        autoComplete="off"
        type="password"
        tabIndex={0}
        data-testid={`${FORM_ID}:${INPUT_ID}`}
      />

      <AnimatePresence>
        <motion.div
          className="mt-auto flex items-center gap-2 rounded-2xl bg-white p-4 text-neutral-800 shadow-lg dark:bg-neutral-700 dark:text-white"
          initial={{ opacity: 0, y: 24 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { ...spring, delay: 0.5 },
          }}
        >
          <div>
            <div className="text-neutral-700 dark:text-white">
              <FontAwesomeIcon
                icon={icon({ name: "hexagon-exclamation" })}
                size="lg"
              />
            </div>
          </div>
          <Text>
            We could not verify your identity using the last-4 digits of your
            SSN.
          </Text>
        </motion.div>
      </AnimatePresence>

      <Button
        key="TaxpayerIdEntry:button"
        disabled={isLoading || !formState.isValid}
        type="submit"
        isLoading={isLoading}
        tabIndex={0}
      >
        Continue
      </Button>
    </form>
  );
};
