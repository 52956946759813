import { createContext } from "react";

export type PostMessageLog = {
  id: string;
  message: string;
  timestamp: Date;
  origin: Location["origin"];
};

export const PostMessageLoggerContext = createContext<{
  logs: PostMessageLog[];
}>({ logs: [] });
