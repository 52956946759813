import { easeOut } from "framer-motion";

export const easeIn = [0.15, 0.1, 0.15, 0.75];
export const easeInOut = [0.65, 0, 0.35, 1];
export const spring = {
  type: "spring",
  damping: 25,
  stiffness: 175,
};

export const motionVariants = {
  fadeIn: {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
    },
    exit: { opacity: 0 },
  },
  fadeInDown: {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { ...spring },
    },
    exit: { opacity: 0, y: 24 },
  },
  slideInFromRight: {
    initial: { opacity: 0, x: 48 },
    animate: {
      opacity: 1,
      x: 0,
      transition: { easeOut, duration: 0.15 },
    },
    exit: { x: -48, opacity: 0, transition: { easeOut, duration: 0.15 } },
  },
  slideInFromLeft: {
    initial: { opacity: 0, x: -48 },
    animate: {
      opacity: 1,
      x: 0,
      transition: { easeOut, duration: 0.15 },
    },
    exit: { x: 48, opacity: 0, transition: { easeOut, duration: 0.15 } },
  },
};
