import * as Factory from "factory.ts";
import {
  UserActivationFragment,
  UserActivationState,
} from "@src/generated/sdk";
import { nanoid } from "nanoid";
import { UserStatus } from "@src/types";

export const userActivationFactory =
  Factory.Sync.makeFactory<UserActivationFragment>({
    __typename: "UserActivation",
    id: nanoid(8),
    state: UserActivationState.CREATED,
    // For now, this will always be at least an empty string.
    failedReason: "",
    userStatus: UserStatus.NEW,
  });
