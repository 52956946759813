import "../main.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { StandaloneError } from "./components/Standalone/StandaloneError";
import { Scope } from "@sentry/react";
import { prepareDOM, prepareMSW } from "./utils/boot";
import { Sentry } from "./utils/telemetry";

const { router, basePath: basename, rootElement } = prepareDOM();

(async () => {
  await prepareMSW();

  if (!router) {
    // If a router cannot be established, render an error view
    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <Sentry.ErrorBoundary
          fallback={<StandaloneError />}
          beforeCapture={(scope: Scope) => scope.setLevel("warning")}
        >
          <StandaloneError />
        </Sentry.ErrorBoundary>
      </React.StrictMode>,
    );
  } else {
    const routes = Sentry.wrappedCreateBrowserRouter(router, { basename });

    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <Sentry.ErrorBoundary
          fallback={<p>An error has occurred</p>}
          beforeCapture={(scope: Scope) => scope.setLevel("warning")}
        >
          <RouterProvider router={routes} />
        </Sentry.ErrorBoundary>
      </React.StrictMode>,
    );
  }
})();
