import ARBITRUM_NETWORK from "../../assets/network-logos/Arbitrum-Badge.svg";
import ETH_NETWORK from "../../assets/network-logos/Ethereum-Badge.svg";
import OPTIMISM_NETWORK from "../../assets/network-logos/Optimism-Badge.svg";
import POLYGON_NETWORK from "../../assets/network-logos/Polygon-Badge.svg";
import SOL_NETWORK from "../../assets/network-logos/Solana-Badge.svg";
import BTC_NETWORK from "../../assets/network-logos/Bitcoin-Badge.svg";
import BASE_NETWORK from "../../assets/network-logos/Base-Badge.svg";
import { FiatInstrument } from "../FiatInstrument";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState } from "react";
import { TransferKind } from "../../generated/schema";
import { Logo } from "../Logo";
import { Network } from "../../types";
import { Text } from "../Text";
import { Transition } from "@headlessui/react";
import { WalletInstrument } from "../WalletInstrument";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const NetworkBadge = ({ network }: { network: Network }) => {
  const networkBadges = {
    [Network.ETHEREUM_MAINNET]: ETH_NETWORK,
    [Network.SOLANA_MAINNET]: SOL_NETWORK,
    [Network.ARBITRUM_MAINNET]: ARBITRUM_NETWORK,
    [Network.OP_MAINNET]: OPTIMISM_NETWORK,
    [Network.POLYGON_MAINNET]: POLYGON_NETWORK,
    [Network.BITCOIN_MAINNET]: BTC_NETWORK,
    [Network.BASE_MAINNET]: BASE_NETWORK,
  };

  return (
    <div
      data-testid={`${network}NetworkBadges`}
      className="h-8 w-8 bg-neutral-600 dark:bg-neutral-200"
      style={{
        WebkitMask: `url(${networkBadges[network]}) 50% 50% / contain no-repeat`,
        mask: `url(${networkBadges[network]}) 50% 50% / contain no-repeat`,
      }}
    />
  );
};

const ProfileDetails = ({
  walletAddress,
  fiatDisplay,
  depositAddress,
  transferKind,
  network,
  canShowPopover = true,
  disableDepositAddressPopover = false,
}: {
  walletAddress: string;
  fiatDisplay?: string;
  depositAddress?: string;
  transferKind: TransferKind;
  network: Network;
  canShowPopover?: boolean;
  disableDepositAddressPopover?: boolean;
}) => {
  const [showPopover, setShowPopover] = useState<
    "wallet" | "fiat" | "depositAddress"
  >();

  const networkName = (Object.entries(Network).find(
    ([_, v]) => v === network,
  ) || [""])[0]
    .split("_")
    .map((name) => name.charAt(0).toUpperCase() + name.substr(1).toLowerCase())
    .join(" ");
  const isCashIn = transferKind === TransferKind.CASH_IN;

  const sourceClassName = twMerge(
    "flex h-7 flex-auto items-center gap-1 px-2 font-bold outline-none ring-0",
    canShowPopover ? "cursor-pointer transition-transform active:scale-95" : "",
  );
  const destinationClassName = canShowPopover
    ? "profile-capsule-inner font-bold group cursor-pointer rounded-full bg-transparent py-1 px-3 h-full hover:bg-neutral-50/30 dark:hover:bg-neutral-50/10 transition-transfer active:scale-95"
    : "profile-capsule-inner font-bold rounded-full bg-transparent py-1 pl-1 pr-3";
  const popoverClassName = twMerge(
    "absolute z-20 mt-9 flex w-[calc(100%-16px)] items-center gap-1 rounded-[16px] border border-transparent p-4 shadow-lg network-popover",
  );

  const fiatDetails = (
    <div
      data-testid="FiatDetails"
      onClick={() =>
        canShowPopover &&
        setShowPopover(showPopover === "fiat" ? undefined : "fiat")
      }
    >
      <FiatInstrument
        className={isCashIn ? sourceClassName : destinationClassName}
        display={fiatDisplay}
      />
    </div>
  );

  const walletDetails = (
    <div
      className={isCashIn ? destinationClassName : sourceClassName}
      data-testid="WalletDetails"
      onClick={() =>
        canShowPopover &&
        setShowPopover(showPopover === "wallet" ? undefined : "wallet")
      }
    >
      <WalletInstrument
        network={network}
        walletAddress={walletAddress}
        className="font-bold group-active:scale-95"
      />
    </div>
  );

  return (
    <div
      data-testid="ProfileDetails"
      className="flex flex-col items-center gap-2"
    >
      <div className="capsule-container">
        <div className="capsule-source px-1">
          {isCashIn ? fiatDetails : walletDetails}
        </div>
        <motion.div
          animate={{
            opacity: [0, 0.5, 0],
            x: [-12, 0, 0],
            transition: {
              delay: 1,
              repeatDelay: 2,
              ease: "easeInOut",
              duration: 1.5,
              repeat: Infinity,
              repeatType: "loop",
            },
          }}
        >
          <FontAwesomeIcon
            icon={icon({ name: "chevron-right", style: "regular" })}
          />
        </motion.div>
        {!isCashIn && (
          <div
            onClick={() =>
              canShowPopover &&
              !disableDepositAddressPopover &&
              setShowPopover(
                showPopover === "depositAddress" ? undefined : "depositAddress",
              )
            }
            data-testid="DepositAddress"
            className={twMerge(
              "flex items-center pl-1 pr-2",
              canShowPopover &&
                !disableDepositAddressPopover &&
                "cursor-pointer transition-transform active:scale-95",
            )}
          >
            <Logo size="xs" showText={false} />
          </div>
        )}
        {!isCashIn && (
          <motion.div
            animate={{
              opacity: [0, 0.5, 0],
              x: [-12, 0, 0],
              transition: {
                delay: 1,
                repeatDelay: 2,
                ease: "easeInOut",
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              },
            }}
          >
            <FontAwesomeIcon
              icon={icon({ name: "chevron-right", style: "regular" })}
            />
          </motion.div>
        )}
        <div className="capsule-destination">
          {isCashIn ? walletDetails : fiatDetails}
        </div>
      </div>
      {canShowPopover && (
        <Transition
          as={Fragment}
          show={showPopover === "wallet"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div data-testid="WalletPopover" className={popoverClassName}>
            <NetworkBadge network={network} />
            <div className="flex flex-col">
              <Text className="text-[10px] font-bold">
                {isCashIn ? "Destination" : "Source"}
              </Text>
              <Text className="text-sm font-semibold">{networkName}</Text>
              <Text className="whitespace-normal break-all font-mono text-[10px] font-bold">
                {walletAddress}
              </Text>
            </div>
          </div>
        </Transition>
      )}
      {canShowPopover && (
        <Transition
          as={Fragment}
          show={showPopover === "fiat"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div data-testid="FiatPopover" className={popoverClassName}>
            <FontAwesomeIcon
              size="lg"
              icon={icon({ name: "credit-card", style: "regular" })}
              className="text-neutral-800 dark:text-white"
            />
            <div className="flex flex-col">
              <Text className="text-[10px] font-bold">
                {isCashIn ? "Source" : "Destination"}
              </Text>
              {fiatDisplay && (
                <Text className="text-sm font-semibold">Debit Card</Text>
              )}
              <Text
                className={twMerge(
                  "whitespace-normal break-words text-[10px] font-bold",
                  fiatDisplay ? "font-mono" : "",
                )}
              >
                {fiatDisplay
                  ? `Ending in ${fiatDisplay}`
                  : "Log in to see your card"}
              </Text>
            </div>
          </div>
        </Transition>
      )}
      {canShowPopover && !disableDepositAddressPopover && (
        <Transition
          as={Fragment}
          show={showPopover === "depositAddress"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div data-testid="DepositAddressPopover" className={popoverClassName}>
            <Logo size="sm" showText={false} />
            <div className="flex flex-col">
              <Text className="text-sm font-semibold">
                Meso Deposit Address
              </Text>
              {fiatDisplay ? (
                <div className="flex items-center gap-1">
                  {!depositAddress && (
                    <FontAwesomeIcon
                      icon={icon({ name: "loader" })}
                      size="xs"
                      className="fa-spin"
                    />
                  )}
                  <Text
                    className={twMerge(
                      "whitespace-normal break-words text-[10px]",
                      depositAddress && "font-mono font-bold",
                    )}
                  >
                    {depositAddress ??
                      "Creating deposit address. This may take a minute..."}
                  </Text>
                </div>
              ) : (
                <Text className="whitespace-normal break-words text-[10px] font-bold">
                  {"Log in to see your deposit address"}
                </Text>
              )}
            </div>
          </div>
        </Transition>
      )}
    </div>
  );
};

export { ProfileDetails };
