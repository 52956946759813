import { CAIPAsset, CAIPNetwork, Network, NetworkNamespace } from "../types";
import type { ExchangeRate } from "./assets";

export function networkFromCAIPNetwork(network: string): Network {
  switch (network) {
    case CAIPNetwork.ETHEREUM_MAINNET:
    case CAIPNetwork.ETHEREUM_SEPOLIA:
      return Network.ETHEREUM_MAINNET;
    case CAIPNetwork.SOLANA_MAINNET:
    case CAIPNetwork.SOLANA_DEVNET:
      return Network.SOLANA_MAINNET;
    case CAIPNetwork.ARBITRUM_ONE:
    case CAIPNetwork.ARBITRUM_SEPOLIA:
      return Network.ARBITRUM_MAINNET;
    case CAIPNetwork.OP_MAINNET:
    case CAIPNetwork.OP_SEPOLIA:
      return Network.OP_MAINNET;
    case CAIPNetwork.POLYGON_MAINNET:
    case CAIPNetwork.POLYGON_AMOY:
      return Network.POLYGON_MAINNET;
    case CAIPNetwork.BITCOIN_TESTNET:
    case CAIPNetwork.BITCOIN_MAINNET:
      return Network.BITCOIN_MAINNET;
    case CAIPNetwork.BASE_SEPOLIA:
    case CAIPNetwork.BASE_MAINNET:
      return Network.BASE_MAINNET;
    default:
      throw new Error(`Cannot parse network from CAIPNetwork: ${network}`);
  }
}

function networkFromCAIPAsset(asset: string): Network {
  switch (asset) {
    case CAIPAsset.ETHEREUM_MAINNET_USDC:
    case CAIPAsset.ETHEREUM_MAINNET_ETH:
    case CAIPAsset.ETHEREUM_SEPOLIA_USDC:
    case CAIPAsset.ETHEREUM_SEPOLIA_ETH:
      return Network.ETHEREUM_MAINNET;
    case CAIPAsset.SOLANA_MAINNET_USDC:
    case CAIPAsset.SOLANA_MAINNET_SOL:
    case CAIPAsset.SOLANA_DEVNET_USDC:
    case CAIPAsset.SOLANA_DEVNET_SOL:
      return Network.SOLANA_MAINNET;
    case CAIPAsset.ARBITRUM_ONE_USDC:
    case CAIPAsset.ARBITRUM_ONE_ETH:
    case CAIPAsset.ARBITRUM_SEPOLIA_USDC:
    case CAIPAsset.ARBITRUM_SEPOLIA_ETH:
      return Network.ARBITRUM_MAINNET;
    case CAIPAsset.OP_MAINNET_USDC:
    case CAIPAsset.OP_MAINNET_ETH:
    case CAIPAsset.OP_SEPOLIA_USDC:
    case CAIPAsset.OP_SEPOLIA_ETH:
      return Network.OP_MAINNET;
    case CAIPAsset.POLYGON_MAINNET_USDC:
    case CAIPAsset.POLYGON_MAINNET_MATIC:
    case CAIPAsset.POLYGON_AMOY_USDC:
    case CAIPAsset.POLYGON_AMOY_MATIC:
      return Network.POLYGON_MAINNET;
    case CAIPAsset.BITCOIN_MAINNET_BTC:
    case CAIPAsset.BITCOIN_TESTNET_BTC:
      return Network.BITCOIN_MAINNET;
    case CAIPAsset.BASE_MAINNET_USDC:
    case CAIPAsset.BASE_SEPOLIA_USDC:
    case CAIPAsset.BASE_MAINNET_ETH:
    case CAIPAsset.BASE_SEPOLIA_ETH:
      return Network.BASE_MAINNET;
    default:
      throw new Error(`Cannot parse network from CAIPAsset ${asset}`);
  }
}

export function networkFromExchangeRate(exchangeRate: ExchangeRate): Network {
  // Attempt to parse network from base or quote assets in order
  for (const asset of [exchangeRate.base, exchangeRate.quote]) {
    try {
      return networkFromCAIPAsset(asset);
    } catch {} // Ignore failure and try the next asset
  }

  // If parsing both base and quote CAIPAssets fail, throw an error
  throw new Error(
    `Cannot parse network from exchange rate ${JSON.stringify({
      base: exchangeRate.base,
      quote: exchangeRate.quote,
    })}`,
  );
}

export const isEVMBlockchain = (network: Network | NetworkNamespace): boolean =>
  network === NetworkNamespace.EIP55 ||
  network === Network.ETHEREUM_MAINNET ||
  network === Network.ARBITRUM_MAINNET ||
  network === Network.OP_MAINNET ||
  network === Network.POLYGON_MAINNET ||
  network === Network.BASE_MAINNET;

export const isSolanaBlockchain = (
  network: Network | NetworkNamespace,
): boolean =>
  network === NetworkNamespace.SOLANA || network === Network.SOLANA_MAINNET;

const truncateETHAddress = (
  walletAddress: string,
  truncateAmount?: number,
): string => {
  const firstChars = truncateAmount ?? 6;
  const lastChars = truncateAmount ?? 4;

  return `${walletAddress.slice(0, firstChars)}...${walletAddress.slice(
    -lastChars,
  )}`;
};

const truncateSOLAddress = (
  walletAddress: string,
  truncateAmount?: number,
): string => {
  const firstChars = truncateAmount ?? 4;
  const lastChars = truncateAmount ?? 6;

  return `${walletAddress.slice(0, firstChars)}...${walletAddress.slice(
    -lastChars,
  )}`;
};

export const truncateAddress = (
  walletAddress: string,
  network: Network | NetworkNamespace,
  truncateAmount?: number,
) => {
  return isEVMBlockchain(network)
    ? truncateETHAddress(walletAddress, truncateAmount)
    : truncateSOLAddress(walletAddress, truncateAmount);
};
