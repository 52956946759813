import { cloneElement, useEffect, useMemo } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { AnimatePresence, animate, useAnimate, motion } from "framer-motion";
import {
  Routes,
  LANDING_AND_TRANSFER_SHEET_HEIGHT,
  LANDING_AND_TRANSFER_SHEET_HEIGHT_COLLAPSED,
} from "@utils/constants";
import { Toast } from "./Toast";
import { useMediaQuery, usePrevious } from "@uidotdev/usehooks";
import { spring } from "@src/utils/animation";
import { IntegrationMode, Position } from "@src/types";
import { isTransferRoute } from "@src/utils/routeHelpers";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { HoldingScreen } from "./HoldingScreen";
import { useApp } from "@src/hooks/useApp";
import { AnnouncementBanner } from "./AnnouncementBanner";
import { twMerge } from "tailwind-merge";
import { DevOnlyFrameControl } from "@src/dev/devOnlyFrameControl";

/**
 * A valid CSS value with `px` units.
 */
type PixelString = `${number}px`;

const cardContainerHeightsByRoute = new Map<string, number>([
  [Routes.LandingSheet, LANDING_AND_TRANSFER_SHEET_HEIGHT],
  [Routes.LandingSheet2Fa, LANDING_AND_TRANSFER_SHEET_HEIGHT],
  [Routes.LandingSheetLoginLanding, LANDING_AND_TRANSFER_SHEET_HEIGHT],
  [Routes.LandingSheetLoginEntry, LANDING_AND_TRANSFER_SHEET_HEIGHT],
  [Routes.TransferSheet, LANDING_AND_TRANSFER_SHEET_HEIGHT],
  [Routes.TransferSheet2Fa, LANDING_AND_TRANSFER_SHEET_HEIGHT],
  [Routes.TransferSheetDepositAddress, LANDING_AND_TRANSFER_SHEET_HEIGHT],
  [Routes.TransferSheetStatus, LANDING_AND_TRANSFER_SHEET_HEIGHT_COLLAPSED],
  [Routes.TransferUnavailable, LANDING_AND_TRANSFER_SHEET_HEIGHT],
]);

const getCardContainerHeight = (pathname: string): number => {
  const height = cardContainerHeightsByRoute.get(pathname);

  if (height) {
    return height;
  }

  throw new Error("Unable to find height based on pathname.");
};
export const MainLayout = () => {
  const { pathname } = useLocation();
  const {
    configuration: { layout },
    mode,
    announcementBanner,
  } = useApp();
  const { offset, position } = layout!;
  const outlet = useOutlet();
  const [cardContainer, animateCardContainer] = useAnimate();
  const previousPathname = usePrevious(pathname);
  const isSmallScreen = useMediaQuery("only screen and (max-width : 450px)");
  const { onboardingInProgress } = useOnboarding();

  const { positionClassName, announcementBannerClassName, offsetPadding } =
    useMemo(() => {
      // Don't use string concatenation to create class names
      // https://v2.tailwindcss.com/docs/just-in-time-mode#arbitrary-value-support
      var positionClassName;
      var announcementBannerClassName;
      var offsetPadding:
        | {
            paddingTop?: PixelString;
            paddingRight?: PixelString;
            paddingBottom?: PixelString;
            paddingLeft?: PixelString;
          }
        | { padding: "0px" };

      const horizontal = `${Number(offset.horizontal) + 16}px` as PixelString;
      const vertical = `${Number(offset.vertical) + 16}px` as PixelString;

      switch (position) {
        case Position.TOP_RIGHT: {
          positionClassName = "card-container-pin-top-right";
          announcementBannerClassName = "announcement-banner-pin-top-right";
          offsetPadding = {
            paddingRight: horizontal,
            paddingTop: vertical,
          };
          break;
        }
        case Position.TOP_LEFT: {
          positionClassName = "card-container-pin-top-left";
          announcementBannerClassName = "announcement-banner-pin-top-left";
          offsetPadding = {
            paddingLeft: horizontal,
            paddingTop: vertical,
          };
          break;
        }
        case Position.BOTTOM_LEFT: {
          positionClassName = "card-container-pin-bottom-left";
          announcementBannerClassName = "announcement-banner-pin-bottom-left";
          offsetPadding = {
            paddingLeft: horizontal,
            paddingBottom: vertical,
          };
          break;
        }
        case Position.BOTTOM_RIGHT: {
          positionClassName = "card-container-pin-bottom-right";
          announcementBannerClassName = "announcement-banner-pin-bottom-right";
          offsetPadding = {
            paddingRight: horizontal,
            paddingBottom: vertical,
          };
          break;
        }
        case Position.CENTER: {
          positionClassName = "card-container-pin-center";
          announcementBannerClassName = "announcement-banner-pin-center";
          offsetPadding = {
            paddingLeft: horizontal,
            paddingTop: vertical,
            paddingRight: horizontal,
            paddingBottom: vertical,
          };
          break;
        }
      }

      return { positionClassName, offsetPadding, announcementBannerClassName };
    }, [offset.horizontal, offset.vertical, position]);

  useEffect(() => {
    (async () => {
      if (!previousPathname) {
        await animate(cardContainer.current, {
          opacity: 1,
          height: getCardContainerHeight(pathname),
        });

        return;
      }

      // Transitioning from /transfer to /transfer/status
      // Transitioning from /transfer to /transfer/2fa
      // Transitioning from /transfer/status to /transfer
      if (isTransferRoute(pathname)) {
        await animateCardContainer(cardContainer.current, {
          height: getCardContainerHeight(pathname),
          transition: spring,
        });

        return;
      }
    })();
  }, [
    isSmallScreen,
    pathname,
    previousPathname,
    cardContainer,
    animateCardContainer,
  ]);

  // Determine the content to render as an outlet within this layout.
  const outletContent = useMemo(() => {
    // For WebViews (which always use embedded integrations), we want onboarding to be rendered as a react component
    if (onboardingInProgress && mode === IntegrationMode.WEBVIEW) {
      return <>{outlet}</>;
    }

    // For all other "embedded" integrations, when onboarding breaks out into a new window, render a holding screen
    if (onboardingInProgress) {
      return <HoldingScreen />;
    }

    // For all non-onboarding routes, render the outlet in an animation context
    return (
      <AnimatePresence mode="wait">
        {outlet && cloneElement(outlet, { key: "landingAndTransfer" })}
      </AnimatePresence>
    );
  }, [mode, onboardingInProgress, outlet]);

  const cardContainerClassName = twMerge(
    // When the announcement banner is riding above the card container at lower breakpoints, it will handle the `mt-auto` positioning so we can omit that here.
    !announcementBanner && "mt-auto",
    "card-container sm:mt-0 overflow-hidden",
    positionClassName,
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={
        announcementBanner ? "root-layout-with-announcement" : "root-layout"
      }
      style={offsetPadding}
    >
      <AnnouncementBanner className={announcementBannerClassName} />
      <motion.div
        initial={{ scale: 1.2, y: -24 }}
        animate={{
          scale: 1,
          y: 0,
          transition: {
            ...spring,
            delay: 0.1,
          },
        }}
        exit={{ scale: 1.2, y: -24, opacity: 0 }}
        ref={cardContainer}
        className={cardContainerClassName}
        layout
      >
        <Toast key={pathname} />
        {outletContent}
      </motion.div>
      <DevOnlyFrameControl />
    </motion.div>
  );
};
