export enum ThreeDSEventKind {
  THREE_DS_READY = "THREE_DS_READY",
  THREE_DS_PAYLOAD = "THREE_DS_PAYLOAD",
  THREE_DS_RESPONSE = "THREE_DS_RESPONSE",
}

export type ThreeDSResponse = {
  threeDSServerTransID: string;
  transStatus?: string;
};

export type ThreeDSPayload = {
  url: string;
  fieldName: string;
  fieldValue: string;
};
