import {
  Configuration,
  IntegrationMode,
  Message,
  MessageKind,
} from "@src/types";
import { Result, err, ok } from "neverthrow";
import { parseEmbeddedConfiguration } from "./parseEmbeddedConfiguration";
import { parseStandaloneConfiguration } from "./parseStandaloneConfiguration";

export const parseConfiguration = (
  queryParams: URLSearchParams,
  mode: IntegrationMode,
): Result<Configuration, Message> => {
  const useTestnets = import.meta.env.VITE_TIGRIS_USE_TESTNETS === "true";

  let parsedConfig: Result<Configuration, Message> | undefined = undefined;
  if (mode === IntegrationMode.STANDALONE) {
    parsedConfig = parseStandaloneConfiguration(queryParams, mode, useTestnets);
  } else {
    parsedConfig = parseEmbeddedConfiguration(queryParams, mode, useTestnets);
  }

  if (!parsedConfig || parsedConfig.isErr()) {
    const error = parsedConfig?.error ?? {
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: "Unable to parse transfer configuration" },
    };

    return err(error);
  }
  return ok(parsedConfig.value);
};
