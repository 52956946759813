import { Logo } from "../Logo";
import "./styles.css";

export const LogoLoader = () => {
  return (
    <div className="relative flex h-24 w-24 items-center justify-center">
      <Logo className="gap-0" size="lg" showText={false} />
      <div className="loader" />
    </div>
  );
};
