import { brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentMethod } from "frames-react";

export const PaymentCardIcon = ({ brand }: { brand?: PaymentMethod }) => {
  let cardIcon = icon({ name: "credit-card" });

  switch (brand) {
    case "Visa":
      cardIcon = brands("cc-visa");
      break;
    case "Mastercard":
      cardIcon = brands("cc-mastercard");
      break;
    case "American Express":
      cardIcon = brands("cc-amex");
      break;
    case "Diners Club":
      cardIcon = brands("cc-diners-club");
      break;
    case "Discover":
      cardIcon = brands("cc-discover");
      break;
  }

  return <FontAwesomeIcon icon={cardIcon} />;
};
