import { CardNumber, Cvv, ExpiryDate, PaymentMethod } from "frames-react";
import { PaymentCardIcon } from "./PaymentCardIcon";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const loadingAnimationVariants: Variants = {
  initial: { opacity: 1 },
  exit: { opacity: 0, transition: { duration: 0.2 } },
};

/**
 * Wrapper for CKO frame elements.
 */
export const PaymentCardInputFrame = ({
  isFocused,
  disabled,
  formIsReady,
  cardBrand,
}: {
  isFocused: boolean;
  disabled: boolean;
  formIsReady: boolean;
  cardBrand?: PaymentMethod;
}) => {
  const containerClassName = twMerge(
    "relative w-full rounded-2xl border transition duration-150 dark:border-transparent dark:bg-neutral-700 dark:text-white",
    isFocused &&
      "outline-none ring-2 dark:ring-primary-light ring-offset-1 dark:ring-offset-neutral-800",
  );
  const inputClassName = twMerge(
    "flex h-12 w-full flex-row items-center px-3 rounded-2xl",
    disabled && "input-disabled",
  );

  return (
    <div className={containerClassName}>
      <AnimatePresence>
        {!formIsReady && (
          <>
            <motion.div
              key="loadingBg"
              data-testid="PaymentCardInputFrame:loadingBg"
              className="absolute left-0 top-0 h-full w-full rounded-2xl bg-neutral-100 dark:bg-neutral-700"
              variants={loadingAnimationVariants}
              initial="initial"
              exit="exit"
            />
          </>
        )}
      </AnimatePresence>

      <div className={inputClassName}>
        <div className="cursor-default pr-2 text-neutral-400">
          <PaymentCardIcon brand={cardBrand} />
        </div>
        <CardNumber className="h-12 flex-grow" tabIndex={1} />
        <div className="flex flex-row items-center justify-between">
          <ExpiryDate className="h-12 w-14" tabIndex={2} />
          <Cvv className="h-12 w-9" tabIndex={3} />
        </div>
      </div>
    </div>
  );
};
