import {
  Button,
  DEFAULT_THEME,
  Logo,
  Text,
  ErrorMessages,
  Hero,
} from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { ThemePickerButton } from "./ThemeButton";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { toast } from "sonner";
import AnimationContainer from "./AnimationContainer";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { useRouter } from "../hooks/useRouter";

const TOAST_ID = "Summary";

const FeeSummary = () => {
  return (
    <div className="relative flex flex-col gap-2 text-xs dark:text-white">
      <div className="flex items-center justify-between">
        <Text className="text-xs font-semibold">Transfer Fee</Text>
        <Text className="flex items-center gap-1 text-xs">
          <span className="font-bold">1.5% Fee</span>
        </Text>
      </div>
      <div className="flex justify-between">
        <Text className="text-xs font-semibold">Transfer Speed</Text>
        <Text className="flex items-center gap-1 text-xs font-bold">
          <FontAwesomeIcon
            icon={icon({ name: "bolt" })}
            size="sm"
            className="opacity-60"
          />
          Instant
        </Text>
      </div>
    </div>
  );
};

const Summary = () => {
  const {
    api: { resolveSetTheme },
    session,
  } = useApi();
  const { user, updateUser, returnToTransfer } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const { disableNavigation } = useRouter();

  useEffect(() => disableNavigation(), [disableNavigation]);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!session) {
        toast.error(ErrorMessages.summary.GENERIC_API_ERROR);
        return;
      }

      setIsLoading(true);

      if (user.theme) {
        const setThemeResult = await resolveSetTheme({ input: user.theme });

        if (setThemeResult.isErr()) {
          setTimeout(() => {
            toast.error(setThemeResult.error, { id: TOAST_ID });
          }, 500);
          setIsLoading(false);
          return;
        }

        updateUser({ theme: user.theme });
      }

      // End the onboarding flow
      returnToTransfer("onboardingComplete");
    },
    [resolveSetTheme, returnToTransfer, session, updateUser, user.theme],
  );

  return (
    <AnimationContainer>
      <form
        id="Summary"
        name="Summary"
        onSubmit={onSubmit}
        className="onboarding-inner-content"
      >
        <div className="-mx-6 -mt-6">
          <Hero theme={user.theme} style={{ padding: 0 }}>
            <Logo size="lg" showText={false} />
          </Hero>
        </div>
        <Text className="mt-4 rounded-full bg-neutral-100/10 px-2 py-1 text-center text-sm font-medium text-neutral-600 dark:text-neutral-200">
          <FontAwesomeIcon icon={icon({ name: "circle-check" })} size="sm" />{" "}
          Your card is connected and ready to use!
        </Text>
        <FeeSummary />
        <ThemePickerButton />

        <div className="onboarding-footer">
          <Button
            key="Summary:button"
            disabled={!session?.riskSession?.sessionKey}
            type="submit"
            isLoading={isLoading}
            themeOverride={
              user.theme === DEFAULT_THEME ? undefined : "transfer-button"
            }
          >
            Save & Continue
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};

export { Summary };
