import { NewSessionMutation } from "@src/generated/sdk";
import { Session } from "@src/types";
import { MESO_MAX_AMOUNT, MESO_MIN_AMOUNT } from "@src/utils/constants";
import * as Factory from "factory.ts";

type SessionResponse = Extract<
  NewSessionMutation["newSession"],
  { __typename: "Session" }
>;

export const riskSessionFactory = Factory.Sync.makeFactory<
  Session["riskSession"]
>({
  userId: "mock-data-user-id",
  sessionKey: "session-key",
  clientId: "client-id",
  environment: "sandbox",
});

export const sessionFactory = Factory.Sync.makeFactory<Session>({
  id: "session-id",
  token: "super-secret-token",
  riskSession: riskSessionFactory.build(),
  mesoLimits: { min: `${MESO_MIN_AMOUNT}`, max: `${MESO_MAX_AMOUNT}` },
  isReturningUser: false,
  passkeysEnabled: false,
});

export const sessionResponseFactory = Factory.Sync.makeFactory<SessionResponse>(
  {
    __typename: "Session",
    id: "session-id",
    token: "super-secret-token",
    riskSession: {
      __typename: "RiskSession",
      ...riskSessionFactory.build(),
    },
    transferMin: { amount: `${MESO_MIN_AMOUNT}`, currency: "USD" },
    transferMax: { amount: `${MESO_MAX_AMOUNT}`, currency: "USD" },
    isReturningUser: false,
    passkeysEnabled: false,
  },
);
