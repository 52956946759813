import { AnimatePresence, motion } from "framer-motion";
import { CheckTransferQuery, TransferStatus } from "@src/generated/sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { spring } from "@src/utils/animation";

const variants = {
  initial: { opacity: 0, scale: 0.85 },
  animate: {
    opacity: 1,
    scale: [0.85, 5, 1],
    transition: spring,
  },
  exit: {
    opacity: 0,
    scale: 0.85,
    transition: spring,
  },
};

export const TransferStatusIndicator = ({
  status,
  className = "",
  bloom = true,
}: {
  status?: Extract<
    CheckTransferQuery["transfer"],
    { __typename: "Transfer" }
  >["status"];
  bloom?: boolean;
  className?: string;
}) => {
  return (
    <AnimatePresence mode="wait">
      <div className={className} data-testid="TransferStatusIndicator">
        {status === TransferStatus.APPROVED ||
        status === TransferStatus.COMPLETE ? (
          <motion.div
            key="processing-complete"
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <div className="z-10">
              <FontAwesomeIcon
                icon={icon({ name: "check-circle" })}
                className="text-primary mr-1 h-4 w-4"
              />
              <span className="font-bold text-neutral-700 dark:text-white">
                {status === TransferStatus.APPROVED ? "Approved" : "Complete"}
              </span>
            </div>
            {bloom && (
              <motion.div
                key="processing-bloom"
                className="pointer-events-none absolute -bottom-8 -left-[calc(3rem-50%)] z-0 h-24 w-24 rounded-full bg-gradient-to-r from-indigo-200 from-10% via-violet-600 via-30% to-emerald-700 to-90% bg-blend-multiply"
                data-testid="processing-bloom"
                style={{
                  boxShadow:
                    "inset 0px 4px 12px #84FFB7, inset 0px 0px 12px 4px #5690FF",
                }}
                initial={{ opacity: 0, scale: 0 }}
                animate={{
                  opacity: [0.5, 0],
                  scale: 8,
                  transition: { type: "spring", duration: 1.5 },
                }}
              />
            )}
          </motion.div>
        ) : (
          <motion.div
            key="processing-in-progress"
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="font-bold text-neutral-700 dark:text-white"
          >
            <span className="opacity-50">
              <FontAwesomeIcon
                icon={icon({ name: "loader" })}
                size="lg"
                className="fa-spin mr-2 text-neutral-700 dark:text-white"
              />
              Processing...
            </span>
          </motion.div>
        )}
      </div>
    </AnimatePresence>
  );
};
