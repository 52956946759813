import {
  CardHero,
  LineItems,
  LoadingInterstitial,
  EnvironmentBanner,
  ErrorMessages,
} from "@tigris/mesokit";
import { CashOutQuote, TransferKind } from "../../generated/sdk";
import { useQuote, UseQuoteConfiguration } from "@src/hooks/useQuote";
import { useContext, useMemo, cloneElement, useCallback } from "react";
import { AppContext } from "@src/contexts/AppContext";
import { CashInQuote } from "@src/types";
import { MESO_MAX_AMOUNT, MESO_MIN_AMOUNT, Routes } from "@src/utils/constants";
import { useLocation, useOutlet } from "react-router-dom";
import { OutletQuoteContext } from "../LandingAndTransferContainer";
import { Toast } from "../Toast";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { HoldingScreen } from "../HoldingScreen";
import { AnnouncementBanner } from "../AnnouncementBanner";
import { QuoteLimitErrorCode } from "@src/api";
import { toast } from "sonner";

export const InlineLayout = () => {
  const {
    user: { theme, fiatInstruments },
    configuration: { walletAddress, network, destinationAsset, transferKind },
    appReady,
    setQuoteLimitReached,
    userLimits,
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const isCashIn = transferKind === TransferKind.CASH_IN;
  const { onboardingInProgress } = useOnboarding();
  const handleQuoteLimitError = useCallback<
    Required<UseQuoteConfiguration>["onLimitError"]
  >(
    (code?: QuoteLimitErrorCode) => {
      switch (code) {
        case "below_min_xfer":
          toast.error(
            ErrorMessages.quote.minLimitError(
              userLimits?.mesoMinimum ?? MESO_MIN_AMOUNT,
            ),
            { id: "quoteLimitError" },
          );
          break;
        case "above_max_xfer":
        case "above_monthly_xfer":
          toast.error(
            ErrorMessages.quote.maxLimitError(
              userLimits?.monthlyAmountAvailable ?? MESO_MAX_AMOUNT,
            ),
            { id: "quoteLimitError" },
          );
      }
      setQuoteLimitReached();
    },
    [
      setQuoteLimitReached,
      userLimits?.mesoMinimum,
      userLimits?.monthlyAmountAvailable,
    ],
  );
  const quoteHook = useQuote({ onLimitError: handleQuoteLimitError });
  const { quote } = quoteHook;
  const outlet = useOutlet({
    useQuote: quoteHook,
  } satisfies OutletQuoteContext);

  const maskedNumber = useMemo(() => {
    const fiatInstrument =
      (fiatInstruments &&
        fiatInstruments.collection.length > 0 &&
        fiatInstruments.collection[0]) ||
      undefined;

    return fiatInstrument?.maskedNumber;
  }, [fiatInstruments]);

  if (!appReady) {
    return <LoadingInterstitial mode="waiting" key="LoadingInterstitial" />;
  }

  return (
    <div className="flex h-full w-full flex-col justify-between transition-opacity">
      {pathname === Routes.TransferUnavailable ||
      pathname === Routes.ActivateUser ? (
        <>{outlet}</>
      ) : (
        <>
          <AnnouncementBanner />
          <Toast key={pathname} />
          <div className="flex flex-col gap-2">
            <CardHero
              theme={theme}
              asset={destinationAsset}
              amount={quote?.destination?.amount}
              network={network}
              walletAddress={walletAddress}
              fiatDisplay={maskedNumber}
              depositAddress={(quote as CashOutQuote)?.depositAddress}
              transferKind={transferKind}
              canShowPopover={pathname !== Routes.TransferSheetStatus}
              limits={
                userLimits && userLimits.approachingLimit
                  ? userLimits
                  : undefined
              }
            />

            <LineItems
              key="LandingSheetLineItems"
              exchangeRate={quote?.exchangeRate}
              mesoFeeAmount={quote?.mesoFee.amount}
              mesoFeeWaived={isCashIn && (quote as CashInQuote)?.mesoFeeWaived}
              networkFeeAmount={
                isCashIn
                  ? (quote as CashInQuote)?.networkFee?.amount
                  : undefined
              }
              networkFeeWaived={
                isCashIn && (quote as CashInQuote)?.networkFeeWaived
              }
              subtotalAmount={quote?.sourceSubtotal.amount}
              totalAmount={
                isCashIn ? quote?.sourceTotal.amount : quote?.destination.amount
              }
              transferKind={transferKind}
            />
          </div>
          <div>{outlet && cloneElement(outlet, { key: pathname })}</div>

          {onboardingInProgress && <HoldingScreen />}

          <div className="absolute flex w-full justify-center">
            <EnvironmentBanner />
          </div>
        </>
      )}
    </div>
  );
};
