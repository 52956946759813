import { z } from "zod";
import { AddResidentialAddressInput } from "../generated/schema";
import { FramesBillingAddress } from "frames-react";

export const emailSchema = z.string().email("A valid email is required.");

// https://stackoverflow.com/a/52619927
const poBoxRegex =
  /^\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+/i;

export const MIN_PASSWORD_LENGTH = 12;
export const MAX_PASSWORD_LENGTH = 64;
export const passwordSchema = z
  .string()
  .min(MIN_PASSWORD_LENGTH)
  .max(MAX_PASSWORD_LENGTH);

export const PROHIBITED_US_AND_TERRITORY_CODES = ["LA", "NY", "la", "ny"];

export const ALLOWED_US_AND_TERRITORY_CODES = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
  "ak",
  "al",
  "ar",
  "az",
  "ca",
  "co",
  "ct",
  "dc",
  "de",
  "fl",
  "ga",
  "hi",
  "ia",
  "id",
  "il",
  "in",
  "ks",
  "ky",
  "ma",
  "md",
  "me",
  "mi",
  "mn",
  "mo",
  "ms",
  "mt",
  "nc",
  "nd",
  "ne",
  "nh",
  "nj",
  "nm",
  "nv",
  "oh",
  "ok",
  "or",
  "pa",
  "ri",
  "sc",
  "sd",
  "tn",
  "tx",
  "ut",
  "va",
  "vt",
  "wa",
  "wi",
  "wv",
  "wy",
] as const;

export const ALL_US_AND_TERRITORY_CODES_UPPERCASE = [
  ...new Set(
    [...ALLOWED_US_AND_TERRITORY_CODES, ...PROHIBITED_US_AND_TERRITORY_CODES]
      .map((code) => code.toUpperCase())
      .sort(),
  ),
];

export const rawAddressStringSchema: z.ZodSchema<string> = z
  .string()
  .refine((value) => !poBoxRegex.test(value), { path: ["poBox"] });
export const addressLine1Schema: z.ZodSchema<string> = z
  .string()
  .min(4)
  .refine((value) => !poBoxRegex.test(value), { path: ["poBox"] });
export const addressLine2Schema = z
  .string()
  .refine((value) => !poBoxRegex.test(value), { path: ["poBox"] })
  .optional();
export const citySchema: z.ZodSchema<string> = z.string().min(2);
export const stateNameSchema: z.ZodSchema<string> = z.string().min(2);
// Regex via: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
export const zipSchema: z.ZodSchema<string> = z
  .string()
  .regex(/^[0-9]{5}(?:-[0-9]{4})?$/);

export const streetAddressSchema: z.ZodSchema<AddResidentialAddressInput> =
  z.object({
    address1: addressLine1Schema,
    address2: addressLine2Schema,
    address3: z.string().optional(),
    address4: z.string().optional(),
    city: citySchema,
    countryCode: z.literal("US"),
    // Regex via: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
    postalCode: zipSchema,
    region: z.enum(ALLOWED_US_AND_TERRITORY_CODES),
  });

export const billingAddressSchema: z.ZodSchema<FramesBillingAddress> = z.object(
  {
    addressLine1: addressLine1Schema,
    addressLine2: addressLine2Schema,
    city: z.string().min(2),
    country: z.literal("US"),
    zip: zipSchema,
    state: z.enum(ALLOWED_US_AND_TERRITORY_CODES),
  },
);

export const rawAddressContainsPOBox = (rawAddress: string): boolean => {
  const validationResult = rawAddressStringSchema.safeParse(rawAddress);

  if (!validationResult.success) {
    return isPOBoxError(validationResult.error);
  }

  return false;
};

export const isPOBoxError = (error: z.ZodError) => {
  return error.issues.some((i) => i.path?.includes("poBox"));
};

export const isProhibitedRegionError = (error: z.ZodError) => {
  return error.issues.some(
    (i) => i.path?.includes("region") || i.path?.includes("state"),
  );
};

export const cardholderNameSchema = z
  .string()
  .min(1)
  .regex(/^[^\d]*$/);
