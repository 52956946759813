/** Boot up the transfer app! */
import { routers } from "@src/routers";
import {
  cleanPath,
  isRewardsRoute,
  isEmbeddedRoute,
  isInlineRoute,
  isModalExperienceRoute,
  isStandaloneRoute,
  STANDALONE_CLIENT_BASE_PATHS,
} from "./routeHelpers";
import { partnerThemes } from "@tigris/mesokit";
import { RouteObject } from "react-router-dom";
import Telemetry from "./telemetry";
import { isDarkMode } from "@tigris/common";

const { VITE_USE_MSW, VITE_TIGRIS_ENV } = import.meta.env;

Telemetry.init();

/** Conditionally initialize `msw` based on environment. */
export const prepareMSW = async () => {
  if (VITE_USE_MSW === "true") {
    const { worker } = await import("@mocks/browser");
    await worker.start({
      onUnhandledRequest(req) {
        if (VITE_TIGRIS_ENV === "local") {
          // eslint-disable-next-line no-console
          console.error(
            "Found an unhandled %s request to %s",
            req.method,
            req.url,
          );
        }
      },
    });
  }
};

/**
 * Sets the `.dark-mode` class on the `<html>` element.
 * This will be used as a signal to our CSS layer to render dark mode styles.
 */
const setDarkMode = () => document.documentElement.classList.add("dark-mode");

/**
 * Execute DOM interactions and setup prior to initializing the React application.
 *
 * All side-effects of inferred configuration should be performed here.
 */
export const prepareDOM = (): {
  router?: RouteObject[];
  /** The first URI segment for the current pathname. */
  basePath?: string;
  /**
   * The HTML element to render the React application into.
   */
  rootElement: HTMLElement;
} => {
  const rootElement = document.getElementById("root") as HTMLElement;
  // Extract the first URI segment from the pathname to determine our client-side routing strategy
  const basePath = cleanPath(`/${location.pathname.split("/")[1]}`);
  const params = new URLSearchParams(location.search);
  const partnerId = params.get("partnerId") ?? "";

  // If we are deep-linking to the standalone integration, redirect to the root.
  // This will short-circuit this boot process.
  if (STANDALONE_CLIENT_BASE_PATHS.includes(basePath)) {
    location.href = `/${location.search}`;
    return { rootElement };
  }

  // Find a client-router based on the given basePath
  const router = routers[basePath];

  // If no router is found, return early
  if (!router) {
    rootElement.classList.add("standalone-root");
    return { rootElement };
  }

  const userPrefersDarkMode = isDarkMode();

  // Set up modal standalone window
  if (isStandaloneRoute(basePath) || isRewardsRoute(basePath)) {
    rootElement.classList.add("standalone-root");

    // For standalone, dark mode is not based on a partner theme, only on the user's preferences
    if (userPrefersDarkMode) setDarkMode();
  }

  if (isEmbeddedRoute(basePath) && userPrefersDarkMode) {
    setDarkMode();
  }

  // Set up basic styles for inline frame
  if (isInlineRoute(basePath)) {
    // Add extra padding to avoid focus-rings on input elements from clipping inside the partner frame.
    rootElement.style.padding = "3px";
  }

  // Set up basic styles for embedded and modal onboarding frames
  if (isModalExperienceRoute(basePath)) {
    rootElement.classList.add("embedded-root");
  }

  // Set up partner theme and appearance for inline and modal onboarding frames
  if (isInlineRoute(basePath)) {
    // Set a partner theme if available
    if (partnerThemes.has(partnerId)) {
      document.documentElement.classList.add(
        `theme-${partnerThemes.get(partnerId)?.themeName}`,
      );
    }

    // Set dark mode if the partner theme requires it or if the user has requested it.
    if (partnerThemes.get(partnerId)?.forceDarkMode || userPrefersDarkMode) {
      setDarkMode();
    }
  }

  return { basePath, router, rootElement };
};
