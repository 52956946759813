import SmartySDK from "smartystreets-javascript-sdk";

const credentials = new SmartySDK.core.SharedCredentials(
  (import.meta.env.VITE_SMARTY_AUTH_ID as string) || "",
);
const client = SmartySDK.core.buildClient.usAutocompletePro(credentials);

export async function smartyLookup(
  search: string,
  selected = "",
): Promise<SmartySDK.usAutocompletePro.Suggestion[] | Error> {
  // skip lookup if search string is empty
  if (search == "") return [];

  // set Smarty lookup params
  const lookup = new SmartySDK.usAutocompletePro.Lookup(
    search.substring(0, 127),
  );
  if (selected != "") lookup.selected = selected.substring(0, 127);
  lookup.maxResults = 6;

  // send lookup req
  try {
    const lookupRes = await client.send(lookup);
    return lookupRes.result;
  } catch {
    return Error("Invalid Response");
  }
}
