import { Button } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  AnimationSequence,
  motion,
  useAnimate,
  usePresence,
} from "framer-motion";
import { useContext, useEffect } from "react";
import { spring } from "@src/utils/animation";
import { AppContext } from "@src/contexts/AppContext";
import { useOnboarding } from "@src/hooks/useOnboarding";

const FORM_ID = "LandingSheetLoginEntry:passkey";

export const LandingSheetLoginEntryPasskey = ({
  onLogInWithPasskey,
  isLoading,
}: {
  onLogInWithPasskey: () => Promise<void>;
  isLoading: boolean;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();
  const { session } = useContext(AppContext);
  const { initializeOnboarding } = useOnboarding();

  // Animate in
  useEffect(() => {
    const sequence: AnimationSequence = [
      [scope.current, { opacity: 1 }],
      [scope.current, { x: 0 }, { at: 0.05 }],
      [".login-input-label", { y: 0, opacity: 1 }, { at: 0.2 }],
      [".login-input-accessory", { y: 0, opacity: 0.6 }, { at: 0.3 }],
    ];
    animate(sequence);
  }, [animate, scope]);

  // Exit
  useEffect(() => {
    const sequence: AnimationSequence = [
      [scope.current, { opacity: 0 }],
      [".login-input-label", { y: 10, opacity: 0 }],
      [".login-input-accessory", { y: 10, opacity: 0 }],
    ];

    if (!isPresent) {
      animate(sequence).then(safeToRemove);
    }
  }, [animate, isPresent, safeToRemove, scope]);

  return (
    <motion.div
      key={`${FORM_ID}:motion`}
      data-testid={`${FORM_ID}:motion`}
      transition={{ ...spring }}
      ref={scope}
    >
      {!session?.isReturningUser && (
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          className="login-input-label mb-2 flex justify-between text-xs font-bold text-neutral-900 dark:text-white"
        >
          <label>Sign Up</label>
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            className="login-input-accessory group cursor-pointer opacity-60"
            onClick={() => {
              initializeOnboarding();
            }}
          >
            Sign Up{" "}
            <span className="inline-flex transition-transform group-hover:translate-x-0.5">
              →
            </span>
          </motion.div>
        </motion.div>
      )}

      <Button
        data-testid="LandingSheetLoginEntry:passkey:button"
        className="flex items-center justify-center gap-2"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={() => onLogInWithPasskey()}
      >
        <FontAwesomeIcon
          icon={icon({ name: "fingerprint", style: "light" })}
          className="h-6 w-6"
        />
        <span className="font-bold">Login with Passkey</span>
      </Button>
    </motion.div>
  );
};
