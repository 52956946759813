import { AuthCodeRef } from "@tigris/mesokit";
import { RefObject, useCallback } from "react";

export const AutoFocusRef = <T extends HTMLInputElement | AuthCodeRef>(
  ref: RefObject<T>,
) =>
  useCallback(() => {
    requestAnimationFrame(() => {
      if (ref.current) {
        ref.current.focus();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
