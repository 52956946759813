import * as Factory from "factory.ts";
import { InstrumentKind, WalletInstrumentFragment } from "@src/generated/sdk";

export const walletInstrumentFactory =
  Factory.Sync.makeFactory<WalletInstrumentFragment>({
    __typename: "WalletInstrument",
    kind: InstrumentKind.WALLET,
    id: "some_blockchain",
    networkNamespace: "?",
    address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    createdAt: new Date(Date.UTC(2023, 10, 11, 0, 0, 0)).toISOString(),
    verified: true,
  });
