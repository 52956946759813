import { api } from "./api";
import {
  AddResidentialAddressInput,
  PartnerQuery,
  UserActivation,
  UserFragment,
} from "./generated/sdk";
import { Network } from "@tigris/common/src/types";
import { SardineEnvironment } from "@sardine-ai/react-js-wrapper";
import { GraphQLError } from "graphql";
import { ThemeName } from "@tigris/mesokit";
import { ReactElement } from "react";

// Export all _types_ from `common` into this types module
export * from "@tigris/common/src/types";

/**
 * A generalized struct to allow sending error messages without throw-ing.
 */
export type GenericError = { message: string };

export type Api = ReturnType<typeof api>;

export type User = Partial<Pick<UserFragment, "id" | "status" | "email">> & {
  /**
   * A string representing the user's formatted phone number.
   */
  phoneNumber?: string;

  /**
   * The status of the user's onboarding activation. This is only at the end of onboarding and is used to determine the terminal UI state for the user.
   */
  activation?: UserActivation;

  theme: ThemeName;

  /** The user's first (given) name. This is only client-side-only data used for pre-filling the payment card form. */
  firstName?: string;
  /** The user's last (family) name. This is only client-side-only data used for pre-filling the payment card form. */
  lastName?: string;
  /** The user's residential address. This is only client-side-only data used for pre-filling the payment card form. */
  residentialAddress?: AddResidentialAddressInput;
} & Partial<{
    fiatInstruments: Extract<
      UserFragment["fiatInstruments"],
      { __typename: "FiatInstruments" }
    >;

    walletInstruments: Extract<
      UserFragment["walletInstruments"],
      { __typename: "WalletInstruments" }
    >;

    depositAddressInstruments: Extract<
      UserFragment["depositAddressInstruments"],
      { __typename: "DepositAddressInstruments" }
    >;
  }>;

/**
 * External configuration values to stand up onboarding.
 */
export type OnboardingConfiguration = {
  network: Network;
  partner: PartnerQuery["partner"];
  session: Session;
  walletAddress: string;
};

export type ReturnToTransferReason =
  | "onboardingComplete"
  | "onboardingTerminated"
  | "returnToLogin";

export enum OnboardingAppRenderContext {
  /** The onboarding application is running as a React component embedded in the Transfer app. */
  EMBEDDED_IN_TRANSFER = "EMBEDDED_IN_TRANSFER",
  /** The onboarding application is running in it's own window and requires communication with the Transfer window. */
  BREAKOUT_FROM_TRANSFER = "BREAKOUT_FROM_TRANSFER",
}

export type OnboardingContextValue = {
  /**
   * Indicates whether the application has finished its boot-sequence and resolved all initial data.
   */
  appReady: boolean;

  /**
   * Externally provided configuration for running an onboarding session.
   */
  // Note: Currently, this is modeled to allow Onboarding within the context of a transfer. In later work, we will allow signups on their own.
  configuration: Omit<OnboardingConfiguration, "session">;

  /** Details of the user. */
  user: User;
  /** Perform an update to the user using a shallow merge strategy. */
  updateUser: (user: Partial<User>) => void;

  /** A callback for when onboarding is resolved – either successfully or terminated. */
  returnToTransfer: (reason: ReturnToTransferReason) => void;

  /**
   * Whether the Onboarding application is embedded as a React component in the Transfer app or as a breakout window attached to a transfer.
   */
  renderContext: OnboardingAppRenderContext;
};

type RiskSession = {
  userId: string;
  sessionKey: string;
  clientId: string;
  environment: SardineEnvironment;
};

/** Details of the current session with the Meso API */
export type Session = {
  /** The unique Meso identifier for the current session */
  id: string;

  /** An authorization token for the current session */
  token: string;

  /** Whether the user (based on walletAddress) has been seen before */
  isReturningUser: boolean;

  /** Whether passkeys are enabled for this current session */
  passkeysEnabled: boolean;

  riskSession?: RiskSession;
};

export type ApiContextValue = {
  api: Api;
  session?: Session;
  updateSession: (session: Partial<Session>) => void;
};

export type GraphQLResponse<T = unknown> = {
  data?: T;
  errors?: GraphQLError[];
  extensions?: unknown;
  status: number;
  [key: string]: unknown;
};

export type CurrentRoute = { pathname: Routes; state?: object };

export type RouterContextValue = {
  currentRoute: CurrentRoute;
  navigate: (pathname: Routes, state?: object) => void;
  outlet: ReactElement;
  /** Navigate back one step. The internal history stack is very basic and will use last-in-first-out semantics. */
  goBack: () => void;
  canGoBack: boolean;

  /** Prevent navigation while events are in-flight. */
  disableNavigation: () => void;
  /** Re-enable navigation. */
  enableNavigation: () => void;
  /** Whether navigation is currently disabled. */
  navigationDisabled: boolean;
};

export enum Routes {
  Root = "/",
  ConnectedWallet = "/connected-wallet",
  CreateAccount = "/create-account",
  PhoneEntry = "/phone-entry",
  Phone2Fa = "/phone-2fa",
  AddPaymentCard = "/add-payment-card",
  BasicInfoOverview = "/basic-info-overview",
  BasicInfoEntry = "/basic-info-entry",
  TaxpayerIdEntry = "/taxpayer-id",
  Summary = "/summary",
  ManualReview = "/manual-review",
  HandoffFailed = "/handoff-failed",
}

export enum OnboardingAppEvents {
  /** An event for the Onboarding application to request configuration from a transfer window. */
  GET_ONBOARDING_CONFIGURATION = "GET_ONBOARDING_CONFIGURATION",
  /** An event dispatched from a transfer window to the Onboarding application with the required configuration for initialization. */
  RETURN_ONBOARDING_CONFIGURATION = "RETURN_ONBOARDING_CONFIGURATION",
  /** An event dispatched from the Onboarding application to a transfer window when onboarding is resolved. */
  ONBOARDING_COMPLETE = "ONBOARDING_COMPLETE",
  /** An event dispatched from the Onboarding application to a transfer window when onboarding is terminated – either via an explicit user cancellation or a failed user activation. */
  ONBOARDING_TERMINATED = "ONBOARDING_TERMINATED",
  /** An event dispatched when the user wishes to return to the Transfer window to attempt logging in. */
  ONBOARDING_RETURN_TO_LOGIN = "ONBOARDING_RETURN_TO_LOGIN",
}
