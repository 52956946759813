import {
  cloneElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { AnimatePresence, motion, Variants } from "framer-motion";
import {
  LANDING_AND_TRANSFER_SHEET_STANDALONE_HEIGHT,
  MESO_MAX_AMOUNT,
  MESO_MIN_AMOUNT,
  Routes,
} from "@utils/constants";
import { twMerge } from "tailwind-merge";
import { Toast } from "../Toast";
import { useMediaQuery } from "@uidotdev/usehooks";
import { motionVariants, spring } from "@src/utils/animation";
import { AppContextValue, CashInQuote } from "@src/types";
import { AppContext } from "../../contexts/AppContextProvider";
import {
  CardHero,
  LineItems,
  ErrorMessages,
  EnvironmentBanner,
  BuyLimit,
  Text,
} from "@tigris/mesokit";
import { TransferKind, TransferStatus } from "@src/generated/sdk";
import { useQuote, UseQuoteConfiguration } from "@src/hooks/useQuote";
import { OutletQuoteContext } from "../LandingAndTransferContainer";
import { TransferStatusIndicator } from "../TransferStatusIndicator";
import { TRANSFER_SUCCESS_STATUSES } from "@src/hooks/useTransfer";
import { AmountEditor } from "../AmountEditor";
import { StandaloneHeader } from "./StandaloneHeader";
import { QuoteLimitErrorCode } from "@src/api";
import { StandaloneDevControls } from "@src/dev/StandaloneDevControls";
import { AnnouncementBanner } from "../AnnouncementBanner";
import { toast } from "sonner";
import { Amplitude } from "@src/utils/telemetry";

const getCardContainerHeight = (
  isOnboardingRoute: boolean,
  isSmallScreen: boolean,
): number | string => {
  const height = isOnboardingRoute
    ? "100%"
    : LANDING_AND_TRANSFER_SHEET_STANDALONE_HEIGHT;

  if (isSmallScreen && isOnboardingRoute) {
    return "100%";
  } else if (height) {
    return height;
  }

  throw new Error("Unable to find height based on pathname.");
};

export const StandaloneLayout = () => {
  const { pathname } = useLocation();
  const isOnboardingRoute = useMemo(
    () => pathname.startsWith("/onboarding"),
    [pathname],
  );
  const isAuxiliaryRoute = useMemo(
    () =>
      pathname === Routes.TransferUnavailable ||
      pathname === Routes.ActivateUser,
    [pathname],
  );
  const {
    configuration: { network, destinationAsset, walletAddress: address },
    user: { theme, fiatInstruments },
    userLimits,
    transfer,
    engageAmountEditor,
    closeAmountEditor,
    isEditingAmount,
    session,
  } = useContext<AppContextValue>(AppContext);
  const isSmallScreen = useMediaQuery("only screen and (max-width : 450px)");
  const [amountEditorErrorMessage, setAmountEditorErrorMessage] =
    useState<string>();

  const allowAmountEditing = useMemo(() => {
    const isRouteThatAllowsEditing =
      pathname === Routes.TransferSheet || pathname === Routes.LandingSheet;

    if (userLimits && isRouteThatAllowsEditing) {
      if (
        userLimits.percentUsed >= 1 ||
        (userLimits.requestedAmountExceedsLimit && !userLimits.editable)
      ) {
        return false;
      } else {
        return isRouteThatAllowsEditing;
      }
    }

    return false;
  }, [pathname, userLimits]);
  const showLimits = useMemo<boolean>(() => {
    if (!userLimits) return false;
    if (transfer && transfer.status) return false;
    if (userLimits && !userLimits.limitReached && userLimits.approachingLimit)
      return true;

    return false;
  }, [userLimits, transfer]);

  useEffect(() => {
    const toastId = "requestedAmountExceedsLimit";

    if (
      userLimits &&
      userLimits.requestedAmountExceedsLimit &&
      !userLimits.editable
    ) {
      Amplitude.track("requested_amount_exceeds_limit", {
        limits: userLimits,
        sessionId: session?.id ?? "unknown",
      });

      toast.info(ErrorMessages.quote.REQUESTED_AMOUNT_EXCEEDS_LIMIT, {
        id: toastId,
      });
    } else {
      toast.dismiss(toastId);
    }
  }, [allowAmountEditing, userLimits, session?.id]);

  // This handler is called whenever there is a "limit error" fetching a quote
  const handleQuoteLimitError = useCallback<
    Required<UseQuoteConfiguration>["onLimitError"]
  >(
    (code?: QuoteLimitErrorCode) => {
      if (code === "below_min_xfer") {
        setAmountEditorErrorMessage(
          ErrorMessages.quote.minLimitError(
            userLimits?.mesoMinimum ?? MESO_MIN_AMOUNT,
          ),
        );
      }

      // Polling is stopped by the useQuote hook when this callback is dispatched.
      engageAmountEditor("auto");
    },
    [engageAmountEditor, userLimits?.mesoMinimum],
  );
  const quoteHook = useQuote({ onLimitError: handleQuoteLimitError });
  const { quote, stop: stopPolling, restart: restartPolling } = quoteHook;
  const outlet = useOutlet({
    useQuote: quoteHook,
  } satisfies OutletQuoteContext);

  const handleAmountEditorResolved = useCallback(() => {
    closeAmountEditor();
    restartPolling();
    setAmountEditorErrorMessage(undefined);
  }, [restartPolling, closeAmountEditor]);

  const handleInitializeAmountEditor = useCallback(
    (sourceAction: Parameters<AppContextValue["engageAmountEditor"]>[0]) => {
      stopPolling();
      engageAmountEditor(sourceAction);
      setAmountEditorErrorMessage(undefined);
    },
    [engageAmountEditor, stopPolling],
  );

  const showChromatic = useMemo(() => {
    return (
      !!transfer?.status &&
      [
        TransferStatus.APPROVED,
        TransferStatus.COMPLETE,
        TransferStatus.EXECUTING,
      ].includes(transfer.status)
    );
  }, [transfer?.status]);

  const showChromaticFull = useMemo(
    () =>
      !!transfer?.status && TRANSFER_SUCCESS_STATUSES.includes(transfer.status),
    [transfer?.status],
  );

  const className = twMerge(
    "card-container",
    showChromatic
      ? "shadow-none transition-shadow overflow-visible"
      : "overflow-y-auto overflow-x-hidden sm:overflow-hidden flex flex-col",
  );

  const fiatInstrument =
    (fiatInstruments &&
      fiatInstruments.collection.length > 0 &&
      fiatInstruments.collection[0]) ||
    undefined;
  const maskedNumber = fiatInstrument?.maskedNumber;

  const animationVariants: Record<string, Variants> = useMemo(() => {
    return {
      mainContainer: {
        initial: {
          scale: 0.9,
          y: -24,
          height: getCardContainerHeight(isOnboardingRoute, isSmallScreen),
        },
        animate: {
          scale: 1,
          y: 0,
          height: getCardContainerHeight(isOnboardingRoute, isSmallScreen),
        },
        exit: {
          scale: 1.2,
          y: -24,
          opacity: 0,
          height: getCardContainerHeight(isOnboardingRoute, isSmallScreen),
        },
      },
      fadeInOut: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
      },
    };
  }, [isOnboardingRoute, isSmallScreen]);

  const amountEditorMinMax = useMemo(() => {
    let maximumAmount = 0;

    if (userLimits?.monthlyAmountAvailable) {
      maximumAmount = userLimits?.monthlyAmountAvailable;
    } else if (session?.mesoLimits.max) {
      maximumAmount = Number(session?.mesoLimits.max);
    } else {
      maximumAmount = MESO_MAX_AMOUNT;
    }

    return {
      minimumAmount: userLimits?.mesoMinimum ?? MESO_MIN_AMOUNT,
      maximumAmount,
    };
  }, [
    session?.mesoLimits.max,
    userLimits?.mesoMinimum,
    userLimits?.monthlyAmountAvailable,
  ]);

  const shouldRenderAmountEditor = useMemo(() => {
    if (!userLimits) {
      return isEditingAmount;
    }

    if (isEditingAmount && userLimits.editable) {
      return true;
    }

    return false;
  }, [isEditingAmount, userLimits]);

  return (
    <div className="root-layout standalone-layout flex overflow-y-auto bg-neutral-100 dark:bg-neutral-900">
      <div className="relative mx-auto flex h-full w-full flex-col gap-4 pt-12 sm:m-auto sm:h-[40rem] sm:w-[22rem] sm:pt-0">
        <Toast key={pathname} />

        {isOnboardingRoute ? (
          <div>{outlet}</div>
        ) : isAuxiliaryRoute ? (
          <div className="onboarding-container shadow-ts-card rounded-ts-card bg-white text-neutral-800 dark:bg-neutral-800 dark:text-white">
            {outlet}
          </div>
        ) : (
          <>
            <AnnouncementBanner />
            <StandaloneHeader
              handleEditAmount={() =>
                handleInitializeAmountEditor("header_button_click")
              }
              allowAmountEditing={allowAmountEditing}
            />
            <AnimatePresence mode="wait">
              <motion.div
                key="StandaloneCardContainer"
                variants={animationVariants.mainContainer}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={spring}
                className={className}
                layout="size"
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    variants={animationVariants.fadeInOut}
                    initial="initial"
                    animate={{
                      opacity: 1,
                      transition: { delay: 0.05 },
                    }}
                    exit="exit"
                    key="StandaloneWrapper"
                    className="h-full"
                  >
                    <AnimatePresence mode="wait">
                      {shouldRenderAmountEditor ? (
                        <AmountEditor
                          key="AmountEditor"
                          onCancel={handleAmountEditorResolved}
                          onComplete={handleAmountEditorResolved}
                          minimumAmount={amountEditorMinMax.minimumAmount}
                          maximumAmount={amountEditorMinMax.maximumAmount}
                          errorMessage={amountEditorErrorMessage}
                        />
                      ) : (
                        <motion.div
                          key="StandaloneTransferContentWrapper"
                          variants={animationVariants.fadeInOut}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          className="flex h-full flex-col"
                          layout="size"
                        >
                          <CardHero
                            disabled={userLimits?.limitReached}
                            theme={theme}
                            asset={destinationAsset}
                            amount={quote?.destination?.amount}
                            network={network}
                            walletAddress={address}
                            fiatDisplay={maskedNumber}
                            transferKind={TransferKind.CASH_IN}
                            editable={allowAmountEditing}
                            onEditAmount={() =>
                              handleInitializeAmountEditor("amount_click")
                            }
                            // If the user has attempted a transfer or the user has reached their limit, don't render this in the card hero
                            limits={
                              showLimits && userLimits ? userLimits : undefined
                            }
                          />
                          {transfer?.status && (
                            <TransferStatusIndicator
                              className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[15%] text-center text-sm font-semibold"
                              status={transfer.status}
                              bloom={false}
                            />
                          )}
                          <div className="h-full p-6">
                            <AnimatePresence mode="wait">
                              {userLimits && userLimits.limitReached ? (
                                <motion.div
                                  key="BuyLimitReached"
                                  variants={motionVariants.fadeIn}
                                  className="flex h-full flex-col justify-between"
                                >
                                  <>
                                    <BuyLimit limits={userLimits} />
                                    <Text>
                                      You have reached your rolling 30-day
                                      buying limit.{" "}
                                      <a
                                        className="font-medium underline"
                                        href="https://support.meso.network/hc/en-us/articles/17053929093531-Fees-Limits"
                                      >
                                        Learn more about buy limits
                                      </a>
                                      .
                                    </Text>
                                  </>
                                </motion.div>
                              ) : (
                                <motion.div
                                  key="LineItems"
                                  variants={motionVariants.fadeIn}
                                >
                                  <LineItems
                                    expanded={true}
                                    key="TransferSheet2FaQuoteLineItems"
                                    exchangeRate={quote?.exchangeRate}
                                    mesoFeeAmount={quote?.mesoFee.amount}
                                    mesoFeeWaived={
                                      (quote as CashInQuote)?.mesoFeeWaived
                                    }
                                    networkFeeAmount={
                                      (quote as CashInQuote)?.networkFee?.amount
                                    }
                                    networkFeeWaived={
                                      (quote as CashInQuote)?.networkFeeWaived
                                    }
                                    subtotalAmount={
                                      quote?.sourceSubtotal.amount
                                    }
                                    totalAmount={quote?.sourceTotal.amount}
                                    transferKind={TransferKind.CASH_IN}
                                  />
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>

                  {showChromatic && (
                    <motion.div
                      key="chromatic-background"
                      initial={{
                        opacity: 0,
                        y: "-50%",
                        scale: 0.5,
                      }}
                      animate={{
                        opacity: showChromaticFull ? 1 : 0.5,
                        y: "-50%",
                        scale: showChromaticFull ? 1 : 0.8,
                        transition: { duration: 0.5, ...spring },
                      }}
                      exit={{ opacity: 0, scale: 0.7 }}
                      className="chromatic-background-wrapper"
                    >
                      <motion.div
                        initial={{
                          rotate: 0,
                        }}
                        animate={{
                          rotate: 360,
                          transition: {
                            repeat: Infinity,
                            duration: 8,
                            repeatType: "loop",
                            ease: "linear",
                          },
                        }}
                        className="chromatic-background-inner"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>
            {outlet && !userLimits?.limitReached && (
              <div className="bottom-0 mt-auto w-full sm:absolute">
                <AnimatePresence mode="wait">
                  {cloneElement(outlet, { key: pathname })}
                </AnimatePresence>
              </div>
            )}
          </>
        )}
      </div>

      <StandaloneDevControls />
      <div className="fixed left-0 top-4 flex w-full justify-center">
        <EnvironmentBanner />
      </div>
    </div>
  );
};
