import { HttpResponse, http } from "msw";

export const smartyMockHandlers = [
  http.get(
    "https://us-autocomplete-pro.api.smarty.com/lookup",
    ({ request }) => {
      const testAddress1 = "35 Lomasney Way Apt Boston MA, 02114";
      const testAddress2 = "619 The Foo Berkeley CA, 94707";

      const testAddress1Suggestions = [
        {
          street_line: "35 Lomasney Way",
          secondary: "Apt",
          city: "Boston",
          state: "MA",
          zipcode: "02114",
          entries: 467,
        },
        {
          street_line: "35 Lomasney Way",
          secondary: "",
          city: "Boston",
          state: "MA",
          zipcode: "02114",
          entries: 0,
        },
        {
          street_line: "35 Lopez St",
          secondary: "",
          city: "Cambridge",
          state: "MA",
          zipcode: "02139",
          entries: 0,
        },
        {
          street_line: "123 Street Way",
          secondary: "P.O. Box 100",
          city: "Grand Junction",
          state: "CO",
          zipcode: "81504",
          entries: 0,
        },
        {
          street_line: "PO Box 123",
          secondary: "",
          city: "Plain",
          state: "KS",
          zipcode: "78234",
          entries: 0,
        },
        {
          street_line: "P.O. Box 78-A",
          secondary: "",
          city: "Never",
          state: "MT",
          zipcode: "33332",
          entries: 0,
        },
      ];
      const testAddress1SecondarySuggestions = [
        {
          street_line: "35 Lomasney Way",
          secondary: "Apt 300",
          city: "Boston",
          state: "MA",
          zipcode: "02114",
          entries: 1,
        },
        {
          street_line: "35 Lomasney Way",
          secondary: "Apt 301",
          city: "Boston",
          state: "MA",
          zipcode: "02114",
          entries: 1,
        },
        {
          street_line: "35 Lomasney Way",
          secondary: "Apt 302",
          city: "Boston",
          state: "MA",
          zipcode: "02114",
          entries: 1,
        },
      ];

      const testAddress2Suggestions = [
        // Address without a road label
        {
          street_line: "619 The Foo",
          secondary: "",
          city: "Berkeley",
          state: "CA",
          zipcode: "94707",
          entries: 0,
        },
      ];

      const url = new URL(request.url);
      const search = url.searchParams.get("search");
      const selected = url.searchParams.get("selected");

      if (Array.isArray(search) || search === null || search.length === 0) {
        return HttpResponse.json({ suggestions: [] });
      }

      if (
        (selected === null || selected.length === 0) &&
        (search === "," || search.trim() === "")
      ) {
        return HttpResponse.json({
          suggestions: [...testAddress1Suggestions, ...testAddress2Suggestions],
        });
      }

      if (testAddress1.toLowerCase().includes(search.toLowerCase())) {
        if (selected != null && selected.length > 0) {
          return HttpResponse.json({
            suggestions: testAddress1SecondarySuggestions,
          });
        } else if (search != null && search.length > 0) {
          return HttpResponse.json({ suggestions: testAddress1Suggestions });
        } else {
          return HttpResponse.json({ suggestions: [] });
        }
      }

      if (testAddress2.toLowerCase().includes(search.toLowerCase())) {
        return HttpResponse.json({ suggestions: testAddress2Suggestions });
      }

      return search.toLowerCase().includes("error")
        ? new HttpResponse(null, { status: 401 })
        : HttpResponse.json({ suggestions: [] });
    },
  ),
];
