import { MesoToaster } from "@tigris/mesokit";
import { AnimatePresence, motion } from "framer-motion";

/**
 * @returns Sonner Toaster wrapped in div with transform property to allow
 * absolute positioning (vs. fixed by default)
 */
export function Toast() {
  return (
    <AnimatePresence>
      <motion.div
        key="onboardingToast"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute z-50 w-full transform"
      >
        <MesoToaster
          key="onboardingToast"
          style={{
            left: "50%",
            top: ".5rem",
            transform: "translateX(-50%)",
            width: "calc(100% - 16px)",
          }}
          duration={300000}
        />
      </motion.div>
    </AnimatePresence>
  );
}
