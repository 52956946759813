import { MesoKitContext } from "@tigris/mesokit";
import { toast } from "sonner";
import { ApiContextProvider } from "../src/contexts/ApiContextProvider";
import { OnboardingContextProvider } from "../src/contexts/OnboardingContextProvider";
import { RouterContextProvider } from "../src/contexts/RouterContextProvider";
import { SardineRiskSession } from "../src/contexts/SardineRiskSession";
import { DevControls } from "../src/dev/DevControls";
import { Layout } from "../src/Layout";
import { Sentry, Amplitude } from "../src/utils/telemetry";
import {
  CurrentRoute,
  OnboardingAppRenderContext,
  OnboardingConfiguration,
  OnboardingContextValue,
  Routes,
} from "../src/types";
import { routes } from "../src/routes";

type OnboardingAppProps = Required<OnboardingConfiguration> & {
  onReturnToTransfer: OnboardingContextValue["returnToTransfer"];
  initialRoute?: CurrentRoute;
  telemetry: {
    Sentry: typeof Sentry;
    Amplitude: typeof Amplitude;
  };
};

export const OnboardingApp = ({
  network,
  partner,
  session,
  walletAddress,
  initialRoute = { pathname: Routes.Root },
  telemetry,
  onReturnToTransfer,
}: OnboardingAppProps) => {
  return (
    <RouterContextProvider routes={routes} initialRoute={initialRoute}>
      <MesoKitContext.Provider
        value={{
          sentry: telemetry.Sentry,
          amplitude: telemetry.Amplitude,
          toast,
        }}
      >
        <ApiContextProvider providedSession={session}>
          <OnboardingContextProvider
            network={network}
            partner={partner}
            walletAddress={walletAddress}
            onReturnToTransfer={onReturnToTransfer}
            renderContext={OnboardingAppRenderContext.EMBEDDED_IN_TRANSFER}
          >
            <SardineRiskSession flow="onboarding">
              <Layout />
            </SardineRiskSession>
          </OnboardingContextProvider>
          <DevControls />
        </ApiContextProvider>
      </MesoKitContext.Provider>
    </RouterContextProvider>
  );
};
