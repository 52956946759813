export type ThemeName =
  | "default"
  | "bubblegum"
  | "anza"
  | "spearmint"
  | "superholo"
  | "sorbet"
  | "ooze"
  | "platinum"
  | "pulse"
  | "amplitude"
  | "waves"
  | "neon"
  | "inline"
  | "mrgn"
  | "arena"
  | "keychain";

type ThemeProperties = {
  /** Whether the theme contains an animated hero background. */
  animated: boolean;
  /** Whether the theme can be selected by a user. */
  userConfigurable: boolean;
};

// Tailwind cannot handle className concatenation
// we need full strings for various properties
export const themeCollection: Record<ThemeName, ThemeProperties> = {
  default: { animated: false, userConfigurable: true },
  bubblegum: { animated: false, userConfigurable: true },
  anza: { animated: false, userConfigurable: true },
  spearmint: { animated: false, userConfigurable: true },
  superholo: { animated: false, userConfigurable: true },
  sorbet: { animated: false, userConfigurable: true },
  ooze: { animated: false, userConfigurable: true },
  platinum: { animated: false, userConfigurable: true },
  pulse: { animated: true, userConfigurable: true },
  amplitude: { animated: true, userConfigurable: true },
  waves: { animated: true, userConfigurable: true },
  neon: { animated: true, userConfigurable: true },
  inline: { animated: false, userConfigurable: false },
  mrgn: { animated: false, userConfigurable: false },
  arena: { animated: false, userConfigurable: false },
  keychain: { animated: false, userConfigurable: false },
};

export const isValidTheme = (theme: ThemeName): boolean =>
  theme in themeCollection;

export const DEFAULT_THEME: ThemeName = "default";

export const partnerThemes = new Map<
  string,
  { themeName: ThemeName; forceDarkMode: boolean }
>([
  ["meso-dev", { themeName: "inline", forceDarkMode: false }],
  ["marginfi", { themeName: "mrgn", forceDarkMode: true }],
  ["arena", { themeName: "arena", forceDarkMode: false }],
  ["keychain", { themeName: "keychain", forceDarkMode: false }],
]);
