import { CheckoutContext } from "./CheckoutContext";
import { CheckoutContextValue } from "@src/types";
import { ErrorMessages } from "@tigris/mesokit";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Sentry } from "@src/utils/telemetry";
import { err, ok } from "neverthrow";

const RISK_JS_PRODUCTION_URL = "https://risk.checkout.com/cdn/risk/1/risk.js";
const RISK_JS_SANDBOX_URL =
  "https://risk.sandbox.checkout.com/cdn/risk/1/risk.js";

export const CheckoutContextProvider = ({ children }: PropsWithChildren) => {
  // React strict mode causes useEffect callbacks to be invoked twice. We use
  // the `riskJsInitialized` ref to avoid initializing riskJs from a second
  // invocation.
  const riskJsInitialized = useRef(false);
  const [context, setContext] = useState<CheckoutContextValue>({
    retrieveDeviceSessionId: async () =>
      err(ErrorMessages.checkoutContext.NOT_INITIALIZED),
  });

  useEffect(() => {
    if (riskJsInitialized.current) return;
    riskJsInitialized.current = true;
    setRetrieveDeviceSessionId(setContext);
  }, []);

  return (
    <CheckoutContext.Provider value={context}>
      {children}
    </CheckoutContext.Provider>
  );
};

const setRetrieveDeviceSessionId = (
  setContext: React.Dispatch<React.SetStateAction<CheckoutContextValue>>,
) => {
  /** https://www.checkout.com/docs/business-operations/prevent-fraud/integrate-with-risk-sdk */
  const script = document.createElement("script");
  script.async = true;
  script.src =
    import.meta.env.VITE_TIGRIS_ENV === "prod"
      ? RISK_JS_PRODUCTION_URL
      : RISK_JS_SANDBOX_URL;

  document.body.appendChild(script);

  script.addEventListener("load", () => {
    if (window.Risk) {
      const riskJsInstance = window.Risk.init(
        import.meta.env.VITE_CHECKOUT_PUBLIC_KEY,
      );

      setContext((previousContext) => ({
        ...previousContext,
        retrieveDeviceSessionId: async () => {
          try {
            return ok(await riskJsInstance.publishRiskData());
          } catch (error: unknown) {
            let errorMessage: string;

            if (error instanceof Error) {
              errorMessage = error.message;
            } else {
              errorMessage =
                typeof error === "object" && error !== null
                  ? JSON.stringify(error)
                  : String(error);
            }
            Sentry.captureMessage(
              ErrorMessages.checkoutContext.FAILED_TO_RETRIEVE_DEVICE_SESSION,
              {
                level: "warning",
                tags: { operation: "publishRiskData" },
                extra: { errorMessage },
              },
            );

            return err(
              ErrorMessages.checkoutContext.FAILED_TO_RETRIEVE_DEVICE_SESSION,
            );
          }
        },
      }));
    }
  });
};
