import { Variants, motion } from "framer-motion";
import { spring } from "@src/utils/animation";
import { BuyLimit, CobrandingLogo, Text, Tooltip } from "@tigris/mesokit";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

// Variants
const headerAnimationVariants: Variants = {
  initial: { opacity: 0, y: -16, scale: 0.9 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { ...spring, delay: 0.1 },
  },
};

const buyHeaderVariants: Variants = {
  initial: { opacity: 0, x: -16, scale: 0.9 },
  animate: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: { ...spring, delay: 0.45 },
  },
};

export const StandaloneHeader = ({
  allowAmountEditing,
  handleEditAmount,
}: {
  handleEditAmount: () => void;
  allowAmountEditing: boolean | undefined;
}) => {
  const {
    partner,
    configuration: { destinationAsset },
    isEditingAmount,
    userLimits,
  } = useContext(AppContext);

  return (
    <motion.header
      key="Standalone:Header"
      variants={headerAnimationVariants}
      initial="initial"
      animate="animate"
      className="absolute top-1 w-full sm:static"
    >
      <div className="flex flex-row items-center justify-between tracking-tight">
        <div className="flex flex-row items-center gap-2 font-semibold">
          <CobrandingLogo
            size="md"
            displayName={partner?.displayName}
            logoUri={partner?.logoUri}
          />
          <motion.div
            variants={buyHeaderVariants}
            initial="initial"
            animate="animate"
            className="text-sm text-neutral-800 dark:text-white"
          >
            Buy {destinationAsset}
          </motion.div>
        </div>
        <div className="flex items-center gap-4 text-neutral-800 dark:text-white">
          {allowAmountEditing && !isEditingAmount && (
            <Tooltip message="Edit Amount">
              <FontAwesomeIcon
                onClick={handleEditAmount}
                icon={icon({ name: "edit", style: "solid" })}
                className="cursor-pointer items-center gap-1 opacity-60 transition-opacity hover:opacity-100"
              />
            </Tooltip>
          )}

          {userLimits && (
            <Popover className="relative">
              {({ open }) => (
                <Tooltip message="Buy Limit" suppress={open}>
                  <>
                    <PopoverButton
                      className={twMerge(
                        "size-6 rounded-full outline-none transition-colors",
                        open && "bg-primary-light text-white",
                      )}
                    >
                      <FontAwesomeIcon
                        icon={icon({ name: "gauge-simple", style: "regular" })}
                        className={twMerge(
                          "cursor-pointer items-center gap-1 opacity-60 transition-all hover:rotate-[15deg] hover:opacity-100",
                          open && "rotate-[15deg] opacity-100",
                        )}
                      />
                    </PopoverButton>
                    <PopoverPanel
                      anchor="bottom"
                      transition
                      className="relative z-50 mt-2 flex origin-top flex-col transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
                    >
                      <div className="flex w-full flex-col gap-2 rounded-3xl bg-neutral-50 p-2 md:max-w-sm dark:bg-neutral-700 dark:shadow-lg">
                        {userLimits && <BuyLimit limits={userLimits} />}
                        <div className="p-2">
                          <Text>
                            Your buying limit is the maximum amount you can
                            purchase over any rolling 30-day period,
                            automatically updating daily based on your activity.
                          </Text>
                          <Text className="mt-2 font-bold">
                            <a
                              href="https://support.meso.network/hc/en-us/articles/17053929093531-Fees-Limits"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Learn more about limits →
                            </a>
                          </Text>
                        </div>
                      </div>
                    </PopoverPanel>
                  </>
                </Tooltip>
              )}
            </Popover>
          )}

          <Tooltip message="Get Help">
            <div className="relative flex">
              <FontAwesomeIcon
                onClick={() => window.open("https://support.meso.network")}
                icon={icon({ name: "life-ring", style: "regular" })}
                className="cursor-pointer items-center gap-1 opacity-60 transition-all hover:rotate-90 hover:opacity-100"
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </motion.header>
  );
};
