import { twMerge } from "tailwind-merge";
import { LOGO_SIZES } from "../utils/constants";
import { Logo } from "./Logo";
import { PartnerLogo } from "./PartnerLogo";
import { motion } from "framer-motion";
import { spring } from "../utils/animation";

export const CobrandingLogo = ({
  size = "sm",
  displayName,
  logoUri,
  className,
}: {
  size?: keyof typeof LOGO_SIZES;
  displayName?: string;
  logoUri?: string;
  className?: string;
}) => {
  return (
    <div
      data-testid="CobrandingLogo"
      className={twMerge("flex items-center justify-center", className)}
    >
      <motion.div
        initial={{ opacity: 0, x: 0, scale: 0.7 }}
        animate={{
          opacity: 1,
          x: 8,
          scale: 1,
          transition: {
            ...spring,
            delay: 0.05,
          },
        }}
        className="z-10"
      >
        <Logo size={size} showText={false} />
      </motion.div>

      <motion.div
        className="relative"
        initial={{ opacity: 0, x: -8, scale: 0.7 }}
        animate={{
          opacity: 1,
          x: 0,
          scale: 1,
          transition: {
            ...spring,
            delay: 0.07,
          },
        }}
      >
        <PartnerLogo size={size} displayName={displayName} logoUri={logoUri} />
      </motion.div>
    </div>
  );
};
