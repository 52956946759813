import { useLocalStorage } from "@uidotdev/usehooks";
import { useState } from "react";

/**
 * Some browser may throw an exception when reading from localStorage (e.g.
 * Safari). This hook wraps the `useLocalStorage` invocation in a `try/catch`
 * block to safely handle this exception. Note well that it falls back to using
 * `useState`, which is in-memory only, meaning state will persist within this
 * app load, but will restart on page refresh.
 */
export const useSafeLocalStorage = <T,>(
  key: string,
  initialValue?: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  /* hooks usage must not be conditional so always call `useState` even if it's
   * unused. */
  const inMemoryState = useState<T>(initialValue as T);

  try {
    return useLocalStorage<T>(key, initialValue);
  } catch {
    return inMemoryState;
  }
};
