import { AppContext } from "@src/contexts/AppContextProvider";
import { TransferStatus } from "@src/generated/sdk";
import { toast } from "sonner";
import { useContext, useEffect, useState } from "react";

const POLL_INTERVAL_MSEC = 1e3;
export const TRANSFER_TERMINAL_STATUSES = [
  TransferStatus.COMPLETE,
  TransferStatus.DECLINED,
];
export const TRANSFER_SUCCESS_STATUSES = [
  TransferStatus.APPROVED,
  TransferStatus.COMPLETE,
];

export default function useTransfer(transferId?: string) {
  const {
    api: { resolveCheckTransfer },
    transfer,
    setTransfer,
  } = useContext(AppContext);
  const [timer, setTimer] = useState<ReturnType<typeof setInterval> | null>(
    null,
  );

  useEffect(() => {
    if (transferId) {
      const t = setInterval(async () => {
        const checkTransferResult = await resolveCheckTransfer({
          id: transferId,
        });

        if (checkTransferResult.isErr()) {
          toast.error(checkTransferResult.error);
          return;
        }

        setTransfer(checkTransferResult.value);
      }, POLL_INTERVAL_MSEC);
      setTimer(t);
      return () => {
        clearInterval(t);
      };
    }
  }, [resolveCheckTransfer, setTransfer, transferId]);

  useEffect(() => {
    if (
      transfer &&
      timer &&
      TRANSFER_TERMINAL_STATUSES.includes(transfer.status)
    ) {
      clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer]);

  return transfer;
}
