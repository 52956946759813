import { USER_WALLET_ADDRESS } from "@mocks/factories";
import { AppContext } from "@src/contexts/AppContextProvider";
import { Routes } from "@src/utils/constants";
import { themeCollection, ThemeName } from "@tigris/mesokit";
import { motion, useDragControls } from "framer-motion";
import { useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const TEST_QUERY_STRING = `?partnerId=foo&network=eip155:1&walletAddress=${USER_WALLET_ADDRESS}&sourceAmount=100&destinationAsset=ETH&version=0.0.30`;

const styles: { [key: string]: React.CSSProperties } = {
  button: {
    display: "block",
    marginBottom: "0.5em",
    padding: "0.5em 0.8em",
    backgroundColor: "#aaa",
  },
};

const themeNames = Object.keys(themeCollection);

function getNextTheme(currentTheme: ThemeName): ThemeName {
  const currentIndex = themeNames.indexOf(currentTheme);
  if (currentIndex === -1) {
    throw new Error(`Theme "${currentTheme}" not found.`);
  }
  const nextIndex = (currentIndex + 1) % themeNames.length;
  return themeNames[nextIndex] as ThemeName;
}

function getPreviousTheme(currentTheme: ThemeName): ThemeName {
  const currentIndex = themeNames.indexOf(currentTheme);
  if (currentIndex === -1) {
    throw new Error(`Theme "${currentTheme}" not found.`);
  }
  const previousIndex =
    (currentIndex - 1 + themeNames.length) % themeNames.length;

  return themeNames[previousIndex] as ThemeName;
}

export const DevOnlyFrameControl = () => {
  const {
    session,
    updateSession,
    user: { theme },
    updateUser,
  } = useContext(AppContext);

  const handleThemeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      updateUser({ theme: event.target.value as ThemeName });
    },
    [updateUser],
  );

  const handleNextTheme = useCallback(() => {
    const nextTheme = getNextTheme(theme);
    updateUser({ theme: nextTheme });
  }, [theme, updateUser]);

  const handlePreviousTheme = useCallback(() => {
    const previousTheme = getPreviousTheme(theme);

    updateUser({ theme: previousTheme });
  }, [theme, updateUser]);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const controls = useDragControls();

  if (
    !import.meta.env.DEV ||
    import.meta.env.VITE_INTEGRATION_TEST === "true"
  ) {
    return;
  }

  return (
    <>
      <motion.div
        className="fixed bottom-2 left-2 rounded border-2 border-black bg-[rgba(255,255,255,0.8)] p-4 text-sm"
        drag
        dragControls={controls}
      >
        {import.meta.env.VITE_INTEGRATION_TEST ?? "UNKNOWN"}
        <div className="mb-4 rounded-xl bg-yellow-400 px-3 py-2 text-xs font-bold dark:bg-yellow-500">
          ⚠️ Development Mode Only
        </div>

        <h1 className="text-md font-bold">Transfer app controls</h1>
        <div className="mb-2 border-b-2 border-black py-2">
          <button
            style={styles.button}
            onClick={() => {
              location.search = TEST_QUERY_STRING;
            }}
          >
            Inject query params
          </button>
        </div>

        <div className="mb-2 border-b-2 border-black py-2">
          <div className="font-bold">Navigation</div>
          <select
            value={Object.values(Routes).find((path) => path === pathname)}
            onChange={(event) => {
              navigate({
                pathname: event.target.value,
                search: location.search,
              });
            }}
          >
            {Object.entries(Routes).map(([k, v]) => (
              <option key={k} value={v}>
                {k}
              </option>
            ))}
          </select>
        </div>

        {pathname === Routes.LandingSheet && (
          <div>
            <button
              style={styles.button}
              disabled={!session}
              onClick={() => {
                if (session) {
                  updateSession({
                    riskSession: {
                      ...session?.riskSession,
                      sessionKey: "risk-session-key",
                    },
                  });
                }
              }}
            >
              Use good risk session key
            </button>
            <button
              style={styles.button}
              disabled={!session}
              onClick={() => {
                if (session) {
                  updateSession({
                    riskSession: {
                      ...session?.riskSession,
                      sessionKey: "bad-risk-session-key",
                    },
                  });
                }
              }}
            >
              Use bad risk session key
            </button>
          </div>
        )}

        <div>
          <div className="mb-1 font-bold dark:text-white">Select theme:</div>

          <button onClick={handlePreviousTheme}>Prev</button>
          <select
            name="theme"
            id="theme"
            value={theme}
            onChange={handleThemeChange}
            className="rounded-xl px-2 py-1 dark:bg-neutral-800 dark:text-white"
          >
            {Object.entries(themeCollection)
              .filter(([_, { userConfigurable }]) => userConfigurable)
              .map(([themeName]) => (
                <option key={themeName} value={themeName}>
                  {themeName}
                </option>
              ))}
          </select>
          <button onClick={handleNextTheme}>Next</button>
        </div>
      </motion.div>
    </>
  );
};
