import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ApiContextValue, Session } from "../types";
import { getSdk } from "../generated/sdk";
import { GraphQLClient } from "graphql-request";
import { api as initializeApi } from "../api";
import { ApiContext } from "./ApiContext";
import { Amplitude, Sentry } from "../utils/telemetry";

const { VITE_GRAPHQL_ORIGIN } = import.meta.env;

const MESO_AUTHORIZATION_HEADER = "Authorization";

const apiOrigin =
  VITE_GRAPHQL_ORIGIN && typeof VITE_GRAPHQL_ORIGIN === "string"
    ? VITE_GRAPHQL_ORIGIN
    : location.origin;

type ApiContextProviderProps = {
  /**
   * Details of a Meso API session provided when the Onboarding application is running in the context of a Transfer.
   */
  providedSession?: ApiContextValue["session"];
};

export const ApiContextProvider = ({
  children,
  providedSession,
}: PropsWithChildren<ApiContextProviderProps>) => {
  // Initialize the API
  const graphQLClient = useMemo(
    () =>
      new GraphQLClient(`${apiOrigin}/query`, {
        errorPolicy: "all",
      }),
    [],
  );

  useEffect(() => {
    if (providedSession && providedSession.riskSession?.userId) {
      Amplitude.setIdentifyUserId(providedSession.riskSession.userId);
    }
  }, [providedSession]);

  const [session, setSession] = useState<ApiContextValue["session"]>(() => {
    if (providedSession) {
      graphQLClient.setHeader(
        MESO_AUTHORIZATION_HEADER,
        `Bearer ${providedSession.token}`,
      );

      return providedSession;
    }
  });

  const updateSession = useCallback<ApiContextValue["updateSession"]>(
    (session: Partial<Session>) => {
      if (session.token) {
        if (session.token) {
          graphQLClient.setHeader(
            MESO_AUTHORIZATION_HEADER,
            `Bearer ${session.token}`,
          );

          Sentry.setContext("session", session);
        }

        if (session.riskSession && session.riskSession.userId) {
          Amplitude.setIdentifyUserId(session.riskSession.userId);
        }
      }

      setSession((previousSession) => ({
        ...previousSession,
        ...(session as Session),
      }));
    },
    [graphQLClient],
  );

  const api = useMemo<ApiContextValue["api"]>(
    () => initializeApi(getSdk(graphQLClient)),
    [graphQLClient],
  );

  const contextValue = useMemo<ApiContextValue>(
    () => ({ api, updateSession, session }),
    [api, session, updateSession],
  );

  // Enable this when onboarding can run detached from a transfer and needs to fetch its own session
  // useEffect(() => {
  //   if (sessionInitialized.current || providedSession) return;
  //   sessionInitialized.current = true;

  //   (async () => {
  //     const newSessionResult = await api.resolveNewSession({
  //       input: {},
  //     });

  //     if (newSessionResult.isErr()) {
  //       return;
  //     }

  //     const newSession = newSessionResult.value;

  //     updateSession({
  //       id: newSession.id,
  //       token: newSession.token,
  //       riskSession: {
  //         userId: newSession.riskSession.userId,
  //         clientId: newSession.riskSession.clientId,
  //         sessionKey: newSession.riskSession.sessionKey,
  //         environment: newSession.riskSession.environment as SardineEnvironment,
  //       },
  //       isReturningUser: newSession.isReturningUser,
  //       passkeysEnabled: newSession.passkeysEnabled,
  //     });
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};
