import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  OnboardingAppRenderContext,
  OnboardingContextValue,
  ReturnToTransferReason,
  User,
} from "../types";
import { OnboardingContext } from "./OnboardingContext";
import { useApi } from "../hooks/useApi";
import { DEFAULT_THEME, isValidTheme } from "@tigris/mesokit";
import { Amplitude } from "../utils/telemetry";

export type OnboardingContextProviderProps =
  OnboardingContextValue["configuration"] & {
    /**
     * A callback to take the user back to the Transfer window (if available). This is dispatched when onboarding resolves successfully, user activation fails, the user needs to go back to login, or if the user wishes to cancel the session.
     */
    onReturnToTransfer: (reason: ReturnToTransferReason) => void;
    /** The mode the Onboarding application is running in.
     *
     * Default: `BREAKOUT_FROM_TRANSFER`
     */
    renderContext?: OnboardingContextValue["renderContext"];
  };

export const OnboardingContextProvider = ({
  children,
  network,
  walletAddress,
  partner,
  onReturnToTransfer,
  renderContext = OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER,
}: PropsWithChildren<OnboardingContextProviderProps>) => {
  const { session } = useApi();
  const [user, setUser] = useState<User>({ theme: "default" });

  // Set amplitude context on first render
  useEffect(
    () => Amplitude.setContext({ renderContext }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateUser = useCallback<OnboardingContextValue["updateUser"]>(
    (user: Partial<User>) => {
      if (user.theme) {
        const newTheme = isValidTheme(user.theme) ? user.theme : DEFAULT_THEME;

        const currentThemeClasses = Array.from(
          document.documentElement.classList,
        ).filter((className) => className.startsWith("theme-"));

        // Side effects!
        document.documentElement.classList.remove(...currentThemeClasses);

        document.documentElement.classList.add(
          `theme-${newTheme ?? "default"}`,
        );

        user.theme = newTheme;
      }

      setUser((prevUser) => ({
        ...prevUser,
        ...user,
      }));
    },
    [],
  );

  const appReady = useMemo(() => session !== undefined, [session]);

  const contextValue = useMemo<OnboardingContextValue>(
    () => ({
      user,
      appReady,
      updateUser,
      configuration: {
        network,
        walletAddress,
        partner,
      },
      returnToTransfer: onReturnToTransfer,
      renderContext,
    }),
    [
      appReady,
      network,
      onReturnToTransfer,
      partner,
      renderContext,
      updateUser,
      user,
      walletAddress,
    ],
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};
