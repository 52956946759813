import debounce from "lodash.debounce";
import { AddressInputProps, Suggestion } from ".";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LabelledInput } from "../Input/LabelledInput";
import { Text } from "../Text";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { smartyLookup } from "../../utils/smarty";
import { parseAddress } from "../../utils/parseAddress";
import { twMerge } from "tailwind-merge";
import {
  useState,
  useMemo,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { useCombobox } from "downshift";
import { parseSuggestion } from "../../utils/parseSuggestion";

const variants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

export const Autocomplete = ({
  onAddressResolved,
  labelText = "",
  disabled,
  isValid,
  inputName,
  setManualEntry,
  placeholder = "",
}: AddressInputProps & {
  setManualEntry: (suggestion?: Suggestion | null) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [suggestionDialogWidth, setSuggestionDialogWidth] = useState(0);
  const [addressSuggestions, setAddressSuggestions] = useState<Suggestion[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [inputValueString, setInputValueString] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  // use ref of full width container diff to calculate width for fixed position dialog
  useLayoutEffect(() => {
    if (containerRef.current) {
      setSuggestionDialogWidth(containerRef.current.offsetWidth);
    }
  }, []);

  const emptyStateItem = useMemo(
    () =>
      ({
        streetLine: "",
        secondary: "",
        city: "",
        state: "",
        zipcode: "",
      }) as Suggestion,
    [],
  );
  const emptyStateItems = useMemo(() => [emptyStateItem], [emptyStateItem]);

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    setHighlightedIndex,
    getItemProps,
  } = useCombobox<Suggestion>({
    items: addressSuggestions || emptyStateItems,
    itemToString: (item) => (item ? parseSuggestion(item) : ""),
    onStateChange: async (changes) => {
      const { inputValue, selectedItem } = changes;

      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputChange: {
          setInputValueString(inputValue ?? "");
          setDialogIsOpen(true);
          debouncedLookup(inputValue, selectedItem);
          break;
        }
        case useCombobox.stateChangeTypes.InputKeyDownEscape: {
          if (!dialogIsOpen) setInputValueString("");
          setAddressSuggestions(undefined);
          setDialogIsOpen(false);
          break;
        }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur: {
          const isEmptyState = selectedItem === emptyStateItem;
          if (isEmptyState) {
            // selecting manual entry item
            setManualEntry();
          } else if (selectedItem && selectedItem.entries <= 1) {
            // selecting Suggestion without additional units
            onAddressResolved(selectedItem, parseSuggestion(selectedItem));
            setManualEntry(selectedItem);
          } else if (selectedItem) {
            // selecting Suggestion with additional units
            setInputValueString(inputValue ?? "");
            debouncedLookup(inputValue, selectedItem);
            const cursorLocation = parseSuggestion(selectedItem).indexOf(",");
            inputRef.current?.setSelectionRange(cursorLocation, cursorLocation);
          } else {
            // blurring with no selected Suggestion, attempt to parse previous
            // input value into address
            const parsed = parseAddress(inputValueString);
            if (parsed.isOk()) {
              onAddressResolved(parsed.value, parseSuggestion(parsed.value));
              setManualEntry(parsed.value);
            } else {
              onAddressResolved(null, inputValueString);
              setDialogIsOpen(false);
            }
          }
          break;
        }
      }
    },
  });

  const handleSmartyLookup = useCallback(() => {
    return async (inputValue?: string, selectedItem?: Suggestion | null) => {
      setIsLoading(true);

      let search = inputValue ?? "";
      let selected = "";
      if (selectedItem && selectedItem.entries > 1) {
        // per https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
        search = `${selectedItem.streetLine} ${selectedItem.secondary}`.trim();
        selected = `${search} (${selectedItem.entries}) ${selectedItem.city} ${selectedItem.state} ${selectedItem.zipcode}`;
      }
      const result = await smartyLookup(search, selected);

      if (!(result instanceof Error) && result.length > 0) {
        setAddressSuggestions(result);
      } else {
        // if no results, automatically highlight the manual address entry item
        setAddressSuggestions(undefined);
        setHighlightedIndex(0);
      }

      setIsLoading(false);
    };
  }, [setHighlightedIndex]);

  // setup memoized debounce on initial render, ensure in-flight lookup is canceled when unmounted
  const debouncedLookup = useMemo(
    () => debounce(handleSmartyLookup(), 300, { leading: true }),
    [handleSmartyLookup],
  );
  useEffect(() => debouncedLookup.cancel(), [debouncedLookup]);

  return (
    <div className="relative" ref={containerRef}>
      <div className="relative">
        <LabelledInput
          labelProps={{
            attributes: getLabelProps(),
            text: labelText,
            accessory: (
              <div
                className={disabled ? "cursor-default" : "cursor-pointer"}
                onClick={() => !disabled && setManualEntry()}
              >
                <Text className="text-[10px] text-neutral-400">
                  Enter manually
                </Text>
              </div>
            ),
          }}
          disabled={disabled}
          isValid={isValid}
          {...getInputProps({ ref: inputRef })}
          data-testid={`${inputName ? inputName + ":" : ""}combobox-input`}
          name={inputName}
          maxLength={255}
          placeholder={placeholder}
        />
        {isLoading && (
          <div className="absolute right-2 top-0 flex h-full items-center align-middle opacity-30">
            <FontAwesomeIcon
              icon={icon({ name: "loader" })}
              size="lg"
              className="fa-spin"
            />
          </div>
        )}
      </div>
      <AnimatePresence>
        <motion.div
          className={
            "fixed z-20 mt-1.5 max-h-96 overflow-auto rounded-2xl border bg-white p-2 text-sm shadow-2xl dark:border-neutral-800 dark:bg-neutral-700"
          }
          style={{
            width: `${suggestionDialogWidth}px`,
            visibility: dialogIsOpen ? "visible" : "hidden",
          }}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          data-testid="combobox-list"
          {...getMenuProps()}
        >
          {addressSuggestions && addressSuggestions?.length > 0 ? (
            <ul>
              {addressSuggestions.map((item, index) => {
                const hasEntries = item.entries > 1;
                const lineAddress = [
                  item.streetLine,
                  hasEntries ? "" : item.secondary,
                ]
                  .filter((addressComponent) => addressComponent)
                  .join(" ");
                const entries = hasEntries ? `(${item.entries} Units)` : "";

                return (
                  <li
                    className={twMerge(
                      "flex cursor-pointer items-center rounded-xl px-4 py-2 transition dark:bg-neutral-700 dark:text-white",
                      highlightedIndex === index &&
                        "bg-neutral-200 dark:bg-neutral-600",
                    )}
                    key={`${index}-${item.streetLine}-${item.secondary}-${item.zipcode}`}
                    {...getItemProps({
                      item,
                      index,
                    })}
                    data-testid="combobox-list-item"
                  >
                    <>
                      {entries ? (
                        <div className="flex w-full flex-col">
                          <div>
                            {lineAddress} {entries}
                          </div>
                          <div>
                            {item.city}, {item.state}
                          </div>
                        </div>
                      ) : (
                        <div className="w-full">
                          {lineAddress}, {item.city}, {item.state}
                        </div>
                      )}
                      {hasEntries && (
                        <FontAwesomeIcon
                          icon={icon({ name: "chevron-right" })}
                        />
                      )}
                    </>
                  </li>
                );
              })}
            </ul>
          ) : (
            <>
              <div
                className="mb-1 flex cursor-default items-center border-b px-4 py-2 text-black/50 transition dark:border-b-neutral-600 dark:text-white"
                key="combobox-fallback-item"
                data-testid="combobox-fallback-item"
              >
                No address found
              </div>
              <div
                className={twMerge(
                  "flex cursor-pointer items-center rounded-xl px-4 py-2 transition dark:bg-neutral-700 dark:text-white",
                  highlightedIndex === 0 &&
                    "bg-neutral-200 dark:bg-neutral-600",
                )}
                key="combobox-manual-entry"
                data-testid="combobox-manual-entry"
                {...getItemProps({ item: emptyStateItem, index: 0 })}
              >
                Enter address manually
              </div>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
