import {
  easeInOut,
  motion,
  useMotionValue,
  useMotionValueEvent,
  Variants,
} from "framer-motion";
import { PropsWithChildren, useCallback } from "react";

type AnimationContainerProps = {
  /** An optional callback for when the wrapped animation is complete. */
  onAnimationComplete?: () => void;
};

const variants: Record<string, Variants> = {
  fadeInOut: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  },
  slideInFromRight: {
    hidden: { opacity: 0, x: -24 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 24 },
  },
  fadeOutDown: {
    hidden: { opacity: 0, y: 8 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -8 },
  },
};

const transitions = {
  quick: {
    ease: easeInOut,
    duration: 0.25,
  },
  debugTransition: {
    duration: 1,
    ease: easeInOut,
  },
};

const AnimationContainer = ({
  children,
  onAnimationComplete = () => {},
}: PropsWithChildren<AnimationContainerProps>) => {
  const opacity = useMotionValue(0);

  const dispatchCompleteEvent = useCallback(() => {
    setTimeout(onAnimationComplete, 300);
  }, [onAnimationComplete]);

  useMotionValueEvent(opacity, "animationComplete", dispatchCompleteEvent);

  return (
    <motion.div
      variants={variants.fadeInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={transitions.quick}
      className="flex h-full flex-grow flex-col"
      style={{ opacity }}
    >
      {children}
    </motion.div>
  );
};

export default AnimationContainer;
