import { createContext } from "react";
import { OnboardingAppRenderContext, OnboardingContextValue } from "../types";

export const OnboardingContext = createContext<OnboardingContextValue>({
  appReady: false,
  updateUser: () => {},
  user: { theme: "default" },
  returnToTransfer: () => {},
  configuration: {} as OnboardingContextValue["configuration"],
  renderContext: OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER,
});
