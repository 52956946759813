import type { Suggestion } from "../components/Address";

/**
 * Parse a Smarty Suggestion into a consistently formatted address string.
 */
export const parseSuggestion = (suggestion?: Suggestion | null): string => {
  if (!suggestion) return "";

  return [
    `${suggestion?.streetLine} ${suggestion?.secondary}`,
    suggestion?.city,
    `${suggestion?.state} ${suggestion?.zipcode}`,
  ]
    .map((component) => component.trim())
    .filter((component) => component.length > 0)
    .join(", ");
};
