export const truncateEmailAddress = (
  emailAddress: string,
  /** The maximum allowed length of the shortened string _including_ the `@` */
  maxLength: number = 24,
) => {
  if (emailAddress.length <= maxLength) {
    return emailAddress;
  }

  const [address, domain] = emailAddress.split("@");

  const truncatedAddress =
    address.length > 6 ? `${address.slice(0, 9)}...` : address;

  if (truncatedAddress.length + domain.length + 1 <= maxLength) {
    return `${truncatedAddress}@${domain}`;
  }

  const maxTruncatedDomainLength = maxLength - 1 - truncatedAddress.length;

  if (domain.length > maxTruncatedDomainLength) {
    const truncatedDomain = domain
      .slice(-(maxTruncatedDomainLength - 3))
      .replace(/^\./, "");

    return `${truncatedAddress}@...${truncatedDomain}`;
  }

  return `${truncatedAddress}@${domain}`;
};
