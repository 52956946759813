import { Asset, CAIPAsset, CryptoAsset, Network } from "../types";

export type ExchangeRate = {
  rate: string;
  quote: string;
  base: string;
  [key: string]: unknown;
};

export function assetFromCAIPAsset(asset: string): Asset {
  switch (asset) {
    case CAIPAsset.FIAT_USD:
      return Asset.USD;
    case CAIPAsset.ETHEREUM_MAINNET_USDC:
    case CAIPAsset.ETHEREUM_SEPOLIA_USDC:
    case CAIPAsset.SOLANA_MAINNET_USDC:
    case CAIPAsset.SOLANA_DEVNET_USDC:
    case CAIPAsset.OP_MAINNET_USDC:
    case CAIPAsset.OP_SEPOLIA_USDC:
    case CAIPAsset.POLYGON_MAINNET_USDC:
    case CAIPAsset.POLYGON_AMOY_USDC:
    case CAIPAsset.ARBITRUM_ONE_USDC:
    case CAIPAsset.ARBITRUM_SEPOLIA_USDC:
    case CAIPAsset.BASE_MAINNET_USDC:
    case CAIPAsset.BASE_SEPOLIA_USDC:
      return Asset.USDC;
    case CAIPAsset.ETHEREUM_MAINNET_ETH:
    case CAIPAsset.ETHEREUM_SEPOLIA_ETH:
    case CAIPAsset.OP_MAINNET_ETH:
    case CAIPAsset.OP_SEPOLIA_ETH:
    case CAIPAsset.ARBITRUM_ONE_ETH:
    case CAIPAsset.ARBITRUM_SEPOLIA_ETH:
    case CAIPAsset.BASE_MAINNET_ETH:
    case CAIPAsset.BASE_SEPOLIA_ETH:
      return Asset.ETH;
    case CAIPAsset.SOLANA_MAINNET_SOL:
    case CAIPAsset.SOLANA_DEVNET_SOL:
      return Asset.SOL;
    case CAIPAsset.POLYGON_MAINNET_MATIC:
    case CAIPAsset.POLYGON_AMOY_MATIC:
      return Asset.POL;
    case CAIPAsset.BITCOIN_MAINNET_BTC:
    case CAIPAsset.BITCOIN_TESTNET_BTC:
      return Asset.BTC;
    default:
      throw new Error(`Cannot parse asset from CAIPAsset ${asset}`);
  }
}

export function cryptoAssetFromExchangeRate(exchangeRate: ExchangeRate): Asset {
  // Attempt to parse asset from base or quote assets in order
  for (const asset of [exchangeRate.base, exchangeRate.quote]) {
    try {
      const mesoJSAsset = assetFromCAIPAsset(asset);
      if (mesoJSAsset in CryptoAsset) return mesoJSAsset;
    } catch {} // Ignore failure and try the next asset
  }

  // If parsing both base and quote CAIPAssets fail, throw an error
  throw new Error(
    `Cannot parse asset from exchange rate ${JSON.stringify({
      base: exchangeRate.base,
      quote: exchangeRate.quote,
    })}`,
  );
}

export function lookupAsset(
  network: Network,
  asset: Asset,
  useTestnets: boolean = false,
): CAIPAsset {
  const error = `Unknown asset for ${network} / ${asset}`;

  if (asset === Asset.USD) {
    return CAIPAsset.FIAT_USD;
  }

  switch (network) {
    case Network.ETHEREUM_MAINNET:
      switch (asset) {
        case Asset.USDC:
          return useTestnets
            ? CAIPAsset.ETHEREUM_SEPOLIA_USDC
            : CAIPAsset.ETHEREUM_MAINNET_USDC;
        case Asset.ETH:
          return useTestnets
            ? CAIPAsset.ETHEREUM_SEPOLIA_ETH
            : CAIPAsset.ETHEREUM_MAINNET_ETH;
        default:
          throw new Error(error);
      }

    case Network.SOLANA_MAINNET:
      switch (asset) {
        case Asset.USDC:
          return useTestnets
            ? CAIPAsset.SOLANA_DEVNET_USDC
            : CAIPAsset.SOLANA_MAINNET_USDC;
        case Asset.SOL:
          return useTestnets
            ? CAIPAsset.SOLANA_DEVNET_SOL
            : CAIPAsset.SOLANA_MAINNET_SOL;
        default:
          throw new Error(error);
      }

    case Network.ARBITRUM_MAINNET:
      switch (asset) {
        case Asset.USDC:
          return useTestnets
            ? CAIPAsset.ARBITRUM_SEPOLIA_USDC
            : CAIPAsset.ARBITRUM_ONE_USDC;
        case Asset.ETH:
          return useTestnets
            ? CAIPAsset.ARBITRUM_SEPOLIA_ETH
            : CAIPAsset.ARBITRUM_ONE_ETH;
        default:
          throw new Error(error);
      }

    case Network.OP_MAINNET:
      switch (asset) {
        case Asset.USDC:
          return useTestnets
            ? CAIPAsset.OP_SEPOLIA_USDC
            : CAIPAsset.OP_MAINNET_USDC;
        case Asset.ETH:
          return useTestnets
            ? CAIPAsset.OP_SEPOLIA_ETH
            : CAIPAsset.OP_MAINNET_ETH;
        default:
          throw new Error(error);
      }

    case Network.POLYGON_MAINNET:
      switch (asset) {
        case Asset.USDC:
          return useTestnets
            ? CAIPAsset.POLYGON_AMOY_USDC
            : CAIPAsset.POLYGON_MAINNET_USDC;
        case Asset.MATIC:
        case Asset.POL:
          return useTestnets
            ? CAIPAsset.POLYGON_AMOY_MATIC
            : CAIPAsset.POLYGON_MAINNET_MATIC;
        default:
          throw new Error(error);
      }

    case Network.BITCOIN_MAINNET:
      switch (asset) {
        case Asset.BTC:
          return useTestnets
            ? CAIPAsset.BITCOIN_TESTNET_BTC
            : CAIPAsset.BITCOIN_MAINNET_BTC;
        default:
          throw new Error(error);
      }

    case Network.BASE_MAINNET:
      switch (asset) {
        case Asset.USDC:
          return useTestnets
            ? CAIPAsset.BASE_SEPOLIA_USDC
            : CAIPAsset.BASE_MAINNET_USDC;
        case Asset.ETH:
          return useTestnets
            ? CAIPAsset.BASE_SEPOLIA_ETH
            : CAIPAsset.BASE_MAINNET_ETH;
        default:
          throw new Error(error);
      }
  }
}

export const truncateAmount = (asset: Asset, amount?: string): string => {
  const maxAmountDigits = 5;
  let newAmount = "0";

  if (amount && (asset === Asset.USD || asset === Asset.USDC)) {
    newAmount = Number(amount).toFixed(2);
  } else if (amount) {
    const number = Number(amount);
    const numWholeNumberDigits =
      Math.max(Math.floor(Math.log10(Math.abs(number))), 0) + 1;
    newAmount = number.toFixed(
      Math.max(maxAmountDigits - numWholeNumberDigits, 0),
    );
  }

  return newAmount;
};
