import { ThemeName } from "../../style/themes";
import { mapValues } from "../../utils/animation";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const AnimatedBackground = ({ theme }: { theme: ThemeName }) => {
  const classes = "overflow-hidden rounded-ts-card animated-background";
  return (
    <div className={classes}>
      <Background theme={theme} />
    </div>
  );
};

const Background = ({ theme }: { theme: ThemeName }) => {
  switch (theme) {
    case "pulse":
      return <Pulse />;
    case "waves":
      return <Waves />;
    case "amplitude":
      return <Amplitude />;
    case "neon":
      return <NeonRain />;
  }
};

const PULSE_PULSES = 4;
const Pulse = () => {
  return (
    <div className="-z-1 absolute -bottom-24 left-0 right-0 flex h-full w-full items-center justify-center">
      {[...Array(PULSE_PULSES).keys()].map((pulse, i) => {
        const opacity = mapValues(pulse, 0, PULSE_PULSES, 0.1, 0.24);
        const size = mapValues(pulse, 0, PULSE_PULSES, 72, 340);
        return (
          <motion.div
            key={i}
            initial={{ opacity: 0 }}
            animate={{
              opacity,
              scale: [1, 1.5],
              y: pulse * 4,
            }}
            transition={{
              type: "spring",
              duration: 3,
              delay: pulse * 0.5,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            style={{
              width: size,
              height: size,
            }}
            className="absolute rounded-full border bg-violet-400 dark:border-violet-200"
          />
        );
      })}
    </div>
  );
};

const Waves = () => {
  return (
    <div className="absolute left-8 flex h-full w-full items-center justify-center">
      {[...Array(4).keys()].map((wave, i) => (
        <motion.div
          initial={{ rotate: 8 * wave }}
          animate={{
            rotate: 180,
            transition: {
              duration: 10 * wave,
              repeat: Infinity,
              repeatType: "reverse",
            },
          }}
          key={i}
          className="absolute top-24 h-[1500px] w-[1500px] rotate-45 rounded-[24rem] bg-white opacity-10"
        />
      ))}
    </div>
  );
};

const AMPLITUDE_WAVES = 24;
const Amplitude = () => {
  return (
    <div className="absolute left-0 h-full w-full">
      {[...Array(AMPLITUDE_WAVES).keys()].map((wave, i) => {
        const x = (500 / AMPLITUDE_WAVES) * wave;

        return (
          <motion.svg
            key={i}
            width="774"
            height="372"
            viewBox="0 0 774 372"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute -top-[2.5%] left-0 h-[105%]"
            style={{ transform: `translateX(-${x}px)` }}
          >
            <motion.path
              custom={wave}
              initial={{ pathLength: 0, opacity: 0 }}
              animate={{
                pathLength: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  duration: 3.5,
                  delay: 0.1 * wave,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              d="M0 1.2002C204.882 1.2002 126.471 370.8 387 370.8C647.529 370.8 617.176 1.2002 774 1.2002"
              stroke="url(#paint0_linear_2039_16804)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2039_16804"
                x1="387"
                y1="2"
                x2="387"
                y2="370.8"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#C2C2C2" />
                <stop offset="1" stopColor="#3A3A3A" />
              </linearGradient>
            </defs>
          </motion.svg>
        );
      })}
    </div>
  );
};

const NEON_RAIN_LASERS = 15;
const NEON_RAIN_COLORS = ["EA40F8", "#8F5DF9", "#26C1F3"];
const NeonRain = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [xPositions, setXPositions] = useState<number[]>([]);

  useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }, []);

  // When size updates, set random x positions for all lasers
  useEffect(() => {
    const newXPositions = Array(NEON_RAIN_LASERS)
      .fill(0)
      .map(() => Math.floor(Math.random() * size.width));
    setXPositions(newXPositions);
  }, [size.width]);

  return (
    <div ref={containerRef} className="absolute inset-0 z-20">
      {[...Array(NEON_RAIN_LASERS).keys()].map((laser, i) => {
        const color =
          NEON_RAIN_COLORS[Math.floor(Math.random() * NEON_RAIN_COLORS.length)];
        const background = `linear-gradient(0deg, ${color} 0%, ${color}00 100%)`;
        const animationDuration = `${Math.random() * (8 - 3) + 1}s`;
        const animationDelay = `${Math.random() * (8 - 3) + 0.5}s`;
        const xPos = xPositions[laser];

        return (
          <div
            key={i}
            className="laser-container"
            style={{ animationDuration, animationDelay }}
          >
            <div
              style={{
                left: xPos,
                background,
              }}
              className="laser"
            />
            <div
              style={{
                left: xPos,
                background,
                filter: "blur(2px)",
              }}
              className="laser"
            />
            <div
              style={{
                left: xPos,
                background,
                filter: "blur(4px)",
              }}
              className="laser"
            />
          </div>
        );
      })}
    </div>
  );
};

export default AnimatedBackground;
