import { Sentry } from "../utils/telemetry";
import { createPostMessageBus } from "@meso-network/meso-js";
import { parseConfiguration } from "@src/configuration";
import { IntegrationMode, MessageKind, PostMessageBus } from "@src/types";
import {
  getTargetWindowOrigin,
  getWebViewWindowHandle,
} from "../utils/browser";
import { ErrorMessages } from "@tigris/mesokit";
import { useMemo } from "react";

type UseInitializationProps = {
  /**
   * URLSearchParams instance that will be parsed into configuration.
   */
  configurationParams: URLSearchParams;

  mode: IntegrationMode;
};

export const useInitialization = ({
  configurationParams,
  mode,
}: UseInitializationProps) => {
  return useMemo(() => {
    const bus = initializePostMessageBus(mode);

    return {
      bus,
      /** The qualified [origin](https://developer.mozilla.org/en-US/docs/Web/API/Location/origin) for the Meso API. */
      apiOrigin: getApiOrigin(),
      configuration: parseConfigurationOrError(configurationParams, mode, bus),
    };
  }, [configurationParams, mode]);
};

const getApiOrigin = () => {
  const { VITE_GRAPHQL_ORIGIN } = import.meta.env;
  return VITE_GRAPHQL_ORIGIN && typeof VITE_GRAPHQL_ORIGIN === "string"
    ? VITE_GRAPHQL_ORIGIN
    : location.origin;
};

const parseConfigurationOrError = (
  queryParams: URLSearchParams,
  mode: IntegrationMode,
  bus?: PostMessageBus,
) => {
  // Attempt to parse the configuration via this window's query params
  const parsedConfigurationResult = parseConfiguration(queryParams, mode);

  if (!parsedConfigurationResult.isOk()) {
    bus?.emit(parsedConfigurationResult.error);

    if (
      parsedConfigurationResult.error.kind ===
        MessageKind.CONFIGURATION_ERROR ||
      parsedConfigurationResult.error.kind ===
        MessageKind.UNSUPPORTED_ASSET_ERROR
    ) {
      throw new Error(parsedConfigurationResult.error.payload.message);
    }

    throw new Error(ErrorMessages.GENERIC_ERROR);
  }

  return parsedConfigurationResult.value;
};

const initializePostMessageBus = (mode: IntegrationMode) => {
  if (mode === IntegrationMode.STANDALONE) {
    return;
  }

  // Our target origin for postmessage depends on the `mode` we are running in. So we set that up here.
  const postMessageBusTargetOrigin = getTargetWindowOrigin(mode);
  const postMessageBusConfiguration: Parameters<typeof createPostMessageBus> = [
    postMessageBusTargetOrigin,
  ];

  if (mode === IntegrationMode.WEBVIEW) {
    const getWebViewWindowHandleResult = getWebViewWindowHandle();

    if (getWebViewWindowHandleResult.isErr()) {
      Sentry.captureMessage("postMessageBusConfiguration for webView", {
        extra: {
          mode: IntegrationMode.WEBVIEW,
          postMessageBusConfiguration,
          error: getWebViewWindowHandleResult.error,
        },
        level: "warning",
      });
      throw new Error(getWebViewWindowHandleResult.error);
    }

    postMessageBusConfiguration.push(getWebViewWindowHandleResult.value);

    Sentry.captureMessage("postMessageBusConfiguration for webView", {
      extra: {
        mode: IntegrationMode.WEBVIEW,
        postMessageBusConfiguration,
      },
      level: "log",
    });
  }
  const bus = createPostMessageBus(...postMessageBusConfiguration);

  if (!bus || "message" in bus) {
    throw new Error(
      bus?.message ||
        ErrorMessages.initialization.MESSAGE_BUS_INITIALIZATION_ERROR,
    );
  }
  return bus as Readonly<PostMessageBus>;
};
