import { Toaster } from "sonner";
import "./styles.css";

type ToasterProps = Parameters<typeof Toaster>[0];

// https://sonner.emilkowal.ski/styling#tailwind-css
const DEFAULT_TOASTER_PROPS: Partial<ToasterProps> = {
  position: "top-center",
  toastOptions: {
    unstyled: true,
    classNames: {
      toast:
        "meso-toast-container md:w-full w-auto bg-white p-4 rounded-[24px] shadow-xl flex items-center gap-2 text-neutral-800 dark:text-white text-sm dark:bg-neutral-800",
      info: "[&>div>svg]:fill-primary [&>div>svg]:dark:fill-primary-light dark:text-white pl-[21px]",
      warning: "[&>div>svg]:fill-warning dark:text-white pl-[21px]",
      success: "[&>div>svg]:fill-highlight dark:text-white pl-[21px]",
      error: "[&>div>svg]:fill-danger pl-[21px]",
      closeButton: "close-button",
    },
  },
  closeButton: true,
};

export const MesoToaster = (props: ToasterProps) => {
  return <Toaster {...{ ...DEFAULT_TOASTER_PROPS, ...props }} />;
};
