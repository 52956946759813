import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { OnboardingContext } from "./OnboardingContext";
import { IntegrationMode, OnboardingContextValue } from "@src/types";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes as OnboardingRoutes } from "@tigris/onboarding";
import { Routes } from "@src/utils/constants";
import { toast } from "sonner";
import { nanoid } from "nanoid";
import { launchOnboardingWindow } from "@src/utils/launchOnboardingWindow";
import { useApp } from "@src/hooks/useApp";

const TOAST_ID = "OnboardingContextProvider";
const ONBOARDING_APP_ORIGIN = import.meta.env.VITE_ONBOARDING_APP_ORIGIN;

export const OnboardingContextProvider = ({ children }: PropsWithChildren) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    mode,
    partner,
    session,
    configuration: { network, walletAddress },
  } = useApp();

  const [onboardingInProgress, setOnboardingInProgress] = useState(false);
  const [cancelOnboarding, setCancelOnboarding] = useState<
    OnboardingContextValue["cancelOnboarding"]
  >(() => {});

  const initializeOnboarding = useCallback<
    OnboardingContextValue["initializeOnboarding"]
  >(
    (options) => {
      if (options?.initialPathname === OnboardingRoutes.ManualReview) {
        // Short-circuit and dead-end user in landing sheet
        navigate({ pathname: Routes.TransferUnavailable, search });
        return;
      }

      if (
        mode === IntegrationMode.STANDALONE ||
        mode === IntegrationMode.WEBVIEW
      ) {
        // Build up a navigation state object to pass to the onboarding route.
        let state = null;

        // If an `initialPathname` is provided, we pass it on navigation state so that we can inject it into Onboarding
        if (options?.initialPathname) {
          state = { initialPathname: options.initialPathname };
        }

        // If a `post2faOnboardingRoute` is provided, we pass it on navigation state so that we can inject it into Onboarding
        if (options?.post2faOnboardingRoute) {
          state = {
            ...state,
            post2faOnboardingRoute: options.post2faOnboardingRoute,
          };
        }

        navigate({ pathname: Routes.Onboarding, search }, { state });

        setOnboardingInProgress(true);
      } else {
        if (!session || !partner) {
          throw new Error("Invalid session.");
        }

        const params = options?.post2faOnboardingRoute
          ? `?${new URLSearchParams({ post2faOnboardingRoute: options.post2faOnboardingRoute }).toString()}`
          : "";
        const clientSessionId = nanoid(8);

        const onboardingWindowResult = launchOnboardingWindow({
          url: `${ONBOARDING_APP_ORIGIN}${options?.initialPathname ?? ""}${params}`.trim(),
          // Add a unique identifier to the window name to prevent reference collisions and the following browser error/warning
          // Unsafe attempt to initiate navigation for frame with URL '<onboarding_origin>' from frame with URL '<transfer_app_origin>'. The frame attempting navigation is neither same-origin with the target, nor is it the target's parent or opener.
          name: `Meso Onboarding ${clientSessionId}`,
          onReturnToTransfer: (reason) => {
            setOnboardingInProgress(false);

            switch (reason) {
              case "returnToLogin": {
                // A caller can provide an optional callback to override the default behavior for the `onReturnToTransfer` function.
                // If not provided, we will perform best-effort routing
                if (options?.onReturnToTransferLogin) {
                  options.onReturnToTransferLogin();
                } else {
                  navigate({ pathname: Routes.LandingSheetLoginEntry, search });
                }
                break;
              }

              case "onboardingComplete": {
                // A caller can provide an optional callback to override the default behavior for the `onReturnToTransfer` function.
                // If not provided, we will perform best-effort routing
                if (options?.onReturnToTransferOnboardingComplete) {
                  options.onReturnToTransferOnboardingComplete();
                } else {
                  navigate({ pathname: Routes.TransferSheet, search });
                }
                break;
              }

              case "onboardingTerminated": {
                // A caller can provide an optional callback to override the default behavior for the `onReturnToTransfer` function.
                // If not provided, we will perform best-effort routing
                if (options?.onReturnToTransferOnboardingTerminated) {
                  options.onReturnToTransferOnboardingTerminated();
                } else {
                  navigate({ pathname: Routes.TransferUnavailable, search });
                }
              }
            }
          },
          onRequestOnboardingConfiguration: () => ({
            partner,
            session: {
              id: session.id,
              token: session.token,
              isReturningUser: session.isReturningUser,
              passkeysEnabled: session.passkeysEnabled,
              riskSession: session.riskSession,
            },
            network,
            walletAddress,
          }),
        });

        if (onboardingWindowResult.isErr()) {
          toast.error(onboardingWindowResult.error, { id: TOAST_ID });
          return;
        }

        setCancelOnboarding(() => () => {
          onboardingWindowResult.value.close();

          setOnboardingInProgress(false);

          if (options?.onReturnToTransferOnboardingTerminated) {
            options.onReturnToTransferOnboardingTerminated();
          }

          setCancelOnboarding(() => {});
        });

        setOnboardingInProgress(true);
      }
    },
    [mode, navigate, network, partner, search, session, walletAddress],
  );

  const contextValue = useMemo(
    () => ({ initializeOnboarding, onboardingInProgress, cancelOnboarding }),
    [cancelOnboarding, initializeOnboarding, onboardingInProgress],
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};
