export const easeIn = [0.15, 0.1, 0.15, 0.75];
export const spring = {
  type: "spring",
  damping: 22,
  stiffness: 195,
};

/**
 * Ensures that input falls within min and max range
 */
function clamp(input: number, min: number, max: number): number {
  return input < min ? min : input > max ? max : input;
}

/**
 * Maps current value within input [min, max] range to output [min, max] range,
 * clamping the mapped current value if needed
 */
export function mapValues(
  current: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number,
): number {
  const mapped: number =
    ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  return clamp(mapped, out_min, out_max);
}
