import { Routes } from "@src/utils/constants";
import { motion } from "framer-motion";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "@src/contexts/AppContextProvider";
import { Copy } from "@src/utils/copy";
import { Amplitude, Sentry } from "@src/utils/telemetry";
import { spring } from "@src/utils/animation";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { OutletQuoteContext } from "./LandingAndTransferContainer";
import { toast } from "sonner";
import {
  ErrorMessages,
  DEFAULT_THEME,
  Button,
  ThemeName,
} from "@tigris/mesokit";
import {
  CashOutQuote,
  ExecuteTransferInput,
  FrontendDeclineReason,
  TransferKind,
  TransferStatus,
} from "@src/generated/sdk";
import {
  AssetAmount,
  MessageKind,
  AuthorizedCashInQuote,
  AuthorizedCashOutQuote,
  IntegrationMode,
  UseQuoteHook,
} from "@src/types";
import useTransfer, {
  TRANSFER_SUCCESS_STATUSES,
  TRANSFER_TERMINAL_STATUSES,
} from "@src/hooks/useTransfer";
import { useCheckout } from "@src/hooks/useCheckout";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { Routes as OnboardingRoutes } from "@tigris/onboarding";
import { useThreeDS } from "@src/hooks/useThreeDS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const isAuthorizedQuote = (quote: UseQuoteHook["quote"]): boolean => {
  return !!(
    quote &&
    ((quote as AuthorizedCashInQuote).jwt ||
      (quote as AuthorizedCashOutQuote).depositAddress)
  );
};

const TOAST_ID = "TransferSheet";
const REPLACE_CARD_TOAST_ID = "ReplaceCard";

export const TransferSheet = () => {
  const {
    useQuote: { quote, stop: stopPolling, restart: restartPolling },
  } = useOutletContext<OutletQuoteContext>();
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const {
    api: { resolveExecuteTransfer, resolveUser },
    configuration: { sourceAsset, destinationAsset, transferKind },
    session,
    transfer,
    setTransfer,
    bus,
    user: { theme, fiatInstruments },
    updateUser,
    isEditingAmount,
    mode,
    quoteLimitReached,
  } = useContext(AppContext);
  const { retrieveDeviceSessionId } = useCheckout();
  const { authenticateThreeDomainSecurePayment } = useThreeDS();
  const [
    executeTransferRequestIsInFlight,
    setExecuteTransferRequestIsInFlight,
  ] = useState(false);
  const { initializeOnboarding } = useOnboarding();
  const isCashIn = transferKind === TransferKind.CASH_IN;
  const needPayoutEligibleFiatInstrument =
    !isCashIn && !fiatInstruments?.collection?.find((fi) => fi.payoutEligible);
  useTransfer(transfer?.id);
  const [searchParams] = useSearchParams();
  const [rewardsProgram] = useState<string | null>(() =>
    searchParams.get("rewardsProgram"),
  );

  const executeTransfer = useCallback(async () => {
    if (
      !quote ||
      !isAuthorizedQuote(quote) ||
      executeTransferRequestIsInFlight
    ) {
      return;
    }

    Amplitude.track("Transfer Execute");
    const executeTransferStartTime = performance.now();

    setExecuteTransferRequestIsInFlight(true);
    stopPolling();

    if (isCashIn) {
      const authenticateThreeDomainSecureResult =
        await authenticateThreeDomainSecurePayment({
          quoteSourceAmount: `${Number(quote.sourceTotal.amount)}`,
        });
      if (authenticateThreeDomainSecureResult.isErr()) {
        Sentry.captureMessage(authenticateThreeDomainSecureResult.error, {
          level: "warning",
        });
        toast.error(ErrorMessages.transferSheet.EXECUTE_TRANSFER_API_ERROR, {
          id: TOAST_ID,
        });
        setExecuteTransferRequestIsInFlight(false);

        return;
      }

      const deviceSessionIdResult = await retrieveDeviceSessionId();

      const executeTransferInput: ExecuteTransferInput = {
        jwt: (quote as AuthorizedCashInQuote)!.jwt,
        riskSessionKey: session!.riskSession.sessionKey,
        ...(deviceSessionIdResult.isOk()
          ? { deviceSessionId: deviceSessionIdResult.value }
          : {}),
        ...(authenticateThreeDomainSecureResult.value
          ? { threeDomainSecure: authenticateThreeDomainSecureResult.value }
          : {}),
      };

      if (rewardsProgram) {
        executeTransferInput.rewardsProgram = rewardsProgram;
      }

      const executeTransferResult = await resolveExecuteTransfer({
        input: executeTransferInput,
      });

      if (executeTransferResult.isErr()) {
        toast.error(executeTransferResult.error, { id: TOAST_ID });
        setExecuteTransferRequestIsInFlight(false);

        return;
      }

      setTransfer(executeTransferResult.value);

      if (executeTransferResult.value.status === TransferStatus.APPROVED) {
        Sentry.metrics.distribution(
          "execute_transfer_approved",
          performance.now() - executeTransferStartTime,
          { unit: "millisecond" },
        );
      }

      navigate(
        { pathname: Routes.TransferSheetStatus, search },
        { state: { executeTransferStartTime } },
      );
    } else {
      const cashOutQuote = quote as CashOutQuote;
      bus!.emit({
        kind: MessageKind.REQUEST_SEND_TRANSACTION,
        payload: {
          amount: cashOutQuote.sourceTotal.amount as AssetAmount,
          recipientAddress: cashOutQuote.depositAddress,
          tokenAddress: cashOutQuote.sourceAsset.address,
          decimals: cashOutQuote.sourceAsset.decimals,
        },
      });
    }
  }, [
    authenticateThreeDomainSecurePayment,
    bus,
    executeTransferRequestIsInFlight,
    isCashIn,
    navigate,
    quote,
    resolveExecuteTransfer,
    retrieveDeviceSessionId,
    rewardsProgram,
    search,
    session,
    setTransfer,
    stopPolling,
  ]);

  useEffect(() => {
    restartPolling();
  }, [restartPolling]);

  useEffect(() => {
    if (state && "declineReason" in state) {
      switch (state.declineReason) {
        case FrontendDeclineReason.BANK_DECLINE:
          toast.error(
            ErrorMessages.transferStatus.TRANSFER_DECLINED_BANK_DECLINE_ERROR,
            { id: TOAST_ID },
          );
          break;
        case FrontendDeclineReason.CARD_EXPIRED:
          toast.error(
            <div
              className="flex items-center gap-2"
              data-testId="toast:cardExpiredError"
            >
              <div className="text-danger">
                <FontAwesomeIcon
                  icon={icon({ name: "circle-exclamation" })}
                  className="h-4 w-4"
                />
              </div>
              <div>
                Transfer declined. Your card is expired. Log in to the your{" "}
                <a
                  href="https://account.meso.network"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Meso account
                </a>{" "}
                to update your card and try again.,
              </div>
            </div>,
            { id: TOAST_ID },
          );
          break;
        default:
          toast.error(ErrorMessages.transferStatus.TRANSFER_DECLINED_ERROR, {
            id: TOAST_ID,
          });
      }

      return;
    }

    if (state && "error" in state && state.error === true) {
      toast.error(ErrorMessages.transferStatus.TRANSFER_DECLINED_ERROR, {
        id: TOAST_ID,
      });

      // An intentional artificial delay to accompany the transition into this view
      setTimeout(() => {
        restartPolling();
      }, 500);
    }
  }, [restartPolling, state]);

  useEffect(() => {
    if (needPayoutEligibleFiatInstrument) {
      toast(
        <div className="flex flex-col justify-center gap-2">
          {ErrorMessages.cashOutQuote.PAYOUT_INELIGIBLE_PAYMENT_CARD_ERROR}
          <Button
            containerClassName="h-8"
            onClick={() => {
              initializeOnboarding({
                initialPathname: OnboardingRoutes.AddPaymentCard,
              });
            }}
          >
            Replace Card
          </Button>
        </div>,
        { id: REPLACE_CARD_TOAST_ID },
      );
    }
  }, [
    initializeOnboarding,
    navigate,
    needPayoutEligibleFiatInstrument,
    search,
    transferKind,
  ]);

  useEffect(() => {
    // In the future, we can refactor this pattern to avoid an redundant lookup. More details here: https://linear.app/meso-network/issue/MESO-1614/refactor-quote-polling-in-transfer-app
    if (!isAuthorizedQuote(quote)) {
      const lookupUser = async () => {
        const resolveUserResult = await resolveUser();

        if (resolveUserResult.isOk()) {
          const user = resolveUserResult.value;

          if (
            user?.__typename === "User" &&
            user.fiatInstruments?.__typename === "FiatInstruments" &&
            user.walletInstruments?.__typename === "WalletInstruments" &&
            user.depositAddressInstruments?.__typename ===
              "DepositAddressInstruments"
          ) {
            updateUser({
              ...user,
              theme: user.theme as ThemeName,
              fiatInstruments: user.fiatInstruments,
              walletInstruments: user.walletInstruments,
              depositAddressInstruments: user.depositAddressInstruments,
            });
          } else {
            toast.error(ErrorMessages.common.LOOKUP_USER_API_ERROR, {
              id: TOAST_ID,
            });
          }
        } else {
          toast.error(resolveUserResult.error, { id: TOAST_ID });
        }
      };
      lookupUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      mode === IntegrationMode.STANDALONE &&
      transfer?.status &&
      (TRANSFER_TERMINAL_STATUSES.includes(transfer.status) ||
        TRANSFER_SUCCESS_STATUSES.includes(transfer.status))
    ) {
      // Clear the transfer so the user can try again
      setTransfer(undefined);
    }
  }, [mode, navigate, search, setTransfer, transfer]);

  useEffect(() => {
    const st = performance.now();

    setTimeout(() => {
      Sentry.metrics.distribution(
        "execute_transfer_approved",
        performance.now() - st,
        { unit: "millisecond" },
      );
    }, 500);
  }, []);

  return (
    <motion.div
      key="TransferSheet:content"
      initial={{ opacity: 0, x: 56 }}
      animate={{
        opacity: 1,
        x: 0,
        transition: { ...spring },
      }}
      exit={{ x: -56, opacity: 0 }}
    >
      <div data-testid="TransferSheet:content">
        <div className="text-fg-subtle py-2 text-[9px] leading-none">
          {Copy.executeTransfer.TRANSFER_NOTICE}
        </div>
        <Button
          disabled={
            quoteLimitReached ||
            isEditingAmount ||
            !session ||
            !isAuthorizedQuote(quote) ||
            executeTransferRequestIsInFlight ||
            needPayoutEligibleFiatInstrument
          }
          onClick={executeTransfer}
          isLoading={!quote || executeTransferRequestIsInFlight}
          themeOverride={
            theme === DEFAULT_THEME ? undefined : "transfer-button"
          }
        >
          {isCashIn ? `Buy ${destinationAsset}` : `Sell ${sourceAsset}`}
        </Button>
      </div>
    </motion.div>
  );
};
