import { Network } from "../types";
import { twMerge } from "tailwind-merge";
import { truncateAddress } from "@tigris/common";

export const WalletInstrument = ({
  network,
  walletAddress,
  className = "",
}: {
  network: Network;
  walletAddress?: string;
  className?: string;
}) => {
  className = twMerge("flex flex-auto gap-1 items-center h-full", className);

  return (
    <div data-testid="WalletInstrument" className={className}>
      <div className="relative">
        <svg
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
        >
          <path d="M1.5 0C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V1.901C0.455892 1.6374 0.973387 1.49906 1.5 1.5H10.5C11.046 1.5 11.559 1.646 12 1.901V1.5C12 1.10218 11.842 0.720644 11.5607 0.43934C11.2794 0.158035 10.8978 0 10.5 0H1.5ZM6 5C6.44431 5.00005 6.876 4.85215 7.22692 4.57963C7.57785 4.30711 7.82803 3.92549 7.938 3.495C8.006 3.227 8.224 3 8.5 3H10.5C10.8978 3 11.2794 3.15804 11.5607 3.43934C11.842 3.72064 12 4.10218 12 4.5V7.5C12 7.89782 11.842 8.27936 11.5607 8.56066C11.2794 8.84196 10.8978 9 10.5 9H1.5C1.10218 9 0.720644 8.84196 0.43934 8.56066C0.158035 8.27936 0 7.89782 0 7.5V4.5C0 4.10218 0.158035 3.72064 0.43934 3.43934C0.720644 3.15804 1.10218 3 1.5 3H3.5C3.776 3 3.994 3.227 4.062 3.495C4.17197 3.92549 4.42215 4.30711 4.77308 4.57963C5.124 4.85215 5.55569 5.00005 6 5Z" />
        </svg>
      </div>
      {walletAddress && (
        <span className="font-mono">
          {truncateAddress(walletAddress, network)}
        </span>
      )}
    </div>
  );
};
