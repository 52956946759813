import { AnimatePresence, Variants, motion } from "framer-motion";

const animationVariants: Variants = {
  initial: { opacity: 0, y: 4 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 4 },
};

const strengths = [
  "💩💩Very weak password",
  "💩 Weak password",
  "😬 Ok password",
  "💪 Strong password",
  "😎 Very strong password",
];

export const PasswordStrength = ({
  strength,
  validPasswordLength,
}: {
  strength: number;
  validPasswordLength: boolean;
}) => {
  return (
    <AnimatePresence>
      {validPasswordLength ? (
        <motion.div
          {...animationVariants}
          className="flex items-center text-xs text-neutral-800 dark:text-neutral-300"
        >
          {strengths[strength]}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
