import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Text } from "@tigris/mesokit";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { usePasskey } from "@src/hooks/usePasskey";
import { useCallback, useContext, useState } from "react";
import { toast } from "sonner";
import { AppContext } from "@src/contexts/AppContextProvider";
import { spring } from "@src/utils/animation";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
  initial: { opacity: 0, scale: 0.85 },
  animate: {
    opacity: 1,
    scale: [0.85, 5, 1],
    transition: spring,
  },
  exit: {
    opacity: 0,
    scale: 0.85,
    transition: spring,
  },
};

const TOAST_ID = "PasskeyRegistrationCard";

export const PasskeyRegistrationCard = () => {
  const { hasPasskey } = useContext(AppContext);
  const { registerPasskey } = usePasskey();
  const [registrationInProgress, setRegistrationInProgress] = useState(false);

  const onHandleClick = useCallback(() => {
    setRegistrationInProgress(true);
    (async () => {
      const result = await registerPasskey();
      if (result.isErr()) {
        toast.error(result.error, { id: TOAST_ID });
        setRegistrationInProgress(false);
      }
    })();
  }, [registerPasskey]);

  return (
    <div
      key="PasskeyRegistrationCard"
      data-testid="PasskeyRegistrationCard"
      className="h-[9rem] rounded-3xl bg-white p-4 shadow-lg dark:bg-neutral-800"
    >
      <AnimatePresence mode="wait">
        {hasPasskey ? (
          <motion.div
            data-testid="PasskeyRegistrationCardPasskeyAdded"
            key="PasskeyRegistrationCardPasskeyAdded"
            variants={variants}
            className="flex h-full w-full flex-col items-center justify-center gap-2 text-center"
          >
            <div className="relative">
              <FontAwesomeIcon
                icon={icon({ name: "fingerprint", style: "light" })}
                className="text-primary dark:text-primary-light h-8 w-8"
              />
              <FontAwesomeIcon
                icon={icon({ name: "check-circle" })}
                className="text-highlight absolute right-0 top-0 z-10 h-4 w-4 -translate-y-[calc(50%)] translate-x-[calc(50%)]"
              />
            </div>
            <Text className="font-bold">Passkey Added</Text>
          </motion.div>
        ) : (
          <motion.div
            data-testid="PasskeyRegistrationCardPrompt"
            key="PasskeyRegistrationCardPrompt"
            variants={variants}
            className="flex flex-col items-center justify-center gap-2 text-center"
          >
            <div className="flex flex-col items-center gap-1">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon
                  icon={icon({ name: "fingerprint", style: "light" })}
                  className="text-primary dark:text-primary-light h-6 w-6"
                />
                <Text className="font-bold">Add a Passkey</Text>
                <Text className="rounded bg-gradient-to-b from-[#F9C84A] to-[#E5A604] px-1 py-[2px] text-xs font-extrabold uppercase tracking-tighter text-[#481703] dark:text-[#481703]">
                  New
                </Text>
              </div>
              <Text className="text-sm">
                Passkeys offer enhanced security and make logging in a breeze.
              </Text>
            </div>
            <Button
              className="h-8 w-40 text-xs font-bold"
              disabled={registrationInProgress}
              isLoading={registrationInProgress}
              onClick={onHandleClick}
            >
              Add Passkey
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
