// This is a Meso-specific implementation of `react-otp-input`.
// We needed specific functionality that was unavailable in the library
// Based on: https://github.com/devfolioco/react-otp-input/blob/90a7b4f8a93e0209dcfe48d5f95706200241a570/src/index.tsx

import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  Fragment,
} from "react";

export type AllowedInputTypes = "password" | "text" | "number" | "tel";

export type InputProps = Required<
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    | "value"
    | "onChange"
    | "onFocus"
    | "onBlur"
    | "onKeyDown"
    | "onPaste"
    | "aria-label"
    | "autoComplete"
    | "inputMode"
    | "onInput"
  > & {
    ref: React.RefCallback<HTMLInputElement>;
    className: string | undefined;
    type: AllowedInputTypes;
    "data-testid": string;
  }
>;

export interface OTPInputProps {
  /** Value of the OTP input */
  value?: string;
  /** Number of OTP inputs to be rendered */
  numInputs?: number;
  /** Callback to be called when the OTP value changes */
  onChange: (otp: string) => void;
  /** Callback to be called when pasting content into the component */
  onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  /** Function to render the input */
  renderInput: (inputProps: InputProps, index: number) => React.ReactNode;
  /** Placeholder for the inputs */
  placeholder?: string;
  /** Style for the container */
  //   containerStyle?: React.CSSProperties | string;
  /** Style for the input */
  inputStyle?: React.CSSProperties | string;
  /** The type that will be passed to the input being rendered */
  inputType?: AllowedInputTypes;
  /** A className string. */
  containerClassName?: string;
}

export type OTPInputRef = {
  /** Set focus on the first input */
  focus: () => void;
  /** Clear all inputs. */
  clear: () => void;
};

export const OTPInput = forwardRef<OTPInputRef, OTPInputProps>(
  (
    {
      value = "",
      numInputs = 6,
      onChange,
      onPaste,
      renderInput,
      inputType = "tel",
      containerClassName,
      inputStyle,
    }: OTPInputProps,
    ref,
  ) => {
    const [activeInput, setActiveInput] = useState(0);
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    const getOTPValue = () => (value ? value.toString().split("") : []);

    const isInputNum = inputType === "number" || inputType === "tel";

    useEffect(() => {
      inputRefs.current = inputRefs.current.slice(0, numInputs);
    }, [numInputs]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRefs.current[0]?.focus();
      },
      clear: () => {
        if (inputRefs.current) {
          for (let i = 0; i < inputRefs.current.length; i++) {
            if (inputRefs.current[i]) {
              inputRefs.current[i]!.value = "";
            }
          }
          inputRefs.current[0]?.focus();
        }
        onChange("");
      },
    }));

    const isInputValueValid = (value: string) => {
      const isTypeValid = isInputNum
        ? !isNaN(Number(value))
        : typeof value === "string";
      return isTypeValid && value.trim().length === 1;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (isInputValueValid(value)) {
        changeCodeAtFocus(value);
        focusInput(activeInput + 1);
      }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { nativeEvent } = event;
      const value = event.target.value;

      if (!isInputValueValid(value)) {
        // Pasting from the native autofill suggestion on a mobile device can pass
        // the pasted string as one long input to one of the cells. This ensures
        // that we handle the full input and not just the first character.
        if (value.length === numInputs) {
          const hasInvalidInput = value
            .split("")
            .some((cellInput) => !isInputValueValid(cellInput));
          if (!hasInvalidInput) {
            handleOTPChange(value.split(""));
            focusInput(numInputs - 1);
          }
        }

        if (
          // @ts-expect-error - This was added previously to handle and edge case for dealing with keyCode "229 Unidentified" on Android. Check if this is still needed.
          nativeEvent.data === null &&
          // @ts-expect-error - See above
          nativeEvent.inputType === "deleteContentBackward"
        ) {
          event.preventDefault();
          changeCodeAtFocus("");
          focusInput(activeInput - 1);
        }

        // Clear the input if it's not valid value because firefox allows
        // pasting non-numeric characters in a number type input
        event.target.value = "";
      }
    };

    const handleFocus =
      (event: React.FocusEvent<HTMLInputElement>) => (index: number) => {
        setActiveInput(index);
        event.target.select();
      };

    const handleBlur = () => {
      setActiveInput(activeInput - 1);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const otp = getOTPValue();
      if ([event.code, event.key].includes("Backspace")) {
        event.preventDefault();
        changeCodeAtFocus("");
        focusInput(activeInput - 1);
      } else if (event.code === "Delete") {
        event.preventDefault();
        changeCodeAtFocus("");
      } else if (event.code === "ArrowLeft") {
        event.preventDefault();
        focusInput(activeInput - 1);
      } else if (event.code === "ArrowRight") {
        event.preventDefault();
        focusInput(activeInput + 1);
      }
      // React does not trigger onChange when the same value is entered
      // again. So we need to focus the next input manually in this case.
      else if (event.key === otp[activeInput]) {
        event.preventDefault();
        focusInput(activeInput + 1);
      } else if (
        event.code === "Spacebar" ||
        event.code === "Space" ||
        event.code === "ArrowUp" ||
        event.code === "ArrowDown"
      ) {
        event.preventDefault();
      }
    };

    const focusInput = (index: number) => {
      const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

      if (inputRefs.current[activeInput]) {
        inputRefs.current[activeInput]?.focus();
        inputRefs.current[activeInput]?.select();
        setActiveInput(activeInput);
      }
    };

    const changeCodeAtFocus = (value: string) => {
      const otp = getOTPValue();
      otp[activeInput] = value[0];
      handleOTPChange(otp);
    };

    const handleOTPChange = (otp: Array<string>) => {
      const otpValue = otp.join("");

      onChange(otpValue);
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();

      const otp = getOTPValue();
      let nextActiveInput = activeInput;

      // Get pastedData in an array of max size (num of inputs - current position)
      const pastedData = event.clipboardData
        .getData("text/plain")
        .slice(0, numInputs - activeInput)
        .split("");

      // Prevent pasting if the clipboard data contains non-numeric values for number inputs
      if (isInputNum && pastedData.some((value) => isNaN(Number(value)))) {
        return;
      }

      // Paste data from focused input onwards
      for (let pos = 0; pos < numInputs; ++pos) {
        if (pos >= activeInput && pastedData.length > 0) {
          otp[pos] = pastedData.shift() ?? "";
          nextActiveInput++;
        }
      }

      focusInput(nextActiveInput);
      handleOTPChange(otp);
    };

    return (
      <div className={containerClassName ?? ""} onPaste={onPaste}>
        {Array.from({ length: numInputs }, (_, index) => index).map((index) => (
          <Fragment key={index}>
            {renderInput(
              {
                value: getOTPValue()[index] ?? "",
                ref: (element) => (inputRefs.current[index] = element),
                onChange: handleChange,
                onFocus: (event) => handleFocus(event)(index),
                onBlur: handleBlur,
                onKeyDown: handleKeyDown,
                onPaste: handlePaste,
                autoComplete: "off",
                "aria-label": `Please enter OTP character ${index + 1}`,
                className:
                  typeof inputStyle === "string" ? inputStyle : undefined,
                type: inputType,
                inputMode: isInputNum ? "numeric" : "text",
                onInput: handleInputChange,
                "data-testid": `otp-input-${index + 1}`,
              },
              index,
            )}
          </Fragment>
        ))}
      </div>
    );
  },
);
