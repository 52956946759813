import { AppContext } from "@src/contexts/AppContext";
import { themeCollection, ThemeName } from "@tigris/mesokit";
import { useCallback, useContext } from "react";

export const StandaloneDevControls = () => {
  const {
    user: { theme },
    updateUser,
  } = useContext(AppContext);

  const handleThemeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      updateUser({ theme: event.target.value as ThemeName });
    },
    [updateUser],
  );

  // If we aren't in dev, don't show the controls. Using this pattern, the component will be excluded from the production build
  if (!import.meta.env.DEV) return;

  return (
    <div className="fixed bottom-4 left-4 rounded-xl bg-black/10 p-4 text-xs dark:bg-black/60">
      <div className="mb-4 rounded-xl bg-yellow-400 px-3 py-2 text-xs font-bold dark:bg-yellow-500">
        ⚠️ Development Mode Only
      </div>

      <div>
        <div className="mb-1 font-bold dark:text-white">Select theme:</div>

        <select
          name="theme"
          id="theme"
          value={theme}
          onChange={handleThemeChange}
          className="rounded-xl px-2 py-1 dark:bg-neutral-800 dark:text-white"
        >
          {Object.keys(themeCollection).map((theme) => (
            <option key={theme} value={theme}>
              {theme}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
