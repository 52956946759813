import { AppContext } from "@src/contexts/AppContextProvider";
import { AuthenticationStrategy } from "@src/types";
import { Routes } from "@src/utils/constants";
import { PropsWithChildren, useContext } from "react";

import { Navigate, useLocation } from "react-router-dom";

export const ResolveLandingView = ({ children }: PropsWithChildren) => {
  const { configuration } = useContext(AppContext);
  const { search } = useLocation();

  if (
    configuration.authenticationStrategy ===
    AuthenticationStrategy.BYPASS_WALLET_VERIFICATION
  ) {
    return (
      <Navigate to={{ pathname: Routes.LandingSheetLoginLanding, search }} />
    );
  }

  return children;
};
