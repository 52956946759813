import { Button, Text } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { truncateAddress } from "@tigris/common";
import { useRouter } from "../hooks/useRouter";
import { useOnboarding } from "../hooks/useOnboarding";
import Heading from "./Heading";
import AnimationContainer from "./AnimationContainer";
import { Routes } from "../types";
import { Sentry } from "../utils/telemetry";
import { toast } from "sonner";

export const ConnectedWallet = () => {
  const { navigate, currentRoute } = useRouter();
  const { configuration } = useOnboarding();

  // We have to safely assume that `state` is a valid `NavigateConfig` (see types in authFlow.ts)
  const onClick = useCallback(() => {
    if (
      currentRoute.state &&
      "nextRoute" in currentRoute.state &&
      typeof currentRoute.state.nextRoute === "string"
    ) {
      navigate(currentRoute.state.nextRoute as Routes);
    } else {
      Sentry.captureException("Failed to find next route", {
        extra: {
          ["currentRoute.state"]: JSON.stringify(currentRoute.state),
        },
      });

      toast.error("Something went wrong. Please contact support.");
    }
  }, [currentRoute.state, navigate]);
  const [showFullAddress, setShowFullAddress] = useState(false);

  return (
    <AnimationContainer>
      <div id="OnboardingWallet" className="flex h-full flex-col gap-2">
        <Heading
          title="Connected Wallet"
          subtitle="
          This address will be linked to your account. You can connect more
          addresses later.
        "
        />

        <div className="dark:border-ts-subtle mb-2 mt-4 flex flex-col justify-between gap-2 overflow-hidden rounded-xl border border-neutral-700/10 p-3 shadow-lg">
          <div className="flex flex-col gap-1">
            <div className="flex h-6 items-center">
              <FontAwesomeIcon
                icon={icon({ name: "wallet" })}
                size="sm"
                className="text-primary dark:text-primary-light mr-1"
              />
              <Text className="font-bold opacity-80">Wallet Address</Text>

              <FontAwesomeIcon
                icon={icon({
                  name: "circle-chevron-right",
                })}
                onClick={() => setShowFullAddress(!showFullAddress)}
                className={`${
                  showFullAddress ? "rotate-90" : "rotate-0"
                } ml-auto mr-1 cursor-pointer opacity-60 transition-transform`}
              />
            </div>
            <div className="flex items-start text-wrap break-all font-mono text-sm">
              <AnimatePresence mode="wait">
                {showFullAddress ? (
                  <motion.div
                    key="FullAddress"
                    layout
                    initial={{ opacity: 0, height: "1rem" }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: "1rem" }}
                  >
                    {configuration.walletAddress}
                  </motion.div>
                ) : (
                  <motion.div
                    key="TruncatedAddress"
                    layout
                    initial={{ opacity: 0, height: "1rem" }}
                    animate={{ opacity: 1, height: "1rem" }}
                    exit={{ opacity: 0, height: "1rem" }}
                  >
                    {truncateAddress(
                      configuration.walletAddress,
                      configuration.network,
                      12,
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <Text className="text-xs leading-tight opacity-50">
          <FontAwesomeIcon icon={icon({ name: "bullhorn" })} size="sm" /> It is
          your responsibility to ensure you&apos;ve connected the correct
          address.
        </Text>
        <div className="mt-auto">
          <Button key="OnboardingWallet:button" onClick={onClick}>
            Continue
          </Button>
        </div>
      </div>
    </AnimationContainer>
  );
};
