import ETH from "../assets/crypto-logos/Ethereum.svg";
import MATIC from "../assets/crypto-logos/Matic.svg";
import OPTIMISM from "../assets/crypto-logos/Optimism.svg";
import SOL from "../assets/crypto-logos/Solana.svg";
import USDC from "../assets/crypto-logos/USDC.svg";
import BTC from "../assets/crypto-logos/BTC.svg";
import { twMerge } from "tailwind-merge";

const icons: { [key: string]: string } = {
  USDC,
  ETH,
  SOL,
  MATIC,
  POL: MATIC,
  OPTIMISM,
  BTC,
};
const sizes = { sm: "h-4 w-4", md: "h-8 w-8", lg: "h-10 w-10" };

export const TokenIcon = ({
  token,
  size = "md",
  className,
}: {
  token: string;
  size?: "sm" | "md" | "lg";
  className?: string;
}) => {
  const classes = twMerge(sizes[size], "token-icon", className);

  return (
    <div
      data-testid={`${token}TokenIcon`}
      className={classes}
      style={{
        WebkitMask: `url(${icons[token]}) 50% 50% / contain no-repeat`,
        mask: `url(${icons[token]}) 50% 50% / contain no-repeat`,
      }}
    />
  );
};
