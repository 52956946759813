import { cloneElement } from "react";
import { Navigation } from "./components/Navigation";
import { AnimatePresence } from "framer-motion";
import { Toast } from "./components/Toast";
import { useRouter } from "./hooks/useRouter";
import { useOnboarding } from "./hooks/useOnboarding";
import { LoadingInterstitial } from "@tigris/mesokit";

type LayoutProps = {
  /**
   * Whether to stand up a `Toast` component.
   *
   * When using `<OnboardingApp />` in the context of a transfer, this should ve `false`.
   * */
  useToast?: boolean;
};

export const Layout = ({ useToast = false }: LayoutProps) => {
  const { appReady } = useOnboarding();
  const { outlet, currentRoute } = useRouter();

  return (
    <main className="onboarding-root flex h-full w-full">
      {appReady ? (
        <section className="wrapper">
          <div className="onboarding-container">
            {useToast && <Toast />}
            <Navigation />
            <div className="onboarding-card">
              <AnimatePresence mode="wait">
                {outlet && cloneElement(outlet, { key: currentRoute.pathname })}
              </AnimatePresence>
            </div>
          </div>
        </section>
      ) : (
        <LoadingInterstitial mode="waiting" />
      )}
    </main>
  );
};
