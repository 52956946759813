import { LoadingInterstitial } from "@tigris/mesokit";
import { AnimatePresence, useAnimate } from "framer-motion";
import { StandaloneError } from "./StandaloneError";
import { PropsWithChildren, useEffect, useState } from "react";
import { AppContextProvider } from "@src/contexts/AppContextProvider";
import { ErrorBoundary } from "react-error-boundary";
import { Sentry } from "@src/utils/telemetry";
import { err, ok, Result } from "neverthrow";
import { MESO_JS_VERSION } from "@meso-network/meso-js";
import { dynamicDelay } from "@src/utils/dynamicDelay";
import { IntegrationMode } from "@src/types";
import { OnboardingContextProvider } from "@src/contexts/OnboardingContextProvider";

const { VITE_GRAPHQL_ORIGIN = "http://localhost:4001" } = import.meta.env;

const verifyUrl = async (): Promise<Result<URLSearchParams, string>> => {
  const ERROR_MESSAGE = "Unable to verify signature";
  const startTime = performance.now();

  try {
    const isTransferUrl = !!new URLSearchParams(location.search).get(
      "partnerId",
    );
    const signatureVerificationResponse = await fetch(
      `${VITE_GRAPHQL_ORIGIN}/${isTransferUrl ? "verify-standalone" : "verify-signature"}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        // We explicitly want to use `location.search` here and not use URLSearchParams.toString() or use URLSearchParams from react-router-dom
        // because it corrupts the order of the parameters which causes signature verification to fail
        body: JSON.stringify({ query: location.search }),
      },
    );

    if (signatureVerificationResponse.ok) {
      await dynamicDelay(startTime);

      const { url } = await signatureVerificationResponse.json();

      return ok(
        new URLSearchParams(
          `${new URL(url).search}&version=${MESO_JS_VERSION}&mode=standalone`,
        ),
      );
    } else {
      return err(ERROR_MESSAGE);
    }
  } catch (error: unknown) {
    await dynamicDelay(startTime);
    Sentry.captureException(error);

    return err(ERROR_MESSAGE);
  }
};

export const StandaloneLoader = ({ children }: PropsWithChildren) => {
  const [scope, animate] = useAnimate();
  const [hasError, setHasError] = useState(false);
  const [params, setParams] = useState<URLSearchParams | null>(null);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      const verifyUrlResult = await verifyUrl();

      if (verifyUrlResult.isErr()) {
        setHasError(true);
        return;
      }

      setParams(verifyUrlResult.value);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scope.current && params) {
      animate(scope.current, { opacity: 1 });
    }
  }, [animate, params, scope]);

  return (
    <ErrorBoundary
      fallback={<StandaloneError />}
      onError={(err) => Sentry.captureException(err)}
    >
      <AnimatePresence mode="wait">
        {hasError && <StandaloneError />}
        {!hasError && !isReady && (
          <LoadingInterstitial mode="waiting" key="LoadingInterstitial" />
        )}
      </AnimatePresence>
      {params && (
        <AppContextProvider
          configurationParams={params}
          onReady={() => setReady(true)}
          mode={IntegrationMode.STANDALONE}
        >
          <OnboardingContextProvider>
            {isReady && children}
          </OnboardingContextProvider>
        </AppContextProvider>
      )}
    </ErrorBoundary>
  );
};
