import AnimatedBackground from "./AnimatedBackground";
import { themeCollection, ThemeName } from "../../style/themes";
import { PropsWithChildren } from "react";
import { Variants, motion } from "framer-motion";
import { spring } from "../../utils/animation";
import { twMerge } from "tailwind-merge";

const heroAnimationVariants: Variants = {
  initial: { scale: 0.9, opacity: 1, y: -16 },
  animate: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: { ...spring, delay: 0 },
  },
};

export const Hero = ({
  children,
  className,
  theme = "default",
  style,
}: PropsWithChildren<{
  theme?: ThemeName;
  className?: string;
  style?: object | undefined;
}>) => {
  const cardHeroClassName = twMerge(
    className,
    "card-hero items-center relative pt-0 transition-all",
  );

  return (
    <motion.div
      key="Hero"
      data-testid="hero"
      id="hero"
      className={cardHeroClassName}
      variants={heroAnimationVariants}
      animate="animate"
      style={style}
    >
      {children}
      {themeCollection[theme].animated && <AnimatedBackground theme={theme} />}
    </motion.div>
  );
};
