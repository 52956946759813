import { motion, AnimatePresence } from "framer-motion";
import { ReactElement, useEffect, useState } from "react";

export const Tooltip = ({
  children,
  message,
  suppress,
}: {
  children: ReactElement;
  message: string;
  suppress?: boolean;
}) => {
  const [hovered, setHover] = useState(false);

  useEffect(() => {
    if (suppress) {
      setHover(false);
    }
  }, [suppress]);

  return (
    <div
      onMouseOver={() => {
        if (suppress) return;
        setHover(true);
      }}
      onMouseOut={() => setHover(false)}
      onClick={() => setHover(false)}
      className="relative"
    >
      {children}

      <AnimatePresence>
        {hovered && (
          <motion.div
            initial={{ opacity: 0, y: 16, scale: 0.9, x: "-50%" }}
            animate={{ opacity: 1, y: 8, scale: 1 }}
            exit={{ opacity: 0, y: 16, scale: 0.9 }}
            className="absolute bottom-8 z-10 hidden w-28 rounded-lg bg-white px-3 py-2 text-center text-sm font-medium text-neutral-800 shadow-lg transition-opacity duration-300 sm:left-1/2 sm:block sm:-translate-x-1/2 dark:bg-neutral-700 dark:text-white"
          >
            {message}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
