/* eslint-disable react-refresh/only-export-components */
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type props = {
  className?: string;
  bold?: boolean;
  ["data-testid"]?: string;
};

const MODE_CLASSES = "text-neutral-800 dark:text-white";

const XL = (props: PropsWithChildren<props>) => (
  <h1
    data-testid={props["data-testid"]}
    className={twMerge(
      "text-3xl",
      MODE_CLASSES,
      props.bold && "font-bold",
      props.className,
    )}
  >
    {props.children}
  </h1>
);

const Large = (props: PropsWithChildren<props>) => (
  <h2
    data-testid={props["data-testid"]}
    className={twMerge(
      "text-2xl",
      MODE_CLASSES,
      props.bold && "font-bold",
      props.className,
    )}
  >
    {props.children}
  </h2>
);

const Medium = (props: PropsWithChildren<props>) => (
  <h3
    data-testid={props["data-testid"]}
    className={twMerge(
      "text-xl",
      props.bold && "font-bold",
      MODE_CLASSES,
      props.className,
    )}
  >
    {props.children}
  </h3>
);

const Small = (props: PropsWithChildren<props>) => (
  <h4
    data-testid={props["data-testid"]}
    className={twMerge(
      "text-lg",
      props.bold && "font-bold",
      MODE_CLASSES,
      props.className,
    )}
  >
    {props.children}
  </h4>
);

export const Title = {
  XL,
  Large,
  Medium,
  Small,
};
