/**
 * Return whether the user has requested dark mode.
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme
 */
export const isDarkMode = (
  onAppearanceChange?: (isDarkMode: boolean) => void,
) => {
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  if (onAppearanceChange) {
    const handler = (event: MediaQueryListEvent) => {
      onAppearanceChange(event.matches);
    };

    if (mediaQuery.matches) {
      onAppearanceChange(true);
    }

    mediaQuery.addEventListener("change", handler);
  }

  return mediaQuery.matches;
};
