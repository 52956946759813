import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { spring } from "../../utils/animation";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useCallback } from "react";
import { twMerge } from "tailwind-merge";

interface CountdownProps {
  onResend: () => void;
  initialSeconds?: number;
  resendMax?: number;
}

const sharedClasses = "text-xs font-medium dark:text-white";

function ResendTimer({
  onResend,
  initialSeconds = 10,
  resendMax = 5,
}: CountdownProps) {
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const [resendCount, setResendCount] = useState<number>(0);

  useEffect(() => {
    if (seconds > 0) {
      const timeout = setTimeout(
        () => setSeconds((prevSeconds) => prevSeconds - 1),
        1000,
      );
      return () => clearTimeout(timeout);
    }
  }, [seconds]);

  const handleResendClick = useCallback(() => {
    onResend();

    if (resendCount < resendMax) {
      setSeconds(initialSeconds);
      setResendCount((prev) => prev + 1);
    }
  }, [initialSeconds, onResend, resendCount, resendMax]);

  return (
    <div
      data-testid="ResendTimer"
      className="mb-2 flex justify-between text-xs font-bold text-neutral-900 dark:text-white"
    >
      <AnimatePresence mode="wait">
        <motion.div
          key="ResendTimer:copy"
          initial={{ y: 12, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { ...spring, delay: 0.2 },
          }}
          exit={{ y: -12, opacity: 0 }}
          data-testid="ResendTimer:copy"
        >
          6-digit SMS Code
        </motion.div>
        {seconds > 0 || resendCount >= resendMax ? null : (
          <motion.div
            key="ResendTimer:button"
            initial={{ y: 12, opacity: 0 }}
            animate={{ y: 0, opacity: 0.6, transition: spring }}
            exit={{ y: -12, opacity: 0 }}
            data-testid="ResendTimer:button"
            onClick={handleResendClick}
            className={twMerge(
              sharedClasses,
              "group hover:cursor-pointer hover:opacity-100",
            )}
          >
            <FontAwesomeIcon
              icon={icon({ name: "refresh" })}
              size="sm"
              className="mr-1 transition-transform group-hover:rotate-180"
            />
            Resend
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ResendTimer;
