import type { FormField } from "../types";
import type { FramesBillingAddress } from "frames-react";

export const defaultFormField = <T = string>(defaultValue: T): FormField<T> => {
  return {
    value: defaultValue,
    isValid: false,
    isDirty: false,
    isTouched: false,
  };
};

export const defaultBillingAddress: FramesBillingAddress = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  country: "US",
};
