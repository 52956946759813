import { animate } from "framer-motion";
import { HTMLAttributes, useEffect, useRef, useState } from "react";

type AnimatedCounterProps = Pick<
  HTMLAttributes<HTMLDivElement>,
  "className"
> & {
  value: number;
  /**
   * The number of decimal places to render. Defaults to `2`.
   */
  precision?: number;
  /**
   * An optional prefix that will be rendered outside of the numeric calculation.
   *
   * For example: `"$"`.
   */
  prefix?: string;
};

/**
 *
 * A component to render animated transitions between numeric values.
 */
export const AnimatedCounter = ({
  value,
  className,
  precision = 2,
  prefix,
}: AnimatedCounterProps) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [fromTo, setFromTo] = useState({ from: 0, to: 0 });

  useEffect(() => {
    setFromTo((state) => {
      return {
        to: value,
        from: state.to,
      };
    });
  }, [value]);

  useEffect(() => {
    const controls = animate(fromTo.from, fromTo.to, {
      duration: 1.5,
      ease: "easeInOut",
      onUpdate(value) {
        if (nodeRef.current !== null) {
          nodeRef.current.textContent = `${prefix || ""}${value.toFixed(
            precision,
          )}`;
        }
      },
    });

    return () => controls.stop();
  }, [fromTo, precision, prefix]);

  return <div className={className} ref={nodeRef} />;
};
