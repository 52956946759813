import * as sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { OnboardingAppRenderContext, Routes } from "../types";

export const Sentry = {
  init: () => {
    sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      enabled: import.meta.env.CI !== "true",
      environment: import.meta.env.VITE_TIGRIS_ENV,
      release: import.meta.env.VITE_TIGRIS_RELEASE,
      debug: import.meta.env.VITE_SENTRY_DEBUG === "1",
      tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
      replaysSessionSampleRate: Number(
        import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
      ),
      replaysOnErrorSampleRate: Number(
        import.meta.env.VITE_SENTRY_ERROR_REPLAY_SESSION_SAMPLE_RATE,
      ),

      integrations: [
        sentry.browserTracingIntegration(),
        sentry.replayIntegration({
          // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/configuration/#using-a-custom-compression-worker
          workerUrl: "/worker.min.js",
        }),
      ],

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
      tracePropagationTargets: [
        "localhost",
        "127.0.0.1",
        /^https:\/\/api\.dev\.meso\.plumbing/,
        /^https:\/\/api\..*\.meso\.network/,
      ],
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],

      // We need to drop errors with this message as they are unhelpful in diagnosing problems with our systems.
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      // Example issue:  https://meso-inc.sentry.io/issues/4847069552/?alert_rule_id=14598235&alert_type=issue&notification_uuid=9d1a8b9a-15ed-41c3-b7d4-38a52ba8e1a6&project=4505665559986176
      ignoreErrors: [
        "Network request failed",
        "AbortError: Aborted",
        // When passkey negotiation takes too long, this error is surfaced, but we handle it gracefully and the user can retry
        // Example issue: https://meso-inc.sentry.io/issues/5428938881/?alert_rule_id=14598235&alert_type=issue&notification_uuid=ffbcab25-fa6b-44fa-8e27-8274467349c5&project=4505665559986176&referrer=slack
        "The operation either timed out or was not allowed.",
        "The operation is insecure.",
      ],
    });
  },

  ErrorBoundary: sentry.ErrorBoundary,
  captureException: sentry.captureException,
  captureMessage: sentry.captureMessage,
  setContext: sentry.setContext,
  metrics: sentry.metrics,
};

export const Amplitude = {
  // Because this has to be duplicated in the `transfer-app`, we use unnecessary specificity in the naming here.
  onboardingAppContext: { renderContext: "unknown" },

  init() {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_PUBLIC_KEY, {
      appVersion: import.meta.env.VITE_TIGRIS_RELEASE,
      logLevel:
        import.meta.env.VITE_AMPLITUDE_DEBUG === "1"
          ? amplitude.Types.LogLevel.Debug
          : amplitude.Types.LogLevel.None,
      serverUrl:
        import.meta.env.VITE_AMPLITUDE_ENDPOINT ??
        "https://api2.amplitude.com/2/httpapi",
    });

    if (import.meta.env.CI === "true") {
      amplitude.setOptOut(true);
    }

    // https://www.docs.developers.amplitude.com/data/sdks/browser-2/#set-the-transport-to-use-beacon-only-when-exiting-page
    window.addEventListener("pagehide", async () => {
      amplitude.setTransport("beacon");
      amplitude.flush();
    });
  },

  setIdentifyUserId(userId: string) {
    if (userId && userId.length != 0 && !/^u_0+/.test(userId)) {
      amplitude.setUserId(userId);
    }
  },

  track: amplitude.track,

  setContext({ renderContext }: { renderContext: OnboardingAppRenderContext }) {
    // Translate our domain type to an amplitude friendly value
    let reportableRenderContext = "embeddedInTransfer";

    if (renderContext === OnboardingAppRenderContext.BREAKOUT_FROM_TRANSFER) {
      reportableRenderContext = "windowFromTransfer";
    }

    this.onboardingAppContext = { renderContext: reportableRenderContext };
  },

  // Because we don't use URL-based routing, we have to dispatch our own equivalent of a `Page Viewed` event.
  trackSyntheticOnboardingAppPageView({ pathname }: { pathname: Routes }) {
    Amplitude.track("Onboarding page view", {
      pathname,
      appName: "Onboarding App",
      renderContext: this.onboardingAppContext.renderContext,
    });
  },
};

const Telemetry = {
  init: () => {
    Sentry.init();
    Amplitude.init();
  },
};

export default Telemetry;
