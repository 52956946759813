import { AnimatePresence, motion } from "framer-motion";
import { spring } from "../utils/animation";
import "./Logo.css";
import { twMerge } from "tailwind-merge";
import mesoLogo from "../assets/meso-logo.svg";
import { LOGO_SIZES } from "../utils/constants";

export const LogoMark = ({
  delay = 0,
  size,
}: {
  delay?: number;
  size: keyof typeof LOGO_SIZES;
}) => {
  const variants = {
    container: {
      initial: { opacity: 0, scale: 0.5, y: 25 },
      animate: { opacity: 1, scale: 1, y: 0, transition: { ...spring, delay } },
    },
    holo: {
      initial: { opacity: 0, scale: 0.5, rotateX: 60 },
      animate: {
        opacity: 1,
        scale: 1,
        rotateX: 0,
        transition: {
          ...spring,
          delay: 0.15 + delay,
        },
      },
    },
    plate: {
      initial: { opacity: 0, scale: 0.5, rotateX: 90 },
      animate: {
        opacity: 1,
        scale: 1,
        rotateX: 0,
        transition: {
          ...spring,
          delay: 0.25 + delay,
        },
      },
    },
    mark: {
      initial: { opacity: 0, scale: 0.5, rotateX: 60 },
      animate: {
        opacity: 1,
        scale: 1,
        rotateX: 0,
        transition: {
          ...spring,
          delay: 0.25 + delay,
        },
      },
    },
  };

  const containerCSS = twMerge(
    "logo-container",
    LOGO_SIZES[size].logoMarkContainer,
  );
  const plateCSS = twMerge("logo-container-plate", LOGO_SIZES[size].plate);

  return (
    <AnimatePresence>
      <motion.div
        variants={variants.container}
        initial="initial"
        animate="animate"
        className={containerCSS}
      >
        <motion.div
          variants={variants.holo}
          initial="initial"
          animate="animate"
          className="logo-container-holo"
        >
          <motion.div className={plateCSS}>
            <img src={mesoLogo} alt="Meso Mark" />
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
