import { UserActivationState } from "@src/generated/sdk";
import { useApp } from "@src/hooks/useApp";
import { UserStatus } from "@src/types";
import { Routes } from "@src/utils/constants";
import { LoadingInterstitial } from "@tigris/mesokit";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const POLL_INTERVAL_MSEC = 5e3;
const TOAST_ID = "ActivateUser";

export const ActivateUser = ({
  pollIntervalInMS = POLL_INTERVAL_MSEC,
}: {
  pollIntervalInMS?: number;
}) => {
  // React strict mode causes useEffect callbacks to be invoked twice. We use
  // the `sessionInitialized` ref to avoid setting a new session token from a
  // second NewSession invocation.
  const userActivationInitialized = useRef(false);
  const { api, session } = useApp();
  const [userActivationId, setUserActivationId] = useState<string>();
  const [isPolling, setIsPolling] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    // Activate user
    const initializeActivation = async () => {
      const activateUserResult = await api.resolveActivateUser({
        input: { riskSessionKey: session!.riskSession.sessionKey },
      });

      if (activateUserResult.isErr()) {
        toast.error(activateUserResult.error, { id: TOAST_ID });
        return;
      }

      setUserActivationId(activateUserResult.value.id);
    };

    if (userActivationInitialized.current) return;
    userActivationInitialized.current = true;
    initializeActivation();
  }, [api, session]);

  const checkActivation = useCallback(async () => {
    if (!userActivationId) return;

    const userActivationStatusResult = await api.resolveUserActivationStatus({
      id: userActivationId,
    });

    if (userActivationStatusResult.isErr()) {
      toast.error(userActivationStatusResult.error, { id: TOAST_ID });
      setIsPolling(false);
      return;
    }

    const userActivation = userActivationStatusResult.value;

    const activationComplete =
      userActivation.state === UserActivationState.FAILED ||
      userActivation.state === UserActivationState.SUCCEEDED;

    if (activationComplete) {
      setIsPolling(false);

      if (
        userActivation.state === UserActivationState.SUCCEEDED &&
        userActivation.userStatus === UserStatus.ACTIVE
      ) {
        navigate({ pathname: Routes.TransferSheet, search });
      } else {
        navigate({ pathname: Routes.TransferUnavailable, search });
      }
    } else {
      setTimeout(checkActivation, pollIntervalInMS);
    }
  }, [api, navigate, pollIntervalInMS, search, userActivationId]);

  useEffect(() => {
    if (userActivationId && !isPolling) {
      setTimeout(checkActivation, pollIntervalInMS / 2);
    }
  }, [api, checkActivation, isPolling, pollIntervalInMS, userActivationId]);

  return (
    <div>
      <LoadingInterstitial mode="waiting" />
    </div>
  );
};
