import responseMiddleware from "../utils/middleware";
import { Amplitude, Sentry } from "../utils/telemetry";
import { AppContext } from "./AppContext";
import {
  DEFAULT_THEME,
  ThemeName,
  UserLimits,
  isValidTheme,
  partnerThemes,
} from "@tigris/mesokit";
import { GraphQLClient } from "graphql-request";
import {
  AssetAmount,
  MessageKind,
  IntegrationMode,
  AnnouncementBanner,
} from "@src/types";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SardineEnvironment } from "packages/vendor/@sardine-ai/react-js-wrapper/dist";
import { useLocation, useNavigate } from "react-router-dom";
import { getSdk } from "../generated/sdk";
import { api as createApi } from "../api";
import { type AppContextValue, type Session, type User } from "../types";
import { useInitialization } from "@src/hooks/useInitialization";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { useSafeLocalStorage } from "@src/hooks/useSafeLocalStorage";
import { defaultContextFn, defaultUser } from "./defaults";
import { MESO_MIN_AMOUNT } from "@src/utils/constants";

type AppContextProviderProps = {
  /**
   * URLSearchParams instance that will be parsed into configuration.
   */
  configurationParams: URLSearchParams;
  /** An optional callback that will be dispatched when the app context is ready. This helps components lower in the tree to defer rendering until all initialization data is resolved. */
  onReady?: () => void;
  /** Initialize the application with a mode driven by the URL. */
  mode: IntegrationMode;
};

const MESO_AUTHORIZATION_HEADER = "Authorization";

export const AppContextProvider = ({
  children,
  configurationParams,
  onReady,
  mode,
}: PropsWithChildren<AppContextProviderProps>) => {
  const { bus, apiOrigin, configuration } = useInitialization({
    configurationParams,
    mode,
  });

  // React strict mode causes useEffect callbacks to be invoked twice. We use
  // the `sessionInitialized` ref to avoid setting a new session token from a
  // second NewSession invocation.
  const sessionInitialized = useRef(false);
  const [hasPasskey, setHasPasskey] = useSafeLocalStorage(
    "meso:hasPasskey",
    false,
  );
  const [toasterId, setToasterId] = useState<number>(Date.now());
  const clearToasts = () => setToasterId(Date.now());

  const navigate = useNavigate();
  const { search } = useLocation();
  const graphQLClient = useMemo(
    () =>
      new GraphQLClient(`${apiOrigin}/query`, {
        errorPolicy: "all",
        responseMiddleware: responseMiddleware(navigate, clearToasts, search),
      }),
    [apiOrigin, navigate, search],
  );
  const api = useMemo<AppContextValue["api"]>(
    () => createApi(getSdk(graphQLClient)),
    [graphQLClient],
  );
  const [announcementBanner, setAnnouncementBanner] =
    useState<AnnouncementBanner>();

  const [appContextState, setAppContextState] = useState<AppContextValue>(
    () => {
      let theme = defaultUser.theme;

      if (mode === IntegrationMode.INLINE) {
        if (partnerThemes.get(configuration.partnerId)) {
          theme = partnerThemes.get(configuration.partnerId)
            ?.themeName as ThemeName;
        } else {
          theme = "inline";
        }
      }

      return {
        configuration,
        bus,
        api,
        toasterId,
        updateSession: defaultContextFn,
        updateUser: defaultContextFn,
        setTransfer: defaultContextFn,
        clearToasts: defaultContextFn,
        user: { ...defaultUser, theme },
        updateConfiguration: defaultContextFn,
        isEditingAmount: false,
        hasPasskey,
        setHasPasskey,
        browserSupportsWebAuthn: browserSupportsWebAuthn(),
        engageAmountEditor: defaultContextFn,
        closeAmountEditor: defaultContextFn,
        apiOrigin,
        appReady: false,
        mode,
        quoteLimitReached: false,
        setQuoteLimitReached: defaultContextFn,
        hasContextError: false,
      };
    },
  );

  const updateSession = useCallback(
    (session: Partial<Session>) => {
      if (session.token) {
        graphQLClient.setHeader(
          MESO_AUTHORIZATION_HEADER,
          `Bearer ${session.token}`,
        );

        Sentry.setContext("session", session);
      }

      if (session.riskSession && session.riskSession.userId) {
        Amplitude.setIdentifyUserId(session.riskSession.userId);
      }

      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        session: { ...prevAppContextState.session, ...(session as Session) },
      }));
    },
    [graphQLClient],
  );

  useEffect(() => {
    if (sessionInitialized.current) return;
    sessionInitialized.current = true;

    (async () => {
      const newSessionResult = await api.resolveNewSession({
        input: {
          partnerId: appContextState.configuration.partnerId,
          network: appContextState.configuration.network,
          walletAddress: appContextState.configuration.walletAddress,
        },
      });

      if (newSessionResult.isErr()) {
        return;
      }

      const newSession = newSessionResult.value;

      updateSession({
        id: newSession.id,
        token: newSession.token,
        riskSession: {
          userId: newSession.riskSession.userId,
          clientId: newSession.riskSession.clientId,
          sessionKey: newSession.riskSession.sessionKey,
          environment: newSession.riskSession.environment as SardineEnvironment,
        },
        mesoLimits: {
          min: newSession.transferMin.amount as AssetAmount,
          max: newSession.transferMax.amount as AssetAmount,
        },
        isReturningUser: newSession.isReturningUser,
        passkeysEnabled: newSession.passkeysEnabled,
      });

      if (newSession.announcementBanner) {
        setAnnouncementBanner({
          title: newSession.announcementBanner.title,
          body: newSession.announcementBanner.body,
        });
      }

      const partnerResult = await api.resolvePartnerDetails();

      if (partnerResult.isOk()) {
        setAppContextState((state) => ({
          ...state,
          partner: partnerResult.value,
        }));
      } else {
        throw new Error(partnerResult.error);
      }

      // Report "ready" across multiple channels
      // Report to partner (in embedded)
      bus?.emit({ kind: MessageKind.READY });
      // Report to internal components awaiting this callback (standalone)
      if (typeof onReady === "function") {
        onReady();
      }
      // Set `appReady` for any components watching this context
      setAppContextState((state) => ({ ...state, appReady: true }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUser = useCallback(
    (user: Partial<User>) => {
      if (user.theme) {
        // For inline, we do not want to update the user's theme
        if (mode === IntegrationMode.INLINE) {
          if (partnerThemes.get(configuration.partnerId)) {
            user.theme = partnerThemes.get(configuration.partnerId)?.themeName;
          } else {
            user.theme = "inline";
          }
        } else if (mode === IntegrationMode.STANDALONE) {
          const newTheme = isValidTheme(user.theme)
            ? user.theme
            : DEFAULT_THEME;

          const currentThemeClasses = Array.from(
            document.documentElement.classList,
          ).filter((className) => className.startsWith("theme-"));

          // Side effects!
          document.documentElement.classList.remove(...currentThemeClasses);
          document.documentElement.classList.add(`theme-${newTheme}`);

          user.theme = newTheme;
        } else if (!isValidTheme(user.theme)) {
          user.theme = DEFAULT_THEME;
        } else {
          // Embedded
          const newTheme = isValidTheme(user.theme)
            ? user.theme
            : DEFAULT_THEME;

          const currentThemeClasses = Array.from(
            document.documentElement.classList,
          ).filter((className) => className.startsWith("theme-"));

          // Side effects!
          document.documentElement.classList.remove(...currentThemeClasses);
          document.documentElement.classList.add(`theme-${newTheme}`);

          user.theme = newTheme;
        }
      }

      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        user: { ...prevAppContextState.user, ...user },
      }));
    },
    [configuration.partnerId, mode],
  );

  const setTransfer = useCallback(
    (transfer: Parameters<AppContextValue["setTransfer"]>[0]) => {
      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        transfer,
      }));
    },
    [],
  );

  const updateConfiguration: AppContextValue["updateConfiguration"] =
    useCallback(({ sourceAmount }) => {
      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        configuration: {
          ...prevAppContextState.configuration,
          sourceAmount,
        },
      }));
    }, []);

  const engageAmountEditor = useCallback<AppContextValue["engageAmountEditor"]>(
    (sourceAction) => {
      Amplitude.track("amount_editor_engaged", { sourceAction });

      setAppContextState((prevAppContextState) => ({
        ...prevAppContextState,
        isEditingAmount: true,
      }));
    },
    [],
  );

  const closeAmountEditor = useCallback<
    AppContextValue["closeAmountEditor"]
  >(() => {
    setAppContextState((prevAppContextState) => ({
      ...prevAppContextState,
      isEditingAmount: false,
    }));
  }, []);

  const setQuoteLimitReached = useCallback(() => {
    setAppContextState((prevAppContextState) => ({
      ...prevAppContextState,
      quoteLimitReached: true,
    }));
  }, []);

  const userLimits = useMemo<UserLimits | undefined>(() => {
    if (!appContextState.session || !appContextState.user.limits) return;

    const session = appContextState.session;
    const userLimits = appContextState.user.limits;
    const sourceAmount = appContextState.configuration.sourceAmount;

    const mesoMinimum = session.mesoLimits.min
      ? Number(session.mesoLimits.min)
      : MESO_MIN_AMOUNT;
    const monthlyAmountUsed = Number(userLimits?.monthlyCashInUsed?.amount);
    const monthlyMaximumAmount = Number(userLimits?.monthlyMax?.amount);
    const userMonthlyMax = Number(userLimits?.monthlyMax?.amount);
    const monthlyAmountAvailable = Number(
      userLimits?.monthlyCashInAvailable.amount,
    );
    const requestedAmount = Number(sourceAmount);
    const percentUsed = monthlyAmountUsed / userMonthlyMax;

    return {
      mesoMinimum,
      monthlyAmountAvailable,
      monthlyAmountUsed,
      monthlyMaximumAmount,

      // Computed properties
      percentUsed,
      requestedAmountExceedsLimit: requestedAmount > monthlyAmountAvailable,
      editable: monthlyAmountAvailable - mesoMinimum >= mesoMinimum,
      limitReached: monthlyAmountUsed >= userMonthlyMax,
      approachingLimit: percentUsed > 0.8,
    };
  }, [
    appContextState.configuration.sourceAmount,
    appContextState.session,
    appContextState.user.limits,
  ]);

  const contextValue = useMemo(() => {
    return {
      ...appContextState,
      updateSession,
      updateUser,
      setTransfer,
      toasterId,
      clearToasts,
      updateConfiguration,
      engageAmountEditor,
      closeAmountEditor,
      hasPasskey,
      setHasPasskey,
      setQuoteLimitReached,
      announcementBanner,
      userLimits,
    };
  }, [
    appContextState,
    updateSession,
    updateUser,
    setTransfer,
    toasterId,
    updateConfiguration,
    engageAmountEditor,
    closeAmountEditor,
    hasPasskey,
    setHasPasskey,
    setQuoteLimitReached,
    announcementBanner,
    userLimits,
  ]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
export { AppContext };
