import { Asset, FiatAsset } from "../../types";
import { TokenIcon } from "../TokenIcon";
import { motion, animate, AnimatePresence, Variants } from "framer-motion";
import { spring } from "../../utils/animation";
import { truncateAmount } from "@tigris/common";
import { useEffect, useRef, useState } from "react";

const animationVariants = (animate: boolean): Variants => ({
  initial: animate ? { opacity: 0, y: 8 } : {},
  animate: animate ? { opacity: 1, y: 0, transition: spring } : {},
  exit: animate ? { opacity: 0, y: -8, transition: spring } : {},
});

const skeletonClassName =
  "bg-gray-400/20 text-transparent rounded-3xl opacity-20 select-none w-40 h-[calc(100%-24px)]";
const skeletonLineVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: [0.2, 0.8, 0.2],
    transition: {
      repeat: Infinity,
      repeatType: "loop",
      duration: 1.4,
      ease: "linear",
    },
  },
  exit: { opacity: 0 },
};

type AssetAmountProps = {
  asset: Asset;
  amount?: string;
  animate?: boolean;
};

const Counter = ({
  from,
  to,
  asset,
}: {
  from: number;
  to: number;
  asset: Asset;
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const controls = animate(from, to, {
      duration: 1.5,
      ease: "easeInOut",
      onUpdate(value) {
        if (nodeRef.current !== null) {
          nodeRef.current.textContent = truncateAmount(asset, value.toString());
        }
      },
    });

    return () => controls.stop();
  }, [asset, from, to]);

  return <div ref={nodeRef} />;
};

export const AssetAmount = ({
  asset,
  amount,
  animate = true,
}: AssetAmountProps) => {
  const [counter, setCounter] = useState<Record<"from" | "to", number>>({
    from: 0,
    to: 0,
  });

  useEffect(() => {
    setCounter((previousCounter) => {
      const newAmount = Number(truncateAmount(asset, amount));
      return {
        from: previousCounter.from === 0 ? newAmount : previousCounter.to,
        to: newAmount,
      };
    });
  }, [asset, amount]);

  const amountClasses = "flex items-center gap-1 text-[3rem] hero-number";

  return (
    <section
      data-testid="AssetAmount"
      className="flex h-12 w-full items-center justify-center gap-4"
    >
      <AnimatePresence mode="popLayout">
        {counter.to !== 0 && counter.from !== 0 ? (
          <motion.div
            key="loaded"
            variants={animationVariants(animate)}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <div className={amountClasses}>
              {asset in FiatAsset ? "$" : <TokenIcon token={asset} />}
              {animate ? (
                <Counter from={counter.from} to={counter.to} asset={asset} />
              ) : (
                <div className="font-bold tabular-nums leading-none">
                  {counter.to}
                </div>
              )}
            </div>
          </motion.div>
        ) : (
          <motion.span
            key="assetAmountLoader"
            data-testid="assetAmountLoader"
            variants={skeletonLineVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={skeletonClassName}
          >
            ------
          </motion.span>
        )}
      </AnimatePresence>
    </section>
  );
};
