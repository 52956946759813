import { Amplitude } from "../utils/telemetry";
import { AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeBottomCard } from "./ThemeBottomCard";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Text } from "@tigris/mesokit";
import { useState } from "react";
import { useOnboarding } from "../hooks/useOnboarding";

export const ThemePickerButton = () => {
  const { user } = useOnboarding();
  const [showThemePicker, setShowThemePicker] = useState<boolean>(false);
  const themeIcon =
    "h-8 w-8 rounded-[64px] ring-1 dark:ring-white/20 ring-neutral-800/20 theme-icon";

  return (
    <>
      <div
        className="flex h-auto w-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-2 font-bold text-neutral-800 shadow-xl transition-all duration-100 hover:bg-neutral-500/5 active:translate-y-1 active:shadow-none dark:border-neutral-700 dark:text-white"
        onClick={() => {
          Amplitude.track("Theme Picker", { action: "show" });
          setShowThemePicker(true);
        }}
        data-testid="ThemePickerButton"
      >
        <div className="flex items-center gap-2">
          <div className={themeIcon} />
          <div className="flex flex-col items-start">
            <Text className="text-xs opacity-50">Theme</Text>
            <Text className="capitalize">{user.theme}</Text>
          </div>
          <FontAwesomeIcon
            icon={icon({ name: "chevron-right" })}
            className="ml-auto pt-1"
          />
        </div>

        <Text className="rounded-lg bg-neutral-50 p-2 text-center text-xs text-neutral-500 dark:bg-neutral-700 dark:text-neutral-300">
          🎨 Your theme lets you verify at-a-glance you’re securely interacting
          with Meso.
        </Text>
      </div>
      <AnimatePresence>
        {showThemePicker && (
          <ThemeBottomCard
            onCloseThemePicker={() => setShowThemePicker(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};
