import {
  Network,
  AssetAmount,
  AuthenticationStrategy,
  Configuration,
  IntegrationMode,
  CAIPAsset,
  CAIPNetwork,
  Asset,
  Message,
  MessageKind,
} from "@src/types";
import {
  assetFromCAIPAsset,
  lookupAsset,
  networkFromCAIPNetwork,
} from "@tigris/common";
import {
  printCodefencedValues,
  validateNetworkAssetPair,
  validateWalletAddress,
} from "./parseCommonConfiguration";
import {
  amountSchema,
  assetSchema,
  caipAssetSchema,
  caipNetworkSchema,
} from "@src/utils/validation";
import { Result, err, ok } from "neverthrow";
import { TransferKind } from "@src/generated/sdk";

export const parseStandaloneConfiguration = (
  queryParams: URLSearchParams,
  mode: IntegrationMode,
  useTestnets: boolean,
): Result<Configuration, Message> => {
  const paramsResult = parseTransferLinkQueryParams(queryParams);
  if (paramsResult.isErr()) {
    return err(paramsResult.error);
  }
  const params = paramsResult.value;

  const networkResult = parseCAIPNetwork(params.network);
  if (networkResult.isErr()) {
    return err(networkResult.error);
  }

  const destinationAssetResult =
    params.destinationAsset in Asset
      ? parseDestinationAsset(networkResult.value, params.destinationAsset)
      : parseCAIPAsset(networkResult.value, params.destinationAsset);
  if (destinationAssetResult.isErr()) {
    return err(destinationAssetResult.error);
  }

  const amountResult = parseSourceOrDestinationAmount(
    params.sourceAmount,
    params.destinationAmount,
  );
  if (amountResult.isErr()) {
    return err(amountResult.error);
  }

  const validateWalletAddressResult = validateWalletAddress(
    params.walletAddress,
    networkResult.value,
  );
  if (validateWalletAddressResult.isErr()) {
    return err(validateWalletAddressResult.error);
  }

  return ok({
    ...(params as unknown as Configuration),
    ...amountResult.value,
    network: networkResult.value,
    sourceAsset: Asset.USD,
    sourceCAIPAsset: CAIPAsset.FIAT_USD,
    destinationAsset: destinationAssetResult.value,
    destinationCAIPAsset: lookupAsset(
      networkResult.value,
      destinationAssetResult.value,
      useTestnets,
    ),
    authenticationStrategy:
      params.authenticationStrategy as AuthenticationStrategy,
    mode,
    transferKind: TransferKind.CASH_IN,
  });
};

const parseTransferLinkQueryParams = (
  queryParams: URLSearchParams,
): Result<{ [key: string]: string }, Message> => {
  const params = {
    partnerId: queryParams.get("partnerId") ?? "",
    network: queryParams.get("network") ?? "",
    walletAddress: queryParams.get("walletAddress") ?? "",
    destinationAsset: queryParams.get("destinationAsset") ?? "",
    version: queryParams.get("version") ?? "",
    authenticationStrategy: AuthenticationStrategy.BYPASS_WALLET_VERIFICATION,
  };
  const amountParams = {
    sourceAmount: queryParams.get("sourceAmount") ?? "",
    destinationAmount: queryParams.get("destinationAmount") ?? "",
  };

  if (queryParams.get("externalId")) {
    // @ts-expect-error: We are declaring an untyped object above. It's fine
    params.externalId = queryParams.get("externalId") ?? "";
  }

  const redirectUrl = queryParams.get("redirectUrl");
  if (redirectUrl && redirectUrl.length > 0) {
    const decoded = decodeURIComponent(redirectUrl);
    const urlWithProtocol = /^https?:\/\//i.test(decoded)
      ? decoded
      : `https://${decoded}`;

    // @ts-expect-error: We are declaring an untyped object above. It's fine
    params.redirectUrl = urlWithProtocol;

    // Only add return button content if a redirect URL is provided
    const returnButtonContent = queryParams.get("returnButtonContent");
    if (returnButtonContent && returnButtonContent.length > 0) {
      // @ts-expect-error: We are declaring an untyped object above. It's fine
      params.returnButtonContent = decodeURIComponent(returnButtonContent);
    }
  }

  let errorMessage = "";
  if (!Object.values(params).every((x) => x !== "")) {
    errorMessage = "Invalid configuration. Ensure all values are present.";
  } else if (!Object.values(amountParams).some((x) => x !== "")) {
    errorMessage =
      "Invalid configuration. Either `sourceAmount` or `destinationAmount` must be present.";
  }

  if (errorMessage.length > 0) {
    if (import.meta.env.DEV && window.top === window) {
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    }

    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok({ ...params, ...amountParams });
};

const parseCAIPAsset = (
  network: Network,
  asset: string,
): Result<Asset, Message> => {
  const assetResult = caipAssetSchema.safeParse(asset);

  if (!assetResult.success) {
    const errorMessage = `Invalid configuration. Asset must be one of ${printCodefencedValues(
      Asset,
    )}`;

    return err({
      kind: MessageKind.UNSUPPORTED_ASSET_ERROR,
      payload: { message: errorMessage },
    });
  }

  const parsedAsset = assetFromCAIPAsset(assetResult.data);
  const validateNetworkAssetPairResult = validateNetworkAssetPair(
    network,
    parsedAsset,
  );
  if (validateNetworkAssetPairResult.isErr()) {
    return err(validateNetworkAssetPairResult.error);
  }

  return ok(parsedAsset);
};

const parseCAIPNetwork = (network: string): Result<Network, Message> => {
  const networkResult = caipNetworkSchema.safeParse(network);

  if (!networkResult.success) {
    const errorMessage = `Invalid configuration. \`network\` must be one of ${printCodefencedValues(
      CAIPNetwork,
    )}`;

    return err({
      kind: MessageKind.UNSUPPORTED_NETWORK_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok(networkFromCAIPNetwork(networkResult.data));
};

const parseSourceOrDestinationAmount = (
  sourceAmount: string,
  destinationAmount: string,
): Result<
  Pick<Configuration, "sourceAmount" | "destinationAmount">,
  Message
> => {
  if (sourceAmount.length > 0) {
    const sourceAmountResult = amountSchema.safeParse(sourceAmount);

    if (!sourceAmountResult.success) {
      const errorMessage =
        "Invalid source amount. Value must be a valid number as a string.";

      return err({
        kind: MessageKind.CONFIGURATION_ERROR,
        payload: { message: errorMessage },
      });
    }

    return ok({ sourceAmount: sourceAmountResult.data as AssetAmount });
  } else {
    const destinationAmountResult = amountSchema.safeParse(destinationAmount);

    if (!destinationAmountResult.success) {
      const errorMessage =
        "Invalid destination amount. Value must be a valid number as a string.";

      return err({
        kind: MessageKind.CONFIGURATION_ERROR,
        payload: { message: errorMessage },
      });
    }

    return ok({
      destinationAmount: destinationAmountResult.data as AssetAmount,
    });
  }
};

const parseDestinationAsset = (
  network: Network,
  destinationAsset: string,
): Result<Asset, Message> => {
  const destinationAssetResult = assetSchema.safeParse(destinationAsset);

  if (!destinationAssetResult.success) {
    const errorMessage = `Invalid configuration. \`destinationAsset\` must be one of ${printCodefencedValues(
      Asset,
    )}`;

    return err({
      kind: MessageKind.UNSUPPORTED_ASSET_ERROR,
      payload: { message: errorMessage },
    });
  }

  const validateNetworkAssetPairResult = validateNetworkAssetPair(
    network,
    destinationAssetResult.data,
  );
  if (validateNetworkAssetPairResult.isErr()) {
    return err(validateNetworkAssetPairResult.error);
  }

  return ok(destinationAssetResult.data);
};
