import * as Factory from "factory.ts";
import { PostMessageBus } from "@src/types";

const noop = () => {};

export const busFactory = Factory.Sync.makeFactory<PostMessageBus>({
  on: noop,
  once: noop,
  emit: noop,
  destroy: noop,
} as unknown as PostMessageBus);
