import {
  Message,
  MessageKind,
  Configuration,
  IntegrationMode,
  Asset,
  CryptoAsset,
  Network,
  Position,
  AssetAmount,
  AuthenticationStrategy,
} from "@src/types";
import {
  printCodefencedValues,
  validateNetworkAssetPair,
  validateSourceDestinationAmountPair,
  validateSourceDestinationAssetPair,
  validateWalletAddress,
} from "./parseCommonConfiguration";
import {
  amountSchema,
  assetSchema,
  authenticationStrategySchema,
  networkSchema,
} from "@src/utils/validation";
import { lookupAsset } from "@tigris/common";
import { Result, err, ok } from "neverthrow";
import { parseLayoutParams } from "./parseLayoutParams";
import { TransferKind } from "@src/generated/sdk";

export const parseEmbeddedConfiguration = (
  queryParams: URLSearchParams,
  mode: IntegrationMode,
  useTestnets: boolean,
): Result<Configuration, Message> => {
  const paramsResult = parseMesoJSQueryParams(queryParams);
  if (paramsResult.isErr()) {
    return err(paramsResult.error);
  }
  const params = paramsResult.value;

  const networkResult = parseNetwork(params.network);
  if (networkResult.isErr()) {
    return err(networkResult.error);
  }

  const sourceAssetResult = parseSourceAsset(
    networkResult.value,
    params.sourceAsset,
  );
  if (sourceAssetResult.isErr()) {
    return err(sourceAssetResult.error);
  }

  const destinationAssetResult = parseDestinationAsset(
    networkResult.value,
    params.destinationAsset,
  );
  if (destinationAssetResult.isErr()) {
    return err(destinationAssetResult.error);
  }

  const validateAssetPairResult = validateSourceDestinationAssetPair(
    sourceAssetResult.value,
    destinationAssetResult.value,
  );
  if (validateAssetPairResult.isErr()) {
    return err(validateAssetPairResult.error);
  }

  const validateAmountPairResult = validateSourceDestinationAmountPair(
    params.sourceAmount as AssetAmount,
    params.destinationAmount as AssetAmount,
  );
  if (validateAmountPairResult.isErr()) {
    return err(validateAmountPairResult.error);
  }

  const amount: { [key: string]: AssetAmount } = {};
  if (params.destinationAmount) {
    const destinationAmountResult = parseDestinationAmount(
      params.destinationAmount,
    );
    if (destinationAmountResult.isErr()) {
      return err(destinationAmountResult.error);
    }
    amount.destinationAmount = destinationAmountResult.value;
  } else {
    const sourceAmountResult = parseSourceAmount(params.sourceAmount);
    if (sourceAmountResult.isErr()) {
      return err(sourceAmountResult.error);
    }
    amount.sourceAmount = sourceAmountResult.value;
  }

  const authenticationStrategyResult = parseAuthenticationStrategy(
    params.authenticationStrategy,
  );
  if (authenticationStrategyResult.isErr()) {
    return err(authenticationStrategyResult.error);
  }

  const parseLayoutResult = parseLayoutParams({
    layoutOffset: params.layoutOffset,
    layoutPosition: params.layoutPosition as Position,
  });
  if (parseLayoutResult.isErr()) {
    return err(parseLayoutResult.error);
  }

  const validateWalletAddressResult = validateWalletAddress(
    params.walletAddress,
    networkResult.value,
  );
  if (validateWalletAddressResult.isErr()) {
    return err(validateWalletAddressResult.error);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { layoutOffset, layoutPosition, ...rest } = params;

  return ok({
    ...(rest as unknown as Configuration),
    ...amount,
    layout: parseLayoutResult.value,
    network: networkResult.value,
    sourceAsset: sourceAssetResult.value,
    sourceCAIPAsset: lookupAsset(
      networkResult.value,
      sourceAssetResult.value,
      useTestnets,
    ),
    destinationAsset: destinationAssetResult.value,
    destinationCAIPAsset: lookupAsset(
      networkResult.value,
      destinationAssetResult.value,
      useTestnets,
    ),
    authenticationStrategy: authenticationStrategyResult.value,
    mode,
    transferKind:
      destinationAssetResult.value in CryptoAsset
        ? TransferKind.CASH_IN
        : TransferKind.CASH_OUT,
  });
};

const parseMesoJSQueryParams = (
  queryParams: URLSearchParams,
): Result<{ [key: string]: string }, Message> => {
  const sourceAmount = queryParams.get("sourceAmount");
  const destinationAmount = queryParams.get("destinationAmount");
  const amountParams: { [key: string]: string } = {
    ...(sourceAmount ? { sourceAmount } : {}),
    ...(destinationAmount ? { destinationAmount } : {}),
  };

  const params = {
    ...amountParams,
    partnerId: queryParams.get("partnerId") ?? "",
    network: queryParams.get("network") ?? "",
    walletAddress: queryParams.get("walletAddress") ?? "",
    sourceAsset: queryParams.get("sourceAsset") ?? "USD",
    destinationAsset: queryParams.get("destinationAsset") ?? "",
    layoutPosition: queryParams.get("layoutPosition") ?? "top-right",
    layoutOffset: queryParams.get("layoutOffset") ?? "0",
    version: queryParams.get("version") ?? "",
    authenticationStrategy:
      queryParams.get("authenticationStrategy") ??
      AuthenticationStrategy.WALLET_VERIFICATION,
  };

  if (
    (!queryParams.get("sourceAmount") &&
      !queryParams.get("destinationAmount")) ||
    !Object.values(params).every((x) => x !== "")
  ) {
    const errorMessage =
      "Invalid configuration. Ensure all values are present.";

    if (import.meta.env.DEV && window.top === window) {
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    }

    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok(params);
};

const parseSourceAsset = (
  network: Network,
  sourceAsset: string,
): Result<Asset, Message> => {
  const sourceAssetResult = assetSchema.safeParse(sourceAsset);

  if (!sourceAssetResult.success) {
    const errorMessage = `Invalid configuration. \`sourceAsset\` must be one of ${printCodefencedValues(
      Asset,
    )}`;

    return err({
      kind: MessageKind.UNSUPPORTED_ASSET_ERROR,
      payload: { message: errorMessage },
    });
  }

  const validateNetworkAssetPairResult = validateNetworkAssetPair(
    network,
    sourceAssetResult.data,
  );
  if (validateNetworkAssetPairResult.isErr()) {
    return err(validateNetworkAssetPairResult.error);
  }

  return ok(sourceAssetResult.data);
};

const parseDestinationAsset = (
  network: Network,
  destinationAsset: string,
): Result<Asset, Message> => {
  const destinationAssetResult = assetSchema.safeParse(destinationAsset);

  if (!destinationAssetResult.success) {
    const errorMessage = `Invalid configuration. \`destinationAsset\` must be one of ${printCodefencedValues(
      Asset,
    )}`;

    return err({
      kind: MessageKind.UNSUPPORTED_ASSET_ERROR,
      payload: { message: errorMessage },
    });
  }

  const validateNetworkAssetPairResult = validateNetworkAssetPair(
    network,
    destinationAssetResult.data,
  );
  if (validateNetworkAssetPairResult.isErr()) {
    return err(validateNetworkAssetPairResult.error);
  }

  return ok(destinationAssetResult.data);
};

const parseSourceAmount = (amount: string) =>
  parseAssetAmount(amount, "source");
const parseDestinationAmount = (amount: string) =>
  parseAssetAmount(amount, "destination");
const parseAssetAmount = (
  amount: string,
  type: "source" | "destination",
): Result<AssetAmount, Message> => {
  const amountResult = amountSchema.safeParse(amount);

  if (!amountResult.success) {
    const errorMessage = `Invalid ${type} amount. Value must be a valid number as a string.`;

    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok(amountResult.data as AssetAmount);
};

const parseAuthenticationStrategy = (
  authenticationStrategy: string,
): Result<AuthenticationStrategy, Message> => {
  const authenticationStrategyResult = authenticationStrategySchema.safeParse(
    authenticationStrategy,
  );

  if (!authenticationStrategyResult.success) {
    const errorMessage = `Invalid configuration. \`authenticationStrategy\` must be one of ${printCodefencedValues(
      AuthenticationStrategy,
    )}`;

    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok(authenticationStrategyResult.data);
};

const parseNetwork = (network: string): Result<Network, Message> => {
  const networkResult = networkSchema.safeParse(network);

  if (!networkResult.success) {
    const errorMessage = `Invalid configuration. \`network\` must be one of ${printCodefencedValues(
      Network,
    )}`;

    return err({
      kind: MessageKind.UNSUPPORTED_NETWORK_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok(networkResult.data);
};
