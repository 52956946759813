import {
  Network,
  Asset,
  FiatAsset,
  CryptoAsset,
  Message,
  MessageKind,
  AssetAmount,
} from "@src/types";
import { Result, err, ok } from "neverthrow";
import { lookupAsset } from "@tigris/common";
import { parseWalletAddress } from "@src/utils/parseWalletAddress";

export const printCodefencedValues = (obj: Record<string, string>): string =>
  Object.values(obj)
    .map((val) => `\`${val}\``)
    .join(", ");

export const validateWalletAddress = (
  walletAddress: string,
  network: Network,
): Result<"ok", Message> => {
  const parseWalletAddressResult = parseWalletAddress(walletAddress, network);
  if (parseWalletAddressResult && "message" in parseWalletAddressResult) {
    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: parseWalletAddressResult.message },
    });
  }
  return ok("ok");
};

export const validateNetworkAssetPair = (
  network: Network,
  destinationAsset: Asset,
): Result<"ok", Message> => {
  try {
    lookupAsset(network, destinationAsset);
  } catch {
    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: {
        message: `The destination asset ${destinationAsset} is not supported for ${network}.`,
      },
    });
  }
  return ok("ok");
};

export const validateSourceDestinationAssetPair = (
  sourceAsset: Asset,
  destinationAsset: Asset,
): Result<"ok", Message> => {
  if (
    (sourceAsset in FiatAsset && destinationAsset in FiatAsset) ||
    (sourceAsset in CryptoAsset && destinationAsset in CryptoAsset)
  ) {
    const errorMessage = `Invalid configuration. ${sourceAsset} \`sourceAsset\` and ${destinationAsset} \`destinationAsset\` pair is unsupported.`;
    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: errorMessage },
    });
  }

  if (destinationAsset in FiatAsset && sourceAsset !== Asset.USDC) {
    const errorMessage = `Invalid configuration. Cash-outs are only supported for ${Asset.USDC}.`;
    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok("ok");
};

export const validateSourceDestinationAmountPair = (
  sourceAmount?: AssetAmount,
  destinationAmount?: AssetAmount,
): Result<"ok", Message> => {
  if (!sourceAmount && !destinationAmount) {
    const errorMessage = `Invalid configuration. Either \`sourceAmount\` or \`destinationAsset\` must be provided.`;
    return err({
      kind: MessageKind.CONFIGURATION_ERROR,
      payload: { message: errorMessage },
    });
  }

  return ok("ok");
};
