import { delay } from "./constants";

// The duration of a synthetic delay to time animations and transitions
const MINIMUM_DELAY_IN_MS = 800;

/**
 * Calculates how much time has passed since `startTime`. If the time elapsed is less than
 * `MINIMUM_DELAY_IN_MS`, it will wait for the remaining time before resolving.
 *
 * @param {number} startTime The result of [`performance.now()`](https://developer.mozilla.org/en-US/docs/Web/API/Performance/now)
 * @returns `void`
 */
export const dynamicDelay = async (startTime: number) => {
  const endTime = performance.now() - startTime;

  const timeLeft = Math.max(0, MINIMUM_DELAY_IN_MS - endTime);

  if (timeLeft > 0) {
    await delay(timeLeft);
  }

  return;
};
