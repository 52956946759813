import {
  Button,
  Title,
  Text,
  CobrandingLogo,
  ErrorMessages,
} from "@tigris/mesokit";
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "sonner";
import { getNextOnboardingStep } from "../utils/getNextOnboardingStep";
import { Amplitude } from "../utils/telemetry";
import AnimationContainer from "./AnimationContainer";
import { useRouter } from "../hooks/useRouter";
import { Routes } from "../types";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { motion } from "framer-motion";

const TERMS_URL = "https://meso.network/legal/terms-of-service";
const PRIVACY_POLICY_URL = "https://meso.network/legal/privacy-policy";
const ZH_PRIVACY_POLICY_URL = "https://docs.zerohash.com/page/privacy-policy";
const ZH_REGULATORY_DISCLOSURES_URL =
  "https://docs.zerohash.com/page/us-licenses-and-disclosures";

const TOAST_ID = "Agreements";

const checkboxClasses =
  "focus:ring-primary dark:focus:ring-primary-light checked:bg-primary dark:checked:bg-primary-light peer h-6 w-6 md:w-4 md:h-4 appearance-none rounded-md border-gray-300 bg-neutral-200 focus:ring-2 ring-offset-1 dark:border-gray-600 dark:bg-neutral-600 dark:ring-offset-neutral-800";
const checkmarkClasses =
  "pointer-events-none absolute left-1.5 top-1.5 md:left-1 h-3 w-3 md:top-1 md:h-2 md:w-2 scale-75 fill-white opacity-0 transition peer-checked:scale-100 peer-checked:opacity-100";

export const Agreements = () => {
  const { navigate } = useRouter();
  const { api } = useApi();
  const { user, configuration, returnToTransfer } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const initiatedOnboarding = useRef(false);
  const [startedOnboarding, setStartedOnboarding] = useState(false);
  const [mesoAgreementsAccepted, setMesoAgreementsAccepted] = useState(false);
  const [zeroHashAgreementsAccepted, setZeroHashAgreementsAccepted] =
    useState(false);

  const startOnboarding = useCallback(async () => {
    const initialAttempt = !initiatedOnboarding.current;
    initiatedOnboarding.current = true;
    setIsLoading(true);

    const startOnboardingResult = await api.resolveStartOnboarding({
      input: {
        network: configuration.network,
        walletAddress: configuration.walletAddress,
      },
    });

    setIsLoading(false);

    if (startOnboardingResult.isErr()) {
      if (!initialAttempt) {
        toast.error(ErrorMessages.agreements.ACCEPT_TERMS_API_ERROR, {
          id: TOAST_ID,
        });
      }

      return false;
    } else if ("loginWithEmailAndPassword" in startOnboardingResult.value) {
      toast(
        <div className="flex flex-col justify-center gap-2">
          {ErrorMessages.agreements.DUPLICATE_WALLET_ERROR}
          <Button
            containerClassName="h-8"
            onClick={() => {
              // For inline/embedded: Close onboarding window and navigate to email/password login
              // For standalone: Navigate to email/password login
              returnToTransfer("returnToLogin");
            }}
          >
            Log In
          </Button>
        </div>,
        { id: TOAST_ID },
      );
    } else {
      setStartedOnboarding(true);
      return true;
    }
  }, [
    api,
    configuration.network,
    configuration.walletAddress,
    returnToTransfer,
  ]);

  useEffect(() => {
    if (!initiatedOnboarding.current && user.status === undefined)
      startOnboarding();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptUserAgreement = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      if (
        !startedOnboarding &&
        user.status === undefined &&
        !(await startOnboarding())
      ) {
        return;
      }

      setIsLoading(true);

      const input = {
        acceptedMeso: mesoAgreementsAccepted,
        acceptedPrivacyVersion: "meso:privacyPolicy:v1",
        acceptedTermsVersion: "meso:terms:v1",
        acceptedZeroHash: zeroHashAgreementsAccepted,
      };

      const acceptTermsResult = await api.resolveAcceptTerms({
        input,
      });

      if (acceptTermsResult.isErr()) {
        toast.error(acceptTermsResult.error, { id: TOAST_ID });
        return;
      }

      const addCitizenshipResult = await api.resolveAddCitizenship({
        input: { countryCode: "US" },
      });

      if (addCitizenshipResult.isErr()) {
        toast.error(addCitizenshipResult.error, { id: TOAST_ID });
        return;
      }

      setIsLoading(false);

      const nextStep = getNextOnboardingStep(
        Routes.Root,
        acceptTermsResult.value,
        user.status,
      );

      if (nextStep.isErr()) {
        toast.error(nextStep.error, { id: TOAST_ID });
        return;
      }

      if (configuration) {
        navigate(Routes.ConnectedWallet, {
          nextRoute: nextStep.value.pathname,
        });
      } else {
        navigate(nextStep.value.pathname, nextStep.value.state);
      }
    },
    [
      api,
      configuration,
      mesoAgreementsAccepted,
      navigate,
      startOnboarding,
      startedOnboarding,
      user.status,
      zeroHashAgreementsAccepted,
    ],
  );

  return (
    <AnimationContainer>
      <form
        name="Agreements"
        id="Agreements"
        onSubmit={handleAcceptUserAgreement}
        className="onboarding-inner-content"
      >
        <div
          data-testid="brandingHero"
          className="my-auto flex flex-col items-center justify-center"
        >
          <motion.div layoutId="CoBranding">
            <CobrandingLogo
              size="lg"
              displayName={configuration.partner?.displayName}
              logoUri={configuration.partner?.logoUri}
            />
          </motion.div>
          <Title.Medium bold className="mt-4">
            Connect a card with Meso
          </Title.Medium>
          <Text className="text-center">
            {configuration.partner?.displayName
              ? `${configuration.partner.displayName} uses Meso to instantly transfer money between your wallet and bank.`
              : "Instantly transfer money between your wallet and bank with Meso."}
          </Text>
        </div>
        <div>
          <div className="mb-2 flex items-start gap-2 pl-1 text-[0.625rem] leading-tight">
            <div className="relative">
              <input
                type="checkbox"
                name="mesoAgreements"
                data-testid="mesoAgreements"
                id="mesoAgreements"
                className={checkboxClasses}
                checked={mesoAgreementsAccepted}
                onChange={() => {
                  Amplitude.track("Form Input Changed", {
                    formId: "Agreements",
                    inputId: "mesoAgreements",
                    checked: !mesoAgreementsAccepted,
                  });
                  setMesoAgreementsAccepted(!mesoAgreementsAccepted);
                }}
              />
              <svg
                width="11"
                height="9"
                viewBox="0 0 11 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={checkmarkClasses}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0812 0.186222C10.5307 0.507231 10.6347 1.13178 10.3137 1.58119L5.31373 8.58119C5.14288 8.82039 4.87543 8.9723 4.58248 8.99655C4.28953 9.02079 4.00075 8.91492 3.79289 8.70706L0.292893 5.20706C-0.0976311 4.81654 -0.0976311 4.18337 0.292893 3.79285C0.683417 3.40232 1.31658 3.40232 1.70711 3.79285L4.3724 6.45814L8.68627 0.418717C9.00728 -0.0306951 9.63183 -0.134787 10.0812 0.186222Z"
                />
              </svg>
            </div>
            <label htmlFor="mesoAgreements" className="opacity-50">
              I agree to the{" "}
              <a
                href={TERMS_URL}
                target="_blank"
                rel="noreferrer"
                className="font-bold no-underline hover:opacity-50"
                onClick={() =>
                  Amplitude.track("Link Clicked", { url: TERMS_URL })
                }
              >
                Meso User Agreement
              </a>{" "}
              and have read and understand the{" "}
              <a
                href={PRIVACY_POLICY_URL}
                target="_blank"
                rel="noreferrer"
                className="font-bold no-underline hover:opacity-50"
                onClick={() =>
                  Amplitude.track("Link Clicked", { url: PRIVACY_POLICY_URL })
                }
              >
                Meso Privacy Policy
              </a>{" "}
              and <span className="font-bold">I am a US citizen</span>.
            </label>
          </div>
          <div className="flex items-start gap-2 pl-1 text-[0.625rem] leading-tight">
            <div className="relative">
              <input
                type="checkbox"
                name="zeroHashAgreements"
                data-testid="zeroHashAgreements"
                id="zeroHashAgreements"
                className={checkboxClasses}
                checked={zeroHashAgreementsAccepted}
                onChange={() => {
                  Amplitude.track("Form Input Changed", {
                    formId: "Agreements",
                    inputId: "zeroHashAgreements",
                    checked: !zeroHashAgreementsAccepted,
                  });
                  setZeroHashAgreementsAccepted(!zeroHashAgreementsAccepted);
                }}
              />

              <svg
                width="11"
                height="9"
                viewBox="0 0 11 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={checkmarkClasses}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0812 0.186222C10.5307 0.507231 10.6347 1.13178 10.3137 1.58119L5.31373 8.58119C5.14288 8.82039 4.87543 8.9723 4.58248 8.99655C4.28953 9.02079 4.00075 8.91492 3.79289 8.70706L0.292893 5.20706C-0.0976311 4.81654 -0.0976311 4.18337 0.292893 3.79285C0.683417 3.40232 1.31658 3.40232 1.70711 3.79285L4.3724 6.45814L8.68627 0.418717C9.00728 -0.0306951 9.63183 -0.134787 10.0812 0.186222Z"
                />
              </svg>
            </div>
            <label htmlFor="zeroHashAgreements" className="opacity-50">
              <p className="mb-1 leading-3">
                I agree to the Zero Hash and Zero Hash Liquidity Services User
                Agreement, and I have read and understand the Zero Hash{" "}
                <a
                  href={ZH_PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold no-underline hover:opacity-50"
                  onClick={() =>
                    Amplitude.track("Link Clicked", {
                      url: ZH_PRIVACY_POLICY_URL,
                    })
                  }
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  href={ZH_REGULATORY_DISCLOSURES_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold no-underline hover:opacity-50"
                  onClick={() =>
                    Amplitude.track("Link Clicked", {
                      url: ZH_REGULATORY_DISCLOSURES_URL,
                    })
                  }
                >
                  Regulatory Disclosures
                </a>
                .
              </p>
              <p className="leading-3">
                I understand that the value of any cryptocurrency, including
                digital assets pegged to fiat currency, commodities, or any
                other asset, may go to zero.
              </p>
            </label>
          </div>
          <div className="mt-4">
            <Button
              key="Agreements:button"
              disabled={
                isLoading ||
                !mesoAgreementsAccepted ||
                !zeroHashAgreementsAccepted
              }
              type="submit"
              isLoading={isLoading}
            >
              Continue
            </Button>
          </div>
        </div>
      </form>
    </AnimationContainer>
  );
};
