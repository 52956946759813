import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

export const PasswordValidity = ({
  isValid,
  minPasswordLength,
}: {
  isValid: boolean;
  minPasswordLength: number;
}) => {
  const variants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 },
  };

  return (
    <div
      className={twMerge(
        "flex items-center gap-1 text-xs transition-colors",
        isValid ? "text-neutral-800 dark:text-neutral-100" : "text-neutral-400",
      )}
    >
      <AnimatePresence mode="wait">
        {isValid ? (
          <motion.div key="valid-icon" {...variants}>
            <FontAwesomeIcon
              icon={icon({ name: "circle-check", style: "solid" })}
              className="text-blue-500 dark:text-blue-400"
            />
          </motion.div>
        ) : (
          <motion.div key="invalid-icon" {...variants}>
            <FontAwesomeIcon
              icon={icon({ name: "circle", style: "regular" })}
              className="opacity-20 dark:text-white"
            />
          </motion.div>
        )}
      </AnimatePresence>
      At least {minPasswordLength} characters
    </div>
  );
};
